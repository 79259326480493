import { useError } from '../common/hooks';
import { Alert, Text, View } from '@aws-amplify/ui-react'
import { ThemeProvider, defaultTheme } from '@aws-amplify/ui-react';

export default function ErrorNotification() {
    const { error, removeError } = useError();

    const handleDismiss = () => {
        removeError();
    };

    return (
        <View position="sticky" top="48px" style={{ zIndex: "100" }} >
            <ThemeProvider theme={defaultTheme}>
                {
                    !!error ? (
                        <View>
                            <Alert

                                width="100%"
                                onDismiss={handleDismiss}
                                isDismissible={true}
                                variation="error">
                                <Text as="p"><b>Error {error.status}:</b> {error.message}</Text>
                            </Alert>

                        </View>
                    ) : null}
            </ThemeProvider>
        </View>
    )

}
