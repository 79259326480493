import { Stack } from '../../models';
import { ComponentDivider } from '..'
import { useNavigate } from 'react-router-dom';
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import Utils from '../../lib/utils'

import {
    Flex,
    Pagination,
    SearchField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@aws-amplify/ui-react'


import { useEffect, useState } from 'react';
import { Auth } from "aws-amplify";


export default function StackList() {
    const [currentPage, setCurrentPage] = useState(1)
    const [stacks, setStacks] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [visibleStacks, setVisibleStacks] = useState([])
    const [stacksWithPodNames, setStacksWithPodNames] = useState([]);

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const navigate = useNavigate();

    const pageSize = 25;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    async function clear() {
        const stacks = await DataStore.query(Stack)
        setStacks(stacks);
    }
    const search = async (event) => {
        const searchStr = event.target.value.toLowerCase();
        const allStacks = await DataStore.query(Stack);
        const stacks = allStacks.filter(stack =>
            (stack.stackName && stack.stackName.toLowerCase().includes(searchStr))
        );

        setCurrentPage(1)
        setStacks(stacks)
    }

    useEffect(() => {
        const stackSub = DataStore.observeQuery(Stack,
            Predicates.ALL, {
            sort: (s) => s.stackName(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            setStacks(items)
        });

        return () => {
            stackSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        const fetchPodNames = async () => {
            const stackData = await Promise.all(stacks.map(async (stack) => {
                const pod = await stack.pod;
                const podNameToDisplay = pod ? pod.podName : 'N/A';
                return { stackId: stack.id, podName: podNameToDisplay };
            }));

            setStacksWithPodNames(stackData);
        };

        if (stacks.length > 0) {
            fetchPodNames();
        } else {
            setStacksWithPodNames([]);
        }
    }, [stacks]);

    useEffect(() => {
        // This method will fire every time the stacks or currentPage
        // dependencies are updated. It sets totalPages and visibleMigrations
        // state objects accordingly before re-rendering the component.
        setTotalPages(Utils.getPageCount(pageSize, stacks.length))
        setVisibleStacks(Utils.getPageItems(stacks, pageSize, currentPage))

    }, [stacks, currentPage])

    return (
        <Flex width="60rem" direction="column">
            <ComponentDivider title="All Stacks" />
            <Flex direction="row" justifyContent="space-between">
                <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
            </Flex>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                onChange={handleOnChange}
            />
            <Table highlightOnHover={true} variation="striped">
                <TableHead>
                    <TableRow >
                        <TableCell as="th">Stack Name</TableCell>
                        <TableCell as="th">Primary AZ</TableCell>
                        <TableCell as="th">Secondary AZ</TableCell>
                        <TableCell as="th">Primary CIDR</TableCell>
                        <TableCell as="th">Secondary CIDR</TableCell>
                        <TableCell as="th">Assigned?</TableCell>
                        <TableCell as="th" style={{ whiteSpace: 'nowrap' }}>POD Attached To Stack</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visibleStacks.filter(s => s.stackName).map(stack =>
                            <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/stack/${stack.id}`)} key={stack.id}>
                                <TableCell>{stack.stackName}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az1)}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az2)}</TableCell>
                                <TableCell>{stack.az1Cidr}</TableCell>
                                <TableCell>{stack.az2Cidr}</TableCell>
                                <TableCell>{stack.clientStacksClientId ? "Yes" : "No"}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    {stacksWithPodNames.find(s => s.stackId === stack.id)?.podName || 'N/A'}
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </Flex >
    );
}
