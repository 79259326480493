/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAMI = /* GraphQL */ `
  mutation CreateAMI(
    $input: CreateAMIInput!
    $condition: ModelAMIConditionInput
  ) {
    createAMI(input: $input, condition: $condition) {
      name
      location
      createdAt
      amiType
      description
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationModifiedCustomerBaselineAMIId
      __typename
    }
  }
`;
export const updateAMI = /* GraphQL */ `
  mutation UpdateAMI(
    $input: UpdateAMIInput!
    $condition: ModelAMIConditionInput
  ) {
    updateAMI(input: $input, condition: $condition) {
      name
      location
      createdAt
      amiType
      description
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationModifiedCustomerBaselineAMIId
      __typename
    }
  }
`;
export const deleteAMI = /* GraphQL */ `
  mutation DeleteAMI(
    $input: DeleteAMIInput!
    $condition: ModelAMIConditionInput
  ) {
    deleteAMI(input: $input, condition: $condition) {
      name
      location
      createdAt
      amiType
      description
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationModifiedCustomerBaselineAMIId
      __typename
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe(
    $input: CreateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    createRecipe(input: $input, condition: $condition) {
      name
      arn
      description
      required
      migrations {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe(
    $input: UpdateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    updateRecipe(input: $input, condition: $condition) {
      name
      arn
      description
      required
      migrations {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe(
    $input: DeleteRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    deleteRecipe(input: $input, condition: $condition) {
      name
      arn
      description
      required
      migrations {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTerminalServer = /* GraphQL */ `
  mutation CreateTerminalServer(
    $input: CreateTerminalServerInput!
    $condition: ModelTerminalServerConditionInput
  ) {
    createTerminalServer(input: $input, condition: $condition) {
      instanceId
      instanceName
      ipAddress
      region
      buildDate
      instanceType
      availabilityZone
      dedicatedHostId
      ami {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      instanceState
      are_licenses_installed
      description
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTerminalServersId
      terminalServerAmiId
      __typename
    }
  }
`;
export const updateTerminalServer = /* GraphQL */ `
  mutation UpdateTerminalServer(
    $input: UpdateTerminalServerInput!
    $condition: ModelTerminalServerConditionInput
  ) {
    updateTerminalServer(input: $input, condition: $condition) {
      instanceId
      instanceName
      ipAddress
      region
      buildDate
      instanceType
      availabilityZone
      dedicatedHostId
      ami {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      instanceState
      are_licenses_installed
      description
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTerminalServersId
      terminalServerAmiId
      __typename
    }
  }
`;
export const deleteTerminalServer = /* GraphQL */ `
  mutation DeleteTerminalServer(
    $input: DeleteTerminalServerInput!
    $condition: ModelTerminalServerConditionInput
  ) {
    deleteTerminalServer(input: $input, condition: $condition) {
      instanceId
      instanceName
      ipAddress
      region
      buildDate
      instanceType
      availabilityZone
      dedicatedHostId
      ami {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      instanceState
      are_licenses_installed
      description
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTerminalServersId
      terminalServerAmiId
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      clientId
      clientName
      leveraged
      multiTimezone
      migrations {
        nextToken
        startedAt
        __typename
      }
      stacks {
        nextToken
        startedAt
        __typename
      }
      userCount
      timeZone
      purchasedStoraged
      internalSubnets
      osVersion
      sqlDriveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      clientId
      clientName
      leveraged
      multiTimezone
      migrations {
        nextToken
        startedAt
        __typename
      }
      stacks {
        nextToken
        startedAt
        __typename
      }
      userCount
      timeZone
      purchasedStoraged
      internalSubnets
      osVersion
      sqlDriveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      clientId
      clientName
      leveraged
      multiTimezone
      migrations {
        nextToken
        startedAt
        __typename
      }
      stacks {
        nextToken
        startedAt
        __typename
      }
      userCount
      timeZone
      purchasedStoraged
      internalSubnets
      osVersion
      sqlDriveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMigration = /* GraphQL */ `
  mutation CreateMigration(
    $input: CreateMigrationInput!
    $condition: ModelMigrationConditionInput
  ) {
    createMigration(input: $input, condition: $condition) {
      id
      dataMigrationRegion
      migrationName
      customerBaselineAMI {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      modifiedCustomerBaselineAMI {
        nextToken
        startedAt
        __typename
      }
      scheduledStart
      scheduledCutover
      customerInventory {
        nextToken
        startedAt
        __typename
      }
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      terminalServers {
        nextToken
        startedAt
        __typename
      }
      status
      tasks {
        nextToken
        startedAt
        __typename
      }
      useDms
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientMigrationsClientId
      migrationCustomerBaselineAMIId
      __typename
    }
  }
`;
export const updateMigration = /* GraphQL */ `
  mutation UpdateMigration(
    $input: UpdateMigrationInput!
    $condition: ModelMigrationConditionInput
  ) {
    updateMigration(input: $input, condition: $condition) {
      id
      dataMigrationRegion
      migrationName
      customerBaselineAMI {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      modifiedCustomerBaselineAMI {
        nextToken
        startedAt
        __typename
      }
      scheduledStart
      scheduledCutover
      customerInventory {
        nextToken
        startedAt
        __typename
      }
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      terminalServers {
        nextToken
        startedAt
        __typename
      }
      status
      tasks {
        nextToken
        startedAt
        __typename
      }
      useDms
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientMigrationsClientId
      migrationCustomerBaselineAMIId
      __typename
    }
  }
`;
export const deleteMigration = /* GraphQL */ `
  mutation DeleteMigration(
    $input: DeleteMigrationInput!
    $condition: ModelMigrationConditionInput
  ) {
    deleteMigration(input: $input, condition: $condition) {
      id
      dataMigrationRegion
      migrationName
      customerBaselineAMI {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      modifiedCustomerBaselineAMI {
        nextToken
        startedAt
        __typename
      }
      scheduledStart
      scheduledCutover
      customerInventory {
        nextToken
        startedAt
        __typename
      }
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      terminalServers {
        nextToken
        startedAt
        __typename
      }
      status
      tasks {
        nextToken
        startedAt
        __typename
      }
      useDms
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientMigrationsClientId
      migrationCustomerBaselineAMIId
      __typename
    }
  }
`;
export const createMigrationTask = /* GraphQL */ `
  mutation CreateMigrationTask(
    $input: CreateMigrationTaskInput!
    $condition: ModelMigrationTaskConditionInput
  ) {
    createMigrationTask(input: $input, condition: $condition) {
      id
      name
      title
      status
      updatedBy
      comment
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTasksId
      __typename
    }
  }
`;
export const updateMigrationTask = /* GraphQL */ `
  mutation UpdateMigrationTask(
    $input: UpdateMigrationTaskInput!
    $condition: ModelMigrationTaskConditionInput
  ) {
    updateMigrationTask(input: $input, condition: $condition) {
      id
      name
      title
      status
      updatedBy
      comment
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTasksId
      __typename
    }
  }
`;
export const deleteMigrationTask = /* GraphQL */ `
  mutation DeleteMigrationTask(
    $input: DeleteMigrationTaskInput!
    $condition: ModelMigrationTaskConditionInput
  ) {
    deleteMigrationTask(input: $input, condition: $condition) {
      id
      name
      title
      status
      updatedBy
      comment
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTasksId
      __typename
    }
  }
`;
export const createPod = /* GraphQL */ `
  mutation CreatePod(
    $input: CreatePodInput!
    $condition: ModelPodConditionInput
  ) {
    createPod(input: $input, condition: $condition) {
      id
      podName
      domain
      domainUser
      timeZone
      podSubId
      awsAccountId
      awsRegion
      multiTimezone
      vpcId
      vpcCidr
      securityGroupFile
      securityGroupSql
      securityGroupMgmt
      securityGroupAD
      sqlAddress
      sqlName
      sqlAz
      sqlReportAddress
      sqlReportName
      sqlReportAz
      fileServerAddress
      fileServerName
      fileServerAz
      stacks {
        nextToken
        startedAt
        __typename
      }
      dnsIpPrimary
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      instanceKeyPair
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePod = /* GraphQL */ `
  mutation UpdatePod(
    $input: UpdatePodInput!
    $condition: ModelPodConditionInput
  ) {
    updatePod(input: $input, condition: $condition) {
      id
      podName
      domain
      domainUser
      timeZone
      podSubId
      awsAccountId
      awsRegion
      multiTimezone
      vpcId
      vpcCidr
      securityGroupFile
      securityGroupSql
      securityGroupMgmt
      securityGroupAD
      sqlAddress
      sqlName
      sqlAz
      sqlReportAddress
      sqlReportName
      sqlReportAz
      fileServerAddress
      fileServerName
      fileServerAz
      stacks {
        nextToken
        startedAt
        __typename
      }
      dnsIpPrimary
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      instanceKeyPair
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePod = /* GraphQL */ `
  mutation DeletePod(
    $input: DeletePodInput!
    $condition: ModelPodConditionInput
  ) {
    deletePod(input: $input, condition: $condition) {
      id
      podName
      domain
      domainUser
      timeZone
      podSubId
      awsAccountId
      awsRegion
      multiTimezone
      vpcId
      vpcCidr
      securityGroupFile
      securityGroupSql
      securityGroupMgmt
      securityGroupAD
      sqlAddress
      sqlName
      sqlAz
      sqlReportAddress
      sqlReportName
      sqlReportAz
      fileServerAddress
      fileServerName
      fileServerAz
      stacks {
        nextToken
        startedAt
        __typename
      }
      dnsIpPrimary
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      instanceKeyPair
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStack = /* GraphQL */ `
  mutation CreateStack(
    $input: CreateStackInput!
    $condition: ModelStackConditionInput
  ) {
    createStack(input: $input, condition: $condition) {
      id
      stackName
      amiTermServ
      amiTermServBase
      amiAncServ
      amiPxPServ
      az1
      az1Cidr
      az2
      az2Cidr
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      createBaseTermServer
      createPxpServer
      instanceTypeTermServ
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pod {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientStacksClientId
      podStacksId
      __typename
    }
  }
`;
export const updateStack = /* GraphQL */ `
  mutation UpdateStack(
    $input: UpdateStackInput!
    $condition: ModelStackConditionInput
  ) {
    updateStack(input: $input, condition: $condition) {
      id
      stackName
      amiTermServ
      amiTermServBase
      amiAncServ
      amiPxPServ
      az1
      az1Cidr
      az2
      az2Cidr
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      createBaseTermServer
      createPxpServer
      instanceTypeTermServ
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pod {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientStacksClientId
      podStacksId
      __typename
    }
  }
`;
export const deleteStack = /* GraphQL */ `
  mutation DeleteStack(
    $input: DeleteStackInput!
    $condition: ModelStackConditionInput
  ) {
    deleteStack(input: $input, condition: $condition) {
      id
      stackName
      amiTermServ
      amiTermServBase
      amiAncServ
      amiPxPServ
      az1
      az1Cidr
      az2
      az2Cidr
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      createBaseTermServer
      createPxpServer
      instanceTypeTermServ
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pod {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientStacksClientId
      podStacksId
      __typename
    }
  }
`;
export const createCSSAClient = /* GraphQL */ `
  mutation CreateCSSAClient(
    $input: CreateCSSAClientInput!
    $condition: ModelCSSAClientConditionInput
  ) {
    createCSSAClient(input: $input, condition: $condition) {
      clientId
      clientName
      region
      awsAccount
      servers {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      supportMembers {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCSSAClient = /* GraphQL */ `
  mutation UpdateCSSAClient(
    $input: UpdateCSSAClientInput!
    $condition: ModelCSSAClientConditionInput
  ) {
    updateCSSAClient(input: $input, condition: $condition) {
      clientId
      clientName
      region
      awsAccount
      servers {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      supportMembers {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCSSAClient = /* GraphQL */ `
  mutation DeleteCSSAClient(
    $input: DeleteCSSAClientInput!
    $condition: ModelCSSAClientConditionInput
  ) {
    deleteCSSAClient(input: $input, condition: $condition) {
      clientId
      clientName
      region
      awsAccount
      servers {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      supportMembers {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCSSADatabase = /* GraphQL */ `
  mutation CreateCSSADatabase(
    $input: CreateCSSADatabaseInput!
    $condition: ModelCSSADatabaseConditionInput
  ) {
    createCSSADatabase(input: $input, condition: $condition) {
      id
      client {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      databaseEnv
      serverName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientServersId
      __typename
    }
  }
`;
export const updateCSSADatabase = /* GraphQL */ `
  mutation UpdateCSSADatabase(
    $input: UpdateCSSADatabaseInput!
    $condition: ModelCSSADatabaseConditionInput
  ) {
    updateCSSADatabase(input: $input, condition: $condition) {
      id
      client {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      databaseEnv
      serverName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientServersId
      __typename
    }
  }
`;
export const deleteCSSADatabase = /* GraphQL */ `
  mutation DeleteCSSADatabase(
    $input: DeleteCSSADatabaseInput!
    $condition: ModelCSSADatabaseConditionInput
  ) {
    deleteCSSADatabase(input: $input, condition: $condition) {
      id
      client {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      databaseEnv
      serverName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientServersId
      __typename
    }
  }
`;
export const createSupportMember = /* GraphQL */ `
  mutation CreateSupportMember(
    $input: CreateSupportMemberInput!
    $condition: ModelSupportMemberConditionInput
  ) {
    createSupportMember(input: $input, condition: $condition) {
      email
      name
      client {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      cssaUsername
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSupportMember = /* GraphQL */ `
  mutation UpdateSupportMember(
    $input: UpdateSupportMemberInput!
    $condition: ModelSupportMemberConditionInput
  ) {
    updateSupportMember(input: $input, condition: $condition) {
      email
      name
      client {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      cssaUsername
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSupportMember = /* GraphQL */ `
  mutation DeleteSupportMember(
    $input: DeleteSupportMemberInput!
    $condition: ModelSupportMemberConditionInput
  ) {
    deleteSupportMember(input: $input, condition: $condition) {
      email
      name
      client {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      cssaUsername
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSupportClientRequest = /* GraphQL */ `
  mutation CreateSupportClientRequest(
    $input: CreateSupportClientRequestInput!
    $condition: ModelSupportClientRequestConditionInput
  ) {
    createSupportClientRequest(input: $input, condition: $condition) {
      id
      salesforceId
      reason
      timeToLive
      status
      timeRevoked
      timeGranted
      revokedMethod
      stateMachineExecution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientSupportClientRequestsId
      supportMemberSupportClientRequestsId
      supportClientRequestDatabaseId
      __typename
    }
  }
`;
export const updateSupportClientRequest = /* GraphQL */ `
  mutation UpdateSupportClientRequest(
    $input: UpdateSupportClientRequestInput!
    $condition: ModelSupportClientRequestConditionInput
  ) {
    updateSupportClientRequest(input: $input, condition: $condition) {
      id
      salesforceId
      reason
      timeToLive
      status
      timeRevoked
      timeGranted
      revokedMethod
      stateMachineExecution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientSupportClientRequestsId
      supportMemberSupportClientRequestsId
      supportClientRequestDatabaseId
      __typename
    }
  }
`;
export const deleteSupportClientRequest = /* GraphQL */ `
  mutation DeleteSupportClientRequest(
    $input: DeleteSupportClientRequestInput!
    $condition: ModelSupportClientRequestConditionInput
  ) {
    deleteSupportClientRequest(input: $input, condition: $condition) {
      id
      salesforceId
      reason
      timeToLive
      status
      timeRevoked
      timeGranted
      revokedMethod
      stateMachineExecution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientSupportClientRequestsId
      supportMemberSupportClientRequestsId
      supportClientRequestDatabaseId
      __typename
    }
  }
`;
export const createMigrationRecipes = /* GraphQL */ `
  mutation CreateMigrationRecipes(
    $input: CreateMigrationRecipesInput!
    $condition: ModelMigrationRecipesConditionInput
  ) {
    createMigrationRecipes(input: $input, condition: $condition) {
      id
      recipeId
      migrationId
      recipe {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMigrationRecipes = /* GraphQL */ `
  mutation UpdateMigrationRecipes(
    $input: UpdateMigrationRecipesInput!
    $condition: ModelMigrationRecipesConditionInput
  ) {
    updateMigrationRecipes(input: $input, condition: $condition) {
      id
      recipeId
      migrationId
      recipe {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMigrationRecipes = /* GraphQL */ `
  mutation DeleteMigrationRecipes(
    $input: DeleteMigrationRecipesInput!
    $condition: ModelMigrationRecipesConditionInput
  ) {
    deleteMigrationRecipes(input: $input, condition: $condition) {
      id
      recipeId
      migrationId
      recipe {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClientSupport = /* GraphQL */ `
  mutation CreateClientSupport(
    $input: CreateClientSupportInput!
    $condition: ModelClientSupportConditionInput
  ) {
    createClientSupport(input: $input, condition: $condition) {
      id
      cSSAClientId
      supportMemberId
      cSSAClient {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      supportMember {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClientSupport = /* GraphQL */ `
  mutation UpdateClientSupport(
    $input: UpdateClientSupportInput!
    $condition: ModelClientSupportConditionInput
  ) {
    updateClientSupport(input: $input, condition: $condition) {
      id
      cSSAClientId
      supportMemberId
      cSSAClient {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      supportMember {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClientSupport = /* GraphQL */ `
  mutation DeleteClientSupport(
    $input: DeleteClientSupportInput!
    $condition: ModelClientSupportConditionInput
  ) {
    deleteClientSupport(input: $input, condition: $condition) {
      id
      cSSAClientId
      supportMemberId
      cSSAClient {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      supportMember {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
