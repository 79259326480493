/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAMI = /* GraphQL */ `
  query GetAMI($id: ID!) {
    getAMI(id: $id) {
      name
      location
      createdAt
      amiType
      description
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationModifiedCustomerBaselineAMIId
      __typename
    }
  }
`;
export const listAMIS = /* GraphQL */ `
  query ListAMIS(
    $filter: ModelAMIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAMIS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAMIS = /* GraphQL */ `
  query SyncAMIS(
    $filter: ModelAMIFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAMIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchAMIS = /* GraphQL */ `
  query SearchAMIS(
    $filter: SearchableAMIFilterInput
    $sort: [SearchableAMISortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAMIAggregationInput]
  ) {
    searchAMIS(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      name
      arn
      description
      required
      migrations {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRecipes = /* GraphQL */ `
  query SyncRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecipes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchRecipes = /* GraphQL */ `
  query SearchRecipes(
    $filter: SearchableRecipeFilterInput
    $sort: [SearchableRecipeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeAggregationInput]
  ) {
    searchRecipes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTerminalServer = /* GraphQL */ `
  query GetTerminalServer($id: ID!) {
    getTerminalServer(id: $id) {
      instanceId
      instanceName
      ipAddress
      region
      buildDate
      instanceType
      availabilityZone
      dedicatedHostId
      ami {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      instanceState
      are_licenses_installed
      description
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTerminalServersId
      terminalServerAmiId
      __typename
    }
  }
`;
export const listTerminalServers = /* GraphQL */ `
  query ListTerminalServers(
    $filter: ModelTerminalServerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalServers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        instanceId
        instanceName
        ipAddress
        region
        buildDate
        instanceType
        availabilityZone
        dedicatedHostId
        instanceState
        are_licenses_installed
        description
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationTerminalServersId
        terminalServerAmiId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTerminalServers = /* GraphQL */ `
  query SyncTerminalServers(
    $filter: ModelTerminalServerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTerminalServers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        instanceId
        instanceName
        ipAddress
        region
        buildDate
        instanceType
        availabilityZone
        dedicatedHostId
        instanceState
        are_licenses_installed
        description
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationTerminalServersId
        terminalServerAmiId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTerminalServers = /* GraphQL */ `
  query SearchTerminalServers(
    $filter: SearchableTerminalServerFilterInput
    $sort: [SearchableTerminalServerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTerminalServerAggregationInput]
  ) {
    searchTerminalServers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        instanceId
        instanceName
        ipAddress
        region
        buildDate
        instanceType
        availabilityZone
        dedicatedHostId
        instanceState
        are_licenses_installed
        description
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationTerminalServersId
        terminalServerAmiId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($clientId: ID!) {
    getClient(clientId: $clientId) {
      clientId
      clientName
      leveraged
      multiTimezone
      migrations {
        nextToken
        startedAt
        __typename
      }
      stacks {
        nextToken
        startedAt
        __typename
      }
      userCount
      timeZone
      purchasedStoraged
      internalSubnets
      osVersion
      sqlDriveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $clientId: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      clientId: $clientId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: [SearchableClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClientAggregationInput]
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMigration = /* GraphQL */ `
  query GetMigration($id: ID!) {
    getMigration(id: $id) {
      id
      dataMigrationRegion
      migrationName
      customerBaselineAMI {
        name
        location
        createdAt
        amiType
        description
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationModifiedCustomerBaselineAMIId
        __typename
      }
      modifiedCustomerBaselineAMI {
        nextToken
        startedAt
        __typename
      }
      scheduledStart
      scheduledCutover
      customerInventory {
        nextToken
        startedAt
        __typename
      }
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      terminalServers {
        nextToken
        startedAt
        __typename
      }
      status
      tasks {
        nextToken
        startedAt
        __typename
      }
      useDms
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientMigrationsClientId
      migrationCustomerBaselineAMIId
      __typename
    }
  }
`;
export const listMigrations = /* GraphQL */ `
  query ListMigrations(
    $filter: ModelMigrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMigrations = /* GraphQL */ `
  query SyncMigrations(
    $filter: ModelMigrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMigrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchMigrations = /* GraphQL */ `
  query SearchMigrations(
    $filter: SearchableMigrationFilterInput
    $sort: [SearchableMigrationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMigrationAggregationInput]
  ) {
    searchMigrations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMigrationTask = /* GraphQL */ `
  query GetMigrationTask($id: ID!) {
    getMigrationTask(id: $id) {
      id
      name
      title
      status
      updatedBy
      comment
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      migrationTasksId
      __typename
    }
  }
`;
export const listMigrationTasks = /* GraphQL */ `
  query ListMigrationTasks(
    $filter: ModelMigrationTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrationTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        status
        updatedBy
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationTasksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMigrationTasks = /* GraphQL */ `
  query SyncMigrationTasks(
    $filter: ModelMigrationTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMigrationTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        status
        updatedBy
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        migrationTasksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPod = /* GraphQL */ `
  query GetPod($id: ID!) {
    getPod(id: $id) {
      id
      podName
      domain
      domainUser
      timeZone
      podSubId
      awsAccountId
      awsRegion
      multiTimezone
      vpcId
      vpcCidr
      securityGroupFile
      securityGroupSql
      securityGroupMgmt
      securityGroupAD
      sqlAddress
      sqlName
      sqlAz
      sqlReportAddress
      sqlReportName
      sqlReportAz
      fileServerAddress
      fileServerName
      fileServerAz
      stacks {
        nextToken
        startedAt
        __typename
      }
      dnsIpPrimary
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      instanceKeyPair
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPods = /* GraphQL */ `
  query ListPods(
    $filter: ModelPodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPods = /* GraphQL */ `
  query SyncPods(
    $filter: ModelPodFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchPods = /* GraphQL */ `
  query SearchPods(
    $filter: SearchablePodFilterInput
    $sort: [SearchablePodSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePodAggregationInput]
  ) {
    searchPods(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getStack = /* GraphQL */ `
  query GetStack($id: ID!) {
    getStack(id: $id) {
      id
      stackName
      amiTermServ
      amiTermServBase
      amiAncServ
      amiPxPServ
      az1
      az1Cidr
      az2
      az2Cidr
      dnsIpSecondary
      tgwAttachmentId
      medFusionCidr
      createBaseTermServer
      createPxpServer
      instanceTypeTermServ
      client {
        clientId
        clientName
        leveraged
        multiTimezone
        userCount
        timeZone
        purchasedStoraged
        internalSubnets
        osVersion
        sqlDriveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pod {
        id
        podName
        domain
        domainUser
        timeZone
        podSubId
        awsAccountId
        awsRegion
        multiTimezone
        vpcId
        vpcCidr
        securityGroupFile
        securityGroupSql
        securityGroupMgmt
        securityGroupAD
        sqlAddress
        sqlName
        sqlAz
        sqlReportAddress
        sqlReportName
        sqlReportAz
        fileServerAddress
        fileServerName
        fileServerAz
        dnsIpPrimary
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        instanceKeyPair
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientStacksClientId
      podStacksId
      __typename
    }
  }
`;
export const listStacks = /* GraphQL */ `
  query ListStacks(
    $filter: ModelStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stackName
        amiTermServ
        amiTermServBase
        amiAncServ
        amiPxPServ
        az1
        az1Cidr
        az2
        az2Cidr
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        createBaseTermServer
        createPxpServer
        instanceTypeTermServ
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientStacksClientId
        podStacksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStacks = /* GraphQL */ `
  query SyncStacks(
    $filter: ModelStackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        stackName
        amiTermServ
        amiTermServBase
        amiAncServ
        amiPxPServ
        az1
        az1Cidr
        az2
        az2Cidr
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        createBaseTermServer
        createPxpServer
        instanceTypeTermServ
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientStacksClientId
        podStacksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchStacks = /* GraphQL */ `
  query SearchStacks(
    $filter: SearchableStackFilterInput
    $sort: [SearchableStackSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStackAggregationInput]
  ) {
    searchStacks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        stackName
        amiTermServ
        amiTermServBase
        amiAncServ
        amiPxPServ
        az1
        az1Cidr
        az2
        az2Cidr
        dnsIpSecondary
        tgwAttachmentId
        medFusionCidr
        createBaseTermServer
        createPxpServer
        instanceTypeTermServ
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientStacksClientId
        podStacksId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCSSAClient = /* GraphQL */ `
  query GetCSSAClient($id: ID!) {
    getCSSAClient(id: $id) {
      clientId
      clientName
      region
      awsAccount
      servers {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      supportMembers {
        nextToken
        startedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCSSAClients = /* GraphQL */ `
  query ListCSSAClients(
    $filter: ModelCSSAClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCSSAClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCSSAClients = /* GraphQL */ `
  query SyncCSSAClients(
    $filter: ModelCSSAClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCSSAClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCSSAClients = /* GraphQL */ `
  query SearchCSSAClients(
    $filter: SearchableCSSAClientFilterInput
    $sort: [SearchableCSSAClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCSSAClientAggregationInput]
  ) {
    searchCSSAClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCSSADatabase = /* GraphQL */ `
  query GetCSSADatabase($id: ID!) {
    getCSSADatabase(id: $id) {
      id
      client {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      databaseEnv
      serverName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientServersId
      __typename
    }
  }
`;
export const listCSSADatabases = /* GraphQL */ `
  query ListCSSADatabases(
    $filter: ModelCSSADatabaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCSSADatabases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        databaseEnv
        serverName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientServersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCSSADatabases = /* GraphQL */ `
  query SyncCSSADatabases(
    $filter: ModelCSSADatabaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCSSADatabases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        databaseEnv
        serverName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientServersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCSSADatabases = /* GraphQL */ `
  query SearchCSSADatabases(
    $filter: SearchableCSSADatabaseFilterInput
    $sort: [SearchableCSSADatabaseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCSSADatabaseAggregationInput]
  ) {
    searchCSSADatabases(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        databaseEnv
        serverName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientServersId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSupportMember = /* GraphQL */ `
  query GetSupportMember($id: ID!) {
    getSupportMember(id: $id) {
      email
      name
      client {
        nextToken
        startedAt
        __typename
      }
      supportClientRequests {
        nextToken
        startedAt
        __typename
      }
      cssaUsername
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSupportMembers = /* GraphQL */ `
  query ListSupportMembers(
    $filter: ModelSupportMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSupportMembers = /* GraphQL */ `
  query SyncSupportMembers(
    $filter: ModelSupportMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSupportMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSupportMembers = /* GraphQL */ `
  query SearchSupportMembers(
    $filter: SearchableSupportMemberFilterInput
    $sort: [SearchableSupportMemberSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSupportMemberAggregationInput]
  ) {
    searchSupportMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSupportClientRequest = /* GraphQL */ `
  query GetSupportClientRequest($id: ID!) {
    getSupportClientRequest(id: $id) {
      id
      client {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      supportMember {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      database {
        id
        databaseEnv
        serverName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientServersId
        __typename
      }
      salesforceId
      reason
      timeToLive
      status
      timeRevoked
      timeGranted
      revokedMethod
      stateMachineExecution
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cSSAClientSupportClientRequestsId
      supportMemberSupportClientRequestsId
      supportClientRequestDatabaseId
      __typename
    }
  }
`;
export const listSupportClientRequests = /* GraphQL */ `
  query ListSupportClientRequests(
    $filter: ModelSupportClientRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportClientRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        salesforceId
        reason
        timeToLive
        status
        timeRevoked
        timeGranted
        revokedMethod
        stateMachineExecution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientSupportClientRequestsId
        supportMemberSupportClientRequestsId
        supportClientRequestDatabaseId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSupportClientRequests = /* GraphQL */ `
  query SyncSupportClientRequests(
    $filter: ModelSupportClientRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSupportClientRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        salesforceId
        reason
        timeToLive
        status
        timeRevoked
        timeGranted
        revokedMethod
        stateMachineExecution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientSupportClientRequestsId
        supportMemberSupportClientRequestsId
        supportClientRequestDatabaseId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSupportClientRequests = /* GraphQL */ `
  query SearchSupportClientRequests(
    $filter: SearchableSupportClientRequestFilterInput
    $sort: [SearchableSupportClientRequestSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSupportClientRequestAggregationInput]
  ) {
    searchSupportClientRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        salesforceId
        reason
        timeToLive
        status
        timeRevoked
        timeGranted
        revokedMethod
        stateMachineExecution
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cSSAClientSupportClientRequestsId
        supportMemberSupportClientRequestsId
        supportClientRequestDatabaseId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMigrationRecipes = /* GraphQL */ `
  query GetMigrationRecipes($id: ID!) {
    getMigrationRecipes(id: $id) {
      id
      recipeId
      migrationId
      recipe {
        name
        arn
        description
        required
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      migration {
        id
        dataMigrationRegion
        migrationName
        scheduledStart
        scheduledCutover
        status
        useDms
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientMigrationsClientId
        migrationCustomerBaselineAMIId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMigrationRecipes = /* GraphQL */ `
  query ListMigrationRecipes(
    $filter: ModelMigrationRecipesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrationRecipes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recipeId
        migrationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMigrationRecipes = /* GraphQL */ `
  query SyncMigrationRecipes(
    $filter: ModelMigrationRecipesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMigrationRecipes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        recipeId
        migrationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClientSupport = /* GraphQL */ `
  query GetClientSupport($id: ID!) {
    getClientSupport(id: $id) {
      id
      cSSAClientId
      supportMemberId
      cSSAClient {
        clientId
        clientName
        region
        awsAccount
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      supportMember {
        email
        name
        cssaUsername
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClientSupports = /* GraphQL */ `
  query ListClientSupports(
    $filter: ModelClientSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cSSAClientId
        supportMemberId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClientSupports = /* GraphQL */ `
  query SyncClientSupports(
    $filter: ModelClientSupportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientSupports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cSSAClientId
        supportMemberId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const runEventOrchestrator = /* GraphQL */ `
  query RunEventOrchestrator($payload: eventPayload) {
    runEventOrchestrator(payload: $payload)
  }
`;
export const invokeCSSAOrchestrator = /* GraphQL */ `
  query InvokeCSSAOrchestrator($payload: cssaPayload) {
    invokeCSSAOrchestrator(payload: $payload)
  }
`;
