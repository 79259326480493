
import { Auth } from "aws-amplify"
import STS from "aws-sdk/clients/sts";

class STSWrapper {

    static async getSTSClient(credentials, region) {
        let awsRegion = !region ? "us-east-2" : region;
        return new STS({
            region: awsRegion,
            credentials
        });
    };

    static async getRoleCredentials(roleName, region) {

        const credentials = await Auth.currentCredentials();
        const client = await this.getSTSClient(credentials, region)

        const accountId = process.env.REACT_APP_ENVMGR_ACCOUNT_ID
        const currentUser = await Auth.currentUserInfo()
        const roleArn = `arn:aws:iam::${accountId}:role/${roleName}`

        // Set the session name based on the current Cognito user's e-mail
        // address, and the current UTC timestamp. This will help with auditing
        // in CloudTrail
        const sessionName = `${currentUser.attributes.email}-${Date.now()}`
        const resp = await client.assumeRole({ RoleArn: roleArn, RoleSessionName: sessionName }).promise()

        return resp.Credentials;
    }
}

export default STSWrapper
