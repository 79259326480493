import { useCallback, useEffect, useState } from 'react';
import { nextGenTheme } from '../../themes';
import { useError } from '../../common/hooks'
import { ProductLaunchModal, LoadingWrapper, ProductDetailsModal } from '..';
import ServiceCatalogWrapper from '../../lib/servicecatalog-wrapper'
import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  View,
} from '@aws-amplify/ui-react';
import Utils from '../../lib/utils';

export default function ProductLaunch(
  {
    awsRegion = 'us-east-2',
    credentials,
    productName,
    provisionedProductName,
    provisioningParams,
    targetAccount,
    targetRegion,
    step
  }) {


  const [artifact, setArtifact] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const [launchPath, setLaunchPath] = useState({})
  const [launchTime, setLaunchTime] = useState(null)
  const [newProductAvailable, setNewProductAvailable] = useState(false)
  const [product, setProduct] = useState({})
  const [provisionedProduct, setProvisionedProduct] = useState({})
  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [showProvisionModal, setShowProvisionModal] = useState(false)
  const [updatedTime, setUpdatedTime] = useState(null)

  const { addError } = useError();

  const openLaunchModal = () => {
    setShowLaunchModal(true)
  }
  const closeLaunchModal = (isUpdate) => {
    setShowLaunchModal(false)

    // Delay the re-fetch of the product status after update, since it usually takes a couple of
    // seconds for the Provisioning Status to change in Service Catalog
    const refreshDelay = isUpdate ? 3000 : 0

    setIsLoading(true)
    setTimeout(loadProducts, refreshDelay)

  }

  const openProvisionModal = () => {
    setShowProvisionModal(true)
  }
  const closeProvisionModal = () => {
    setShowProvisionModal(false)
    loadProducts()
  }


  const validateProduct = useCallback((searchProductsResult) => {
    if (searchProductsResult && searchProductsResult.length) {

      // This component is intended to support one product, artifact version, and launch constraint
      // at a time - so throw an error if we detect more than one of any of these.

      if (searchProductsResult.length > 1) {
        throw new Error(`More than one product found using the productName filter ${productName}: ${JSON.stringify(searchProductsResult)}`);
      }

      const productResult = searchProductsResult[0]

      if (productResult.artifacts.length > 1) {
        throw new Error(`More than one available artifact found for ${productName}: ${JSON.stringify(productResult.artifacts)}`);
      }

      if (productResult.launchPaths.length > 1) {
        throw new Error(`More than one available launch constraint found for ${productName}: ${JSON.stringify(productResult.launchPaths)}`);
      }

      // If the product contains a StackSet launch constraint, we need to ensure a targetAccountId and targetRegion have
      // been provided. Otherwise, Service Catalog will launch the product to every account and region defined in the
      // launch constraint.
      if (productResult.launchPaths.find(x => x.ConstraintSummaries && x.ConstraintSummaries.find(s => s.Type === 'STACKSET'))) {
        if (!targetAccount || !targetRegion)
          throw new Error(`Could not render ProductLaunch component. The product ${productName} contains a StackSet Launch Constraint,
          and the targetAccount and/or targetRegion have not been set.`);
      }
    }
  }, [productName, targetAccount, targetRegion])

  const loadProducts = useCallback(async () => {
    setIsLoading(true)
    try {
      const searchProductsPromise = ServiceCatalogWrapper.searchProducts(credentials, awsRegion, productName);
      const provisionedProductsPromise = ServiceCatalogWrapper.searchProvisionedProducts(credentials, awsRegion, provisionedProductName);
      const [searchProductsResult, provisionedProductsResult] = await Promise.all([searchProductsPromise, provisionedProductsPromise])

      validateProduct(searchProductsResult)

      const searchProductResult = searchProductsResult[0]

      if (provisionedProductsResult && provisionedProductsResult.length > 0) {

        const provisionedProductResult = provisionedProductsResult[0]
        const recordDetails = await ServiceCatalogWrapper.describeRecordDetails(provisionedProductResult.LastRecordId, credentials, awsRegion);

        setProvisionedProduct(provisionedProductResult)
        setLaunchTime(Utils.timestampToString(provisionedProductResult.CreatedTime))
        setUpdatedTime(Utils.timestampToString(recordDetails.RecordDetail.UpdatedTime))
        setIsUpdate(true)

        // Show "New Version Available" badge if the artifact ID of the
        // provisioned product is different from the one in the available
        // product.
        const isNewVersionAvailable = provisionedProductResult.ProvisioningArtifactId !==
          (!Utils.isEmpty(searchProductResult.product) && searchProductResult.artifacts[0].Id)

        setNewProductAvailable(isNewVersionAvailable)
      }

      setProduct(searchProductResult.product)
      setArtifact(searchProductResult.artifacts[0])
      setLaunchPath(searchProductResult.launchPaths[0])
      setIsLoading(false)

    } catch (err) {
      addError(err.message, err.statusCode)
    }
  }, [addError, awsRegion, credentials, provisionedProductName, productName, setIsLoading, validateProduct])


  useEffect(() => {
    if (credentials) {
      loadProducts()
    }

  }, [loadProducts, credentials])

  const renderStatusLabel = () => {
    const status = provisionedProduct.Status
    switch (status) {
      case 'ERROR':
        return (
          <Badge size="medium" variation="error">{status}</Badge>
        );
      case 'TAINTED':
        return (
          <Badge size="medium" variation="warning">{status}</Badge>
        );
      case 'AVAILABLE': return (<Badge size="medium" variation="success">{status}</Badge>);
      case undefined: return (<Badge size="medium">NOT LAUNCHED</Badge>);
      default: return (<Badge size="medium" >{status}</Badge>);
    }
  }

  const renderInstructions = (step) => {
    let clickAction = isUpdate ? 'Update' : 'Launch';

    switch (step) {
      case 'sftp-provision':
        return (
            <View as="ol">
              <View as="li">Click the <b>{clickAction}</b> button below to begin provisioning an
                SFTP user and S3 bucket for the customer.</View>
              <View as="li">Click <b>Confirm</b> in the displayed modal.</View>
              <View as="li">Once Provisioning Status changes to <b>AVAILABLE</b>, you may move to the next
                step.</View>
            </View>
        );
      case 'launch-customer-stack':
        return (
            <View as="ol">
              <View as="li">Click the <b>{clickAction}</b> button below to confirm the provisioning parameters for the new customer Stack.</View>
              <View as="li">Click <b>{clickAction}</b> in the displayed modal. If there are fields highlighted as missing, make sure that the Pod and Stack definitions
                in  Environment Manager targeted for migraiton contain all of the pre-requisite parameters.
              </View>
              <View as="li">Once Provisioning Status changes to <b>AVAILABLE</b>, you may move to the next step.</View>
            </View>
        )
      case 'update-customer-stack':
        return (
            <View as="ol">
              <View as="li">Click the <b>{clickAction}</b> button below to update the provisioning parameters for the existing customer stack.</View>
              <View as="li">Enter the AMI ID you want to use for the Terminal Server fleet. Include the desired number of terminal and test servers.</View>
            </View>
        )
      default:
        return (
            <View as="ol">
              <View as="li">
                (Instructions are unavailable for this step)
              </View>
            </View>
        );
    }
  }

  const renderTable = () => {
    return (
      <TableRow key={artifact.Id}>
        <TableCell>
          {newProductAvailable &&
            <View>
              <Badge size="small" variation="success">New Version Available</Badge>
            </View>
          }
          {product.Name}
        </TableCell>
        <TableCell>
          {provisionedProductName}
        </TableCell>
        <TableCell>{renderStatusLabel(provisionedProduct)}</TableCell>
        <TableCell>{launchTime}</TableCell>
        <TableCell>{updatedTime}</TableCell>
        <TableCell>
          <Button size="small" variation="primary" margin="5px"
            onClick={openLaunchModal}
            disabled={provisionedProduct.Status === "UNDER_CHANGE"}
          >

            {isUpdate ? "Update" : "Launch"}
          </Button>
          {isUpdate &&
            <Button textAlign="right" margin="5px" variation="primary" size="small"
              onClick={openProvisionModal}
            >
              Details
            </Button>
          }
          <Button textAlign="right"
            margin="5px"
            variation="primary"
            size="small"
            onClick={loadProducts}>
            Refresh
          </Button >
        </TableCell>
      </TableRow>
    )
  }

  return (
      <LoadingWrapper isLoading={isLoading}>
        <View>
          {renderInstructions(step)}
          <Table textAlign="center" size="small" variation="bordered">
            <TableHead>
              <TableRow>
                <TableCell as="th" >Base Product</TableCell>
                <TableCell as="th" >Provisioned Product</TableCell>
                <TableCell as="th" >Provisioning Status</TableCell>
                <TableCell as="th" >Launched</TableCell>
                <TableCell as="th" >Updated</TableCell>
                <TableCell as="th" >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTable()}
            </TableBody>
          </Table>

          {showLaunchModal &&
              <ThemeProvider theme={nextGenTheme} >
                <ProductLaunchModal
                    artifactId={artifact.Id}
                    awsRegion={awsRegion}
                    credentials={credentials}
                    launchPathId={launchPath.Id}
                    onClose={closeLaunchModal}
                    productId={product.ProductId}
                    provisioningParams={provisioningParams}
                    resourceName={provisionedProductName}
                    targetAccount={targetAccount}
                    targetRegion={targetRegion}
                    show={showLaunchModal}
                    isUpdate={isUpdate}
                >
                </ProductLaunchModal>

              </ThemeProvider>
          }
          {showProvisionModal &&
              <ThemeProvider theme={nextGenTheme} >

                <ProductDetailsModal
                    awsRegion={awsRegion}
                    credentials={credentials}
                    onClose={closeProvisionModal}
                    provisionedProductName={provisionedProductName}
                    show={showProvisionModal}>
                </ProductDetailsModal>
              </ThemeProvider>
          }
        </View>
      </LoadingWrapper >
  );
}