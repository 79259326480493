import {
    Tabs,
    TabItem,
    Link,
    View, TableCell, Button, Table, TableBody, TableRow, TableHead, TextField, Badge, ThemeProvider
} from '@aws-amplify/ui-react'
import {
    ComponentDivider,
    Document,
    MigrationTaskTabTitle
} from '../..'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { nextGenTheme } from '../../../themes';
import Modal from "react-modal";
import { runEventOrchestrator } from '../../../graphql/queries';
import { API } from 'aws-amplify';
import { TerminalServer } from '../../../models';
import { DataStore } from "@aws-amplify/datastore";



export default function MigrationStepBaseApps({ client = {}, migrationId, pod = {}, stack = {}, step }) {

    const current = new Date();
    const date = `${current.getFullYear()}-${(current.getMonth() + 1)}-${current.getDate()}`
    const navigate = useNavigate();
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [selectedInstanceId, setSelectedInstanceId] = React.useState(null);
    const [existingTs, setExistingTs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [description, setDescription] = React.useState('');

    React.useEffect(() => {
        const fetchExistingTs = async () => {
            console.log("Fetching existing Terminal Servers...");

            if (!migrationId) {
                console.log("No migration ID provided, aborting fetch.");
                return;
            }

            try {
                console.log("Querying Terminal Servers by migration ID:", migrationId);
                // Fetch all TerminalServers items
                const allTerminalServers = await DataStore.query(TerminalServer);
                console.log("Fetched all Terminal Servers:", allTerminalServers);

                // Filter TerminalServers that have the provided migrationId
                const terminalServers                   = allTerminalServers.filter(ts => ts.migrationTerminalServersId === migrationId);
                const customerBaselineTerminalServers   = terminalServers.filter(ts => ts.ami.amiType ==="CustomerBaseline");

                console.log("Filtered Terminal Servers:", terminalServers);
                console.log("Filtered Terminal Servers (Customer baseline only):", customerBaselineTerminalServers);

                setExistingTs(customerBaselineTerminalServers);
                setLoading(false);
                console.log("Existing Terminal Servers fetched successfully.");
            } catch (error) {
                console.error("Error fetching Terminal Servers:", error);
                setError(error);
                setLoading(false);
            }
        };

        fetchExistingTs();
    }, [client.clientId, migrationId]);

    // Function to handle changes in the text box
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSnapshotButtonClick = (instanceId) => {
        setConfirmationOpen(true);
        setSelectedInstanceId(instanceId);
    };


    const handleYesButtonClick = async () => {
        try {

            // Trigger logic to create snapshot
            console.log(`Creating snapshot for instance ${selectedInstanceId}`);


            // Trigger runEventOrchestrator for snapshot
            const payload = {
                event_name: 'snapshot',
                migration_id: migrationId,
                client_id: client.clientId,
                instance_id: selectedInstanceId,
                client_account_id: pod.awsAccountId,
                description: description
            };

            await API.graphql({
                query: runEventOrchestrator,
                variables: { payload },
            });

            // Close the modal
            setConfirmationOpen(false);
            alert('Snapshot triggered successfully!');
        } catch (error) {
            console.error('Error taking snapshot', error);

            // Check if there is an error, and if there is at least one error with a message.
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error taking snapshot: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error taking snapshot. Please try again.');
            }

        }
    };

    const handleCancelButtonClick = () => {
        setConfirmationOpen(false);
    };


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="configureAppsInstallTerm" title="Install Base Terminal Server Applications" />}>
                <View as="ol">
                    <View as="li">Log onto the Base Terminal Server instance, <code>{client.clientId}-base</code> using SecureLink</View>
                    <View as="li">Before running through Step 3+, the <code>NGConfig.ini's</code> will need to be fixed to ensure they have the correct NGRoot share paths & encrypted UID & PWD</View>
                    <View as="li">Before running through Step 4+, the <code>ODBC.reg</code> files will need to be configured with appropriate SQL Server datasource references</View>
                    <View as="li">Run the <code>VCPReg.ps1</code> after confirming it has appropriate paths and edits and let it run through completion.</View>
                    <View as="li">Test launching the application from the NG 5 or NG 6 desktop shortcuts.</View>
                </View>
                <Document
                    assetPath='templates/powershell/VCPreg.ps1'
                    showLineNumbers={true}
                    language="powershell"
                    templateVars={
                        {
                            NGROOT_PATH: `\\\\${pod.fileServerName}\\${client.clientId}-NextGenRoot\\`,
                            NG_ENVIRONMENT: 'Prod',
                            PRIMARY_SQL: pod.sqlName,
                            REPORT_SQL: pod.sqlReportName
                        }
                    } />
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="configureAppsInstallAnc" title="Install Ancillary Server Applications" />}>
                <View as="ol">
                    <View as="li">Log onto the Ancillary Server instance, <code>{client.clientId}-anc1</code> using SecureLink.</View>
                    <View as="li">Run the <code>VCPReg.ps1</code> after confirming it has appropriate paths and edits and let it run through completion.</View>
                    <View as="li">Test launching the application from the NG 5 or NG 6 desktop shortcuts.</View>
                </View>
                <Document
                    assetPath='templates/powershell/VCPreg.ps1'
                    showLineNumbers={true}
                    language="powershell"
                    templateVars={
                        {
                            NGROOT_PATH: `\\\\${pod.fileServerName}\\${client.clientId}-NextGenRoot\\`,
                            NG_ENVIRONMENT: 'Prod',
                            PRIMARY_SQL: pod.sqlName,
                            REPORT_SQL: pod.sqlReportName
                        }
                    } />
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.3`} migrationId={migrationId} taskName="configureAppsInstallBase" title="Create Base Terminal Server Image" />}>
                <View as="ol">
                    <View as="li">As Migration Engineer after completing client base terminal NGE configuration hand back over to MCS Hosting Ops Engineer.</View>
                    <View as="li">As MCS Operations Engineer perform the following by logging into the base client terminal server <code>{client.clientId}-base</code> using SecureLink.</View>
                    <View as="li">Perform the following to create a base client golden NGE AMI --> From the Windows Start menu, open <code>EC2LaunchSettings</code>.</View>
                    <View as="li">For Administrator Password, choose <b>Random</b>.</View>
                    <View as="li">Choose <b>Shutdown with Sysprep</b>.</View>
                    <View as="li">Move back over to the Amazon EC2 console, and then choose Instances from the navigation pane and find the client base instance.</View>
                    <View as="li">After the instance state changes to stopped, select your instance.</View>
                    <View as="li">For Actions, choose Image, Create image. For Image name, enter  <code>{client.clientId}-base-{date}</code>.</View>
                    <View as="li">Choose <b>Create image</b>.</View>
                    <View as="li">Once the image is ready, copy the image id to use within  the <b>Terminal Server AMI</b> field of the <Link onClick={() => navigate(`/stack/${stack.id}`, { podName: pod.podName })}>{stack.stackName}</Link> Stack definition.</View>
                    <View as="li">Terminate the instance after successfully creating the <b> Base Terminal Server AMI</b>.</View>
                </View>
                <ComponentDivider level="5" title="Existing Terminal Servers" />
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error fetching data: {error.message}</p>
                ) : (
                    <Table highlightOnHover={true} style={{ width: "500px" }} variation="striped" border="0">
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">
                                    Instance ID
                                </TableCell>
                                <TableCell as="th">
                                    Status
                                </TableCell>
                                <TableCell as="th">
                                    Latest Snapshot
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingTs.map((ts) => {
                                console.log("Instance ID:", ts.instanceId)
                                return (
                                    <TableRow key={ts.instanceId}>
                                        <TableCell size="small" variation="primary">{ts.instanceId}</TableCell>
                                        <TableCell size="small" variation="primary">{ts.status}</TableCell>
                                        <TableCell size="small" variation="primary">{ts.latestSnapshot}</TableCell>
                                        <TableCell>
                                            <ThemeProvider theme={nextGenTheme}>
                                                <Button onClick={(e) => handleSnapshotButtonClick(ts.instanceId, e.target)} size="small" variation="primary">Snapshot</Button>
                                            </ThemeProvider>
                                        </TableCell>
                                        {confirmationOpen && selectedInstanceId === ts.instanceId && (
                                            <Modal
                                                isOpen={confirmationOpen}
                                                onRequestClose={handleCancelButtonClick}
                                                style={customStyles}
                                                appElement={document.getElementById('app')}
                                            >
                                                <ThemeProvider theme={nextGenTheme}>
                                                    <div>
                                                        <ComponentDivider level={4} title={`Snapshot Instance ${selectedInstanceId}`} />
                                                        <p>Description:</p>
                                                        <TextField
                                                            size="small"
                                                            minWidth="300px"
                                                            value={description}
                                                            onChange={handleDescriptionChange}
                                                            placeholder="Add description here"
                                                        /><br /><br />
                                                        Are you sure you want to create an AMI of this Terminal Server?<br /><br />
                                                        <Button size="small" variation="primary" onClick={handleYesButtonClick}>
                                                            Yes
                                                        </Button>&#160;
                                                        <Button size="small" onClick={handleCancelButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </ThemeProvider>
                                            </Modal>
                                        )}
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                )}


            </TabItem >
        </Tabs >
    )
}
