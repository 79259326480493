/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { Stack } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function StackUpdateForm(props) {
  const {
    id: idProp,
    stack: stackModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    stackName: "",
    amiTermServ: "",
    amiTermServBase: "",
    amiAncServ: "",
    amiPxPServ: "",
    az1: "",
    az1Cidr: "",
    az2: "",
    az2Cidr: "",
    dnsIpSecondary: "",
    tgwAttachmentId: "",
    medFusionCidr: "",
    createBaseTermServer: false,
    createPxpServer: false,
    instanceTypeTermServ: "",
  };
  const [stackName, setStackName] = React.useState(initialValues.stackName);
  const [amiTermServ, setAmiTermServ] = React.useState(
    initialValues.amiTermServ
  );
  const [amiTermServBase, setAmiTermServBase] = React.useState(
    initialValues.amiTermServBase
  );
  const [amiAncServ, setAmiAncServ] = React.useState(initialValues.amiAncServ);
  const [amiPxPServ, setAmiPxPServ] = React.useState(initialValues.amiPxPServ);
  const [az1, setAz1] = React.useState(initialValues.az1);
  const [az1Cidr, setAz1Cidr] = React.useState(initialValues.az1Cidr);
  const [az2, setAz2] = React.useState(initialValues.az2);
  const [az2Cidr, setAz2Cidr] = React.useState(initialValues.az2Cidr);
  const [dnsIpSecondary, setDnsIpSecondary] = React.useState(
    initialValues.dnsIpSecondary
  );
  const [tgwAttachmentId, setTgwAttachmentId] = React.useState(
    initialValues.tgwAttachmentId
  );
  const [medFusionCidr, setMedFusionCidr] = React.useState(
    initialValues.medFusionCidr
  );
  const [createBaseTermServer, setCreateBaseTermServer] = React.useState(
    initialValues.createBaseTermServer
  );
  const [createPxpServer, setCreatePxpServer] = React.useState(
    initialValues.createPxpServer
  );
  const [instanceTypeTermServ, setInstanceTypeTermServ] = React.useState(
    initialValues.instanceTypeTermServ
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = stackRecord
      ? { ...initialValues, ...stackRecord }
      : initialValues;
    setStackName(cleanValues.stackName);
    setAmiTermServ(cleanValues.amiTermServ);
    setAmiTermServBase(cleanValues.amiTermServBase);
    setAmiAncServ(cleanValues.amiAncServ);
    setAmiPxPServ(cleanValues.amiPxPServ);
    setAz1(cleanValues.az1);
    setAz1Cidr(cleanValues.az1Cidr);
    setAz2(cleanValues.az2);
    setAz2Cidr(cleanValues.az2Cidr);
    setDnsIpSecondary(cleanValues.dnsIpSecondary);
    setTgwAttachmentId(cleanValues.tgwAttachmentId);
    setMedFusionCidr(cleanValues.medFusionCidr);
    setCreateBaseTermServer(cleanValues.createBaseTermServer);
    setCreatePxpServer(cleanValues.createPxpServer);
    setInstanceTypeTermServ(cleanValues.instanceTypeTermServ);
    setErrors({});
  };
  const [stackRecord, setStackRecord] = React.useState(stackModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Stack, idProp)
        : stackModelProp;
      setStackRecord(record);
    };
    queryData();
  }, [idProp, stackModelProp]);
  React.useEffect(resetStateValues, [stackRecord]);
  const validations = {
    stackName: [],
    amiTermServ: [],
    amiTermServBase: [],
    amiAncServ: [],
    amiPxPServ: [],
    az1: [],
    az1Cidr: [],
    az2: [],
    az2Cidr: [],
    dnsIpSecondary: [],
    tgwAttachmentId: [],
    medFusionCidr: [],
    createBaseTermServer: [],
    createPxpServer: [],
    instanceTypeTermServ: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          stackName,
          amiTermServ,
          amiTermServBase,
          amiAncServ,
          amiPxPServ,
          az1,
          az1Cidr,
          az2,
          az2Cidr,
          dnsIpSecondary,
          tgwAttachmentId,
          medFusionCidr,
          createBaseTermServer,
          createPxpServer,
          instanceTypeTermServ,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Stack.copyOf(stackRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "StackUpdateForm")}
      {...rest}
    >
      <TextField
        label="Stack name"
        isRequired={false}
        isReadOnly={false}
        value={stackName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName: value,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.stackName ?? value;
          }
          if (errors.stackName?.hasError) {
            runValidationTasks("stackName", value);
          }
          setStackName(value);
        }}
        onBlur={() => runValidationTasks("stackName", stackName)}
        errorMessage={errors.stackName?.errorMessage}
        hasError={errors.stackName?.hasError}
        {...getOverrideProps(overrides, "stackName")}
      ></TextField>
      <TextField
        label="Ami term serv"
        isRequired={false}
        isReadOnly={false}
        value={amiTermServ}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ: value,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.amiTermServ ?? value;
          }
          if (errors.amiTermServ?.hasError) {
            runValidationTasks("amiTermServ", value);
          }
          setAmiTermServ(value);
        }}
        onBlur={() => runValidationTasks("amiTermServ", amiTermServ)}
        errorMessage={errors.amiTermServ?.errorMessage}
        hasError={errors.amiTermServ?.hasError}
        {...getOverrideProps(overrides, "amiTermServ")}
      ></TextField>
      <TextField
        label="Ami term serv base"
        isRequired={false}
        isReadOnly={false}
        value={amiTermServBase}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase: value,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.amiTermServBase ?? value;
          }
          if (errors.amiTermServBase?.hasError) {
            runValidationTasks("amiTermServBase", value);
          }
          setAmiTermServBase(value);
        }}
        onBlur={() => runValidationTasks("amiTermServBase", amiTermServBase)}
        errorMessage={errors.amiTermServBase?.errorMessage}
        hasError={errors.amiTermServBase?.hasError}
        {...getOverrideProps(overrides, "amiTermServBase")}
      ></TextField>
      <TextField
        label="Ami anc serv"
        isRequired={false}
        isReadOnly={false}
        value={amiAncServ}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ: value,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.amiAncServ ?? value;
          }
          if (errors.amiAncServ?.hasError) {
            runValidationTasks("amiAncServ", value);
          }
          setAmiAncServ(value);
        }}
        onBlur={() => runValidationTasks("amiAncServ", amiAncServ)}
        errorMessage={errors.amiAncServ?.errorMessage}
        hasError={errors.amiAncServ?.hasError}
        {...getOverrideProps(overrides, "amiAncServ")}
      ></TextField>
      <TextField
        label="Ami px p serv"
        isRequired={false}
        isReadOnly={false}
        value={amiPxPServ}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ: value,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.amiPxPServ ?? value;
          }
          if (errors.amiPxPServ?.hasError) {
            runValidationTasks("amiPxPServ", value);
          }
          setAmiPxPServ(value);
        }}
        onBlur={() => runValidationTasks("amiPxPServ", amiPxPServ)}
        errorMessage={errors.amiPxPServ?.errorMessage}
        hasError={errors.amiPxPServ?.hasError}
        {...getOverrideProps(overrides, "amiPxPServ")}
      ></TextField>
      <SelectField
        label="Az1"
        placeholder="Please select an option"
        isDisabled={false}
        value={az1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1: value,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.az1 ?? value;
          }
          if (errors.az1?.hasError) {
            runValidationTasks("az1", value);
          }
          setAz1(value);
        }}
        onBlur={() => runValidationTasks("az1", az1)}
        errorMessage={errors.az1?.errorMessage}
        hasError={errors.az1?.hasError}
        {...getOverrideProps(overrides, "az1")}
      >
        <option
          children="Us east 1a"
          value="us_east_1a"
          {...getOverrideProps(overrides, "az1option0")}
        ></option>
        <option
          children="Us east 1b"
          value="us_east_1b"
          {...getOverrideProps(overrides, "az1option1")}
        ></option>
        <option
          children="Us east 1c"
          value="us_east_1c"
          {...getOverrideProps(overrides, "az1option2")}
        ></option>
        <option
          children="Us east 1d"
          value="us_east_1d"
          {...getOverrideProps(overrides, "az1option3")}
        ></option>
        <option
          children="Us east 1e"
          value="us_east_1e"
          {...getOverrideProps(overrides, "az1option4")}
        ></option>
        <option
          children="Us east 1f"
          value="us_east_1f"
          {...getOverrideProps(overrides, "az1option5")}
        ></option>
        <option
          children="Us east 2a"
          value="us_east_2a"
          {...getOverrideProps(overrides, "az1option6")}
        ></option>
        <option
          children="Us east 2b"
          value="us_east_2b"
          {...getOverrideProps(overrides, "az1option7")}
        ></option>
        <option
          children="Us east 2c"
          value="us_east_2c"
          {...getOverrideProps(overrides, "az1option8")}
        ></option>
        <option
          children="Us west 2a"
          value="us_west_2a"
          {...getOverrideProps(overrides, "az1option9")}
        ></option>
        <option
          children="Us west 2b"
          value="us_west_2b"
          {...getOverrideProps(overrides, "az1option10")}
        ></option>
        <option
          children="Us west 2c"
          value="us_west_2c"
          {...getOverrideProps(overrides, "az1option11")}
        ></option>
        <option
          children="Us west 2d"
          value="us_west_2d"
          {...getOverrideProps(overrides, "az1option12")}
        ></option>
      </SelectField>
      <TextField
        label="Az1 cidr"
        isRequired={false}
        isReadOnly={false}
        value={az1Cidr}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr: value,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.az1Cidr ?? value;
          }
          if (errors.az1Cidr?.hasError) {
            runValidationTasks("az1Cidr", value);
          }
          setAz1Cidr(value);
        }}
        onBlur={() => runValidationTasks("az1Cidr", az1Cidr)}
        errorMessage={errors.az1Cidr?.errorMessage}
        hasError={errors.az1Cidr?.hasError}
        {...getOverrideProps(overrides, "az1Cidr")}
      ></TextField>
      <SelectField
        label="Az2"
        placeholder="Please select an option"
        isDisabled={false}
        value={az2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2: value,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.az2 ?? value;
          }
          if (errors.az2?.hasError) {
            runValidationTasks("az2", value);
          }
          setAz2(value);
        }}
        onBlur={() => runValidationTasks("az2", az2)}
        errorMessage={errors.az2?.errorMessage}
        hasError={errors.az2?.hasError}
        {...getOverrideProps(overrides, "az2")}
      >
        <option
          children="Us east 1a"
          value="us_east_1a"
          {...getOverrideProps(overrides, "az2option0")}
        ></option>
        <option
          children="Us east 1b"
          value="us_east_1b"
          {...getOverrideProps(overrides, "az2option1")}
        ></option>
        <option
          children="Us east 1c"
          value="us_east_1c"
          {...getOverrideProps(overrides, "az2option2")}
        ></option>
        <option
          children="Us east 1d"
          value="us_east_1d"
          {...getOverrideProps(overrides, "az2option3")}
        ></option>
        <option
          children="Us east 1e"
          value="us_east_1e"
          {...getOverrideProps(overrides, "az2option4")}
        ></option>
        <option
          children="Us east 1f"
          value="us_east_1f"
          {...getOverrideProps(overrides, "az2option5")}
        ></option>
        <option
          children="Us east 2a"
          value="us_east_2a"
          {...getOverrideProps(overrides, "az2option6")}
        ></option>
        <option
          children="Us east 2b"
          value="us_east_2b"
          {...getOverrideProps(overrides, "az2option7")}
        ></option>
        <option
          children="Us east 2c"
          value="us_east_2c"
          {...getOverrideProps(overrides, "az2option8")}
        ></option>
        <option
          children="Us west 2a"
          value="us_west_2a"
          {...getOverrideProps(overrides, "az2option9")}
        ></option>
        <option
          children="Us west 2b"
          value="us_west_2b"
          {...getOverrideProps(overrides, "az2option10")}
        ></option>
        <option
          children="Us west 2c"
          value="us_west_2c"
          {...getOverrideProps(overrides, "az2option11")}
        ></option>
        <option
          children="Us west 2d"
          value="us_west_2d"
          {...getOverrideProps(overrides, "az2option12")}
        ></option>
      </SelectField>
      <TextField
        label="Az2 cidr"
        isRequired={false}
        isReadOnly={false}
        value={az2Cidr}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr: value,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.az2Cidr ?? value;
          }
          if (errors.az2Cidr?.hasError) {
            runValidationTasks("az2Cidr", value);
          }
          setAz2Cidr(value);
        }}
        onBlur={() => runValidationTasks("az2Cidr", az2Cidr)}
        errorMessage={errors.az2Cidr?.errorMessage}
        hasError={errors.az2Cidr?.hasError}
        {...getOverrideProps(overrides, "az2Cidr")}
      ></TextField>
      <TextField
        label="Dns ip secondary"
        isRequired={false}
        isReadOnly={false}
        value={dnsIpSecondary}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary: value,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.dnsIpSecondary ?? value;
          }
          if (errors.dnsIpSecondary?.hasError) {
            runValidationTasks("dnsIpSecondary", value);
          }
          setDnsIpSecondary(value);
        }}
        onBlur={() => runValidationTasks("dnsIpSecondary", dnsIpSecondary)}
        errorMessage={errors.dnsIpSecondary?.errorMessage}
        hasError={errors.dnsIpSecondary?.hasError}
        {...getOverrideProps(overrides, "dnsIpSecondary")}
      ></TextField>
      <TextField
        label="Tgw attachment id"
        isRequired={false}
        isReadOnly={false}
        value={tgwAttachmentId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId: value,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.tgwAttachmentId ?? value;
          }
          if (errors.tgwAttachmentId?.hasError) {
            runValidationTasks("tgwAttachmentId", value);
          }
          setTgwAttachmentId(value);
        }}
        onBlur={() => runValidationTasks("tgwAttachmentId", tgwAttachmentId)}
        errorMessage={errors.tgwAttachmentId?.errorMessage}
        hasError={errors.tgwAttachmentId?.hasError}
        {...getOverrideProps(overrides, "tgwAttachmentId")}
      ></TextField>
      <TextField
        label="Med fusion cidr"
        isRequired={false}
        isReadOnly={false}
        value={medFusionCidr}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr: value,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.medFusionCidr ?? value;
          }
          if (errors.medFusionCidr?.hasError) {
            runValidationTasks("medFusionCidr", value);
          }
          setMedFusionCidr(value);
        }}
        onBlur={() => runValidationTasks("medFusionCidr", medFusionCidr)}
        errorMessage={errors.medFusionCidr?.errorMessage}
        hasError={errors.medFusionCidr?.hasError}
        {...getOverrideProps(overrides, "medFusionCidr")}
      ></TextField>
      <SwitchField
        label="Create base term server"
        defaultChecked={false}
        isDisabled={false}
        isChecked={createBaseTermServer}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer: value,
              createPxpServer,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.createBaseTermServer ?? value;
          }
          if (errors.createBaseTermServer?.hasError) {
            runValidationTasks("createBaseTermServer", value);
          }
          setCreateBaseTermServer(value);
        }}
        onBlur={() =>
          runValidationTasks("createBaseTermServer", createBaseTermServer)
        }
        errorMessage={errors.createBaseTermServer?.errorMessage}
        hasError={errors.createBaseTermServer?.hasError}
        {...getOverrideProps(overrides, "createBaseTermServer")}
      ></SwitchField>
      <SwitchField
        label="Create pxp server"
        defaultChecked={false}
        isDisabled={false}
        isChecked={createPxpServer}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer: value,
              instanceTypeTermServ,
            };
            const result = onChange(modelFields);
            value = result?.createPxpServer ?? value;
          }
          if (errors.createPxpServer?.hasError) {
            runValidationTasks("createPxpServer", value);
          }
          setCreatePxpServer(value);
        }}
        onBlur={() => runValidationTasks("createPxpServer", createPxpServer)}
        errorMessage={errors.createPxpServer?.errorMessage}
        hasError={errors.createPxpServer?.hasError}
        {...getOverrideProps(overrides, "createPxpServer")}
      ></SwitchField>
      <TextField
        label="Instance type term serv"
        isRequired={false}
        isReadOnly={false}
        value={instanceTypeTermServ}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              stackName,
              amiTermServ,
              amiTermServBase,
              amiAncServ,
              amiPxPServ,
              az1,
              az1Cidr,
              az2,
              az2Cidr,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              createBaseTermServer,
              createPxpServer,
              instanceTypeTermServ: value,
            };
            const result = onChange(modelFields);
            value = result?.instanceTypeTermServ ?? value;
          }
          if (errors.instanceTypeTermServ?.hasError) {
            runValidationTasks("instanceTypeTermServ", value);
          }
          setInstanceTypeTermServ(value);
        }}
        onBlur={() =>
          runValidationTasks("instanceTypeTermServ", instanceTypeTermServ)
        }
        errorMessage={errors.instanceTypeTermServ?.errorMessage}
        hasError={errors.instanceTypeTermServ?.hasError}
        {...getOverrideProps(overrides, "instanceTypeTermServ")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || stackModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || stackModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
