import { Auth } from 'aws-amplify';
import { View, Button, Tabs, TabItem, } from '@aws-amplify/ui-react';
import {
    ManageProduct,
    MigrationTaskTabTitle
} from '../../'
import React from 'react';
import Utils from '../../../lib/utils';
import SyntaxHighlighter from 'react-syntax-highlighter'

export default function MigrationStepSftp({ clientId, dataRegion, migrationId, step, pod = {} }) {
    const [credentials, setCredentials] = React.useState(null)
    const environment = process.env.REACT_APP_ENV
    const sftpUsername = `ngcstmr${clientId}`;
    const sftpConnectionString = `${sftpUsername}@${Utils.getSftpEndpointName(dataRegion)}`
    const startWinSCPCmd = `.\\start_winscp.bat ${sftpConnectionString}`

    React.useEffect(() => {
        Auth.currentCredentials().then(credentials => {
            setCredentials(Auth.essentialCredentials(credentials))
        });
    }, [])

    const onDownload = React.useCallback(() => {
        Utils.downloadFile('tools/sftp-client.zip', 'sftp-client.zip');
    }, [])

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="sftpServiceCatalog" title="Provision SFTP Using Service Catalog" />}>
                <ManageProduct
                    step='sftp-provision'
                    credentials={credentials}
                    productName={Utils.getProductName('sftp')}
                    provisionedProductName={`${environment}-${clientId}-lynx-sftp-user`}
                    provisioningParams={[
                        { Key: "customerFileshareAccountId", Value: pod.awsAccountId || process.env.REACT_APP_ENVMGR_ACCOUNT_ID },
                        { Key: "env", Value: environment },
                        { Key: "username", Value: sftpUsername },
                        { Key: "ExecutionID", Value: Utils.generateRandomId() }
                    ]}
                    targetAccount={process.env.REACT_APP_ENVMGR_ACCOUNT_ID}
                    targetRegion={Utils.formatRegion(dataRegion)}
                />
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="sftpValidate" title="Validate Credentials" />}>
                <View as="ol">
                    <View as="li">Click the button on the right to download the latest SFTP Client package.
                        <Button alignSelf="center" size="small" marginLeft={"10px"} height="30px" onClick={onDownload} variation="primary">sftp-client.zip</Button>
                    </View>
                    <View as="li">Unzip the package to a new folder on your desktop.</View>
                    <View as="li">Navigate to the new folder in explorer.</View>
                    <View as="li">While holding down the <b>Shift</b> key, right-click an empty area inside the folder and select <i>Open Powershell window here.</i></View>
                    <View as="li">Execute this command to launch WinSCP: <SyntaxHighlighter>{startWinSCPCmd}</SyntaxHighlighter></View>
                    <View as="li">Click the "View SFTP Credentials" button at the top of this page.</View>
                    <View as="li">Enter the username and password displayed under "SFTP Credentials".</View>
                </View>
            </TabItem>
        </Tabs>
    );
}