import { ComponentDivider, MigrationTaskTabTitle } from '../..'
import { Storage } from 'aws-amplify'
import { Flex, View, Tabs, TabItem } from '@aws-amplify/ui-react'
import { useEffect, useCallback, useState } from 'react'
import { useError } from '../../../common/hooks'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@aws-amplify/ui-react'
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { useNavigate } from 'react-router-dom'

export default function MigrationStepQuestionnaire({ clientId, migrationId, step }) {
    const [fileList, setFileList] = useState([])

    const { addError } = useError();
    const navigate = useNavigate()

    const clientPrefix = `clients/${clientId}/`


    const processFile = ({ file }) => {
        return { file, key: `${clientPrefix}/${file.name}` };
    };

    const onUploadSuccess = ({ file }) => {
        getFileList()
    };

    const downloadFile = useCallback(async (key, filename) => {
        const element = document.createElement('a');
        const url = await Storage.get(key, { download: false })

        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element)
    }, [])

    const onDownloadEmpty = useCallback((event) => {
        downloadFile('docs/MigrationQuestionnaire.docx', `MigrationQuestionnaire_${clientId}.docx`)
    }, [downloadFile, clientId])

    const getFileList = useCallback(async () => {
        let existingFiles
        const fileList = []
        try {
            existingFiles = await Storage.list(clientPrefix, { level: "public", pageSize: "ALL" })
            existingFiles.results.forEach(async file => {
                // Only add actual files to list.
                if (file.key !== clientPrefix) {
                    fileList.push({
                        lastModified: file.lastModified.toLocaleString(),
                        key: file.key,
                        name: file.key.split("/").pop(),
                        size: file.size
                    })

                }
            })
        }
        catch (err) {
            addError(err.message, err.statusCode)
        }

        if (fileList.length > 0) {
            setFileList(fileList)
        }
    }, [addError, clientPrefix])


    useEffect(() => {

        getFileList()
    }, [getFileList, addError])
    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="questionnaireAssessment" title="Pre-Migration Assessment" />}>
                <Flex direction="column">
                    <Flex justifyContent="center" align-items="center" direction="column">
                        <View as="ol">
                            <View as="li">
                                If a Migration Questionnaire has not yet been started for this customer, click the
                                "Download Blank Questionnaire" button below.
                            </View>
                            <View as="li">Once you've gathered the customer's <b>user count</b>, <b>time zone</b>, and
                                whether they plan on purchasing any <b>additional storage</b>, upload the document below
                                and capture this info in step 1.2.
                            </View>
                            <View as="li">
                                Continue uploading revised versions of this file if you need to gather further
                                information or make any revisions. <b>Note:</b> Clicking download again will pull down
                                the most recent version of the questionnaire displayed below.
                            </View>
                        </View>
                        <ComponentDivider level={6}></ComponentDivider>
                        <StorageManager
                            acceptedFileTypes={[
                                'application/msword',
                                'application/pdf',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-powerpoint',
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'image/*',
                                'text/*'
                            ]}
                            accessLevel="public"
                            processFile={processFile}
                            onUploadSuccess={onUploadSuccess}
                            maxFileCount={10}
                        />

                        <Flex direction="row" wrap="wrap" justifyContent="space-between" >
                            <Flex>
                                <Button
                                    alignSelf="flex-end"
                                    size="small"
                                    height="34px"
                                    variation="primary"
                                    onClick={onDownloadEmpty}>
                                    Download Blank Questionnaire
                                </Button>

                            </Flex>
                            <Table highlightOnHover={true} variation="striped">
                                <TableHead>
                                    <TableRow >
                                        <TableCell as="th">Filename</TableCell>
                                        <TableCell as="th">Last Modified</TableCell>
                                        <TableCell as="th">Size</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        fileList.map((file, idx) =>
                                            <TableRow style={{ cursor: 'pointer' }} onClick={() => downloadFile(file.key, file.name)} key={idx}>
                                                <TableCell>{file.name}</TableCell>
                                                <TableCell>{file.lastModified}</TableCell>
                                                <TableCell>{file.size}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>


                        </Flex>
                        <View id="docxcontainer" />
                    </Flex>
                </Flex>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="questionnaireUpdateClient" title="Assessment Update Client Details" />}>
                <Flex justifyContent="center" align-items="center" direction="column">
                    <View as="ol">
                        <View as="li">Once you've gathered the <b>user count</b>, <b>time zone</b> and <b>storage</b> requirements from the customer, click the button below and update the appropriate fields on
                            the customer details page.
                        </View>  <Button onClick={() => navigate(`/client/${clientId}`)} size="small" variation="primary">Update Client Details</Button>
                        <View as="li">Let someone from the IS team know that you've completed this task so that they can begin the build-out.</View>
                    </View>
                </Flex>
            </TabItem>
        </Tabs >
    )
}