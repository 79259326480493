import './App.css';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify'
import { AuthModal } from './ui-components-nge';
import { DataStore } from 'aws-amplify';
import { nextGenTheme } from './themes'
import { ThemeProvider, View } from '@aws-amplify/ui-react'
import App from './App';
import awsExports from './aws-exports';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'sortable-tablesort/sortable.min.js';

Amplify.configure(awsExports);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <View>
            <ThemeProvider theme={nextGenTheme} >
                <AuthModal>
                    {({ signOut, user }) => (
                        <App signOut={async () => { await DataStore.clear(); signOut() }} user={user} />
                    )}
                </AuthModal>
            </ThemeProvider>
        </View>
    </React.StrictMode>
);


