import {
    Loader,
    Flex
} from '@aws-amplify/ui-react';

export default function LoadingWrapper(props) {
    return (
        <>
            {props.isLoading ? < Flex width="100%" justifyContent="center" > <Loader width="50px" height="50px" /></Flex > :
                props.children
            }
        </>
    )
}
