
import { Auth } from "aws-amplify"
import SecretsManager from "aws-sdk/clients/secretsmanager";

class SecretsManagerWrapper {

    static async getSecretsManagerClient(credentials, region) {
        let awsRegion = !region ? "us-east-2" : region;
        return new SecretsManager({
            region: awsRegion,
            credentials,
        });
    };

    static async getSecretValue(secretId, region) {

        const credentials = await Auth.currentCredentials();
        const client = await this.getSecretsManagerClient(credentials, region)
        const secret = await client.getSecretValue({ SecretId: secretId }).promise()

        return secret;
    }
}

export default SecretsManagerWrapper
