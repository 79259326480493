import { ClientCreateForm, ClientUpdateForm } from '../../ui-components'
import { ComponentDivider } from '..'
import { Flex, View } from '@aws-amplify/ui-react'
import Utils from '../../lib/utils'


export default function ClientCreateUpdate({ client, onCloseModal, onSubmitModal }) {

    const formOverrides =
    {
        clientId: { label: "Client ID (SAP ID)" },
        clientName: { label: "Client Name" },
        userCount: { label: "User Count", isRequired: true },
        timeZone: { label: "Customer Time Zone", isRequired: true },
        ...Utils.createTzOptions('timeZone'),
        purchasedStoraged: { label: "Additional Client Purchased Storage (GB)" },
        internalSubnets: { variation: "primary", label: "RDGW Subnets Or VPN if client uses VPN" },
        osVersion: { label: "OS", defaultValue: "Server2019" },
        sqlDriveLetter: { label: "SQL Drive Letter" },
        isLeveragedClient: { label: "Client is Leveraged" }
    }

    function renderForm() {
        if (client) {
            return (
                <View>
                    <ComponentDivider title={`Edit Client - ${client.clientName}`} />
                    <ClientUpdateForm
                        client={client}
                        onCancel={onCloseModal}
                        onSubmit={onSubmitModal}
                        overrides={formOverrides} />
                </View>
            )
        }
        return (
            <View>
                <ComponentDivider title="Create New Client" />
                <ClientCreateForm
                    onCancel={onCloseModal}
                    onSubmit={onSubmitModal}
                    overrides={formOverrides} />
            </View>
        )
    }

    return (
        <Flex direction="column" width="33vw" >
            {Utils.modalCloseButton(onCloseModal)}
            {renderForm()}
        </Flex>
    )
}

