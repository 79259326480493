import { ComponentDivider } from '..'
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Migration } from '../../models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Flex,
    Pagination,
    SearchField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@aws-amplify/ui-react'
import Utils from '../../lib/utils'
import {Auth} from "aws-amplify";

export default function MigrationList() {
    const [currentPage, setCurrentPage] = useState(1)
    const [migrations, setMigrations] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [visibleMigrations, setVisibleMigrations] = useState([])

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups     = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const navigate = useNavigate();

    const pageSize = 25;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    async function clear() {
        const migrations = await DataStore.query(Migration)
        setMigrations(migrations);
    }
    const search = async (event) => {
        const searchStr = event.target.value.toLowerCase();
        const allMigrations = await DataStore.query(Migration);
        const migrations = allMigrations.filter(m =>
            (m.migrationName && m.migrationName.toLowerCase().includes(searchStr)) ||
            (m.status && m.status.toLowerCase().includes(searchStr))
        )
        setCurrentPage(1)
        setMigrations(migrations)
    }

    useEffect(() => {

        const migrationSub = DataStore.observeQuery(Migration,
            Predicates.ALL, {
            sort: (m) => m.migrationName(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            setMigrations(items)
        });
        return () => {
            migrationSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        // This method will fire every time the migrations or currentPage
        // dependencies are updated. It sets totalPages and visibleMigrations
        // state objects accordingly before re-rendering the component.
        setTotalPages(Utils.getPageCount(pageSize, migrations.length))
        setVisibleMigrations(Utils.getPageItems(migrations, pageSize, currentPage))

    }, [migrations, currentPage])

    return (
        <Flex width="60rem" direction="column">
            <ComponentDivider title="All Migrations" />

            <Flex direction="row" justifyContent="space-between">
                <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
            </Flex>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                onChange={handleOnChange}
            />
            <Table highlightOnHover={true} variation="striped">
                <TableHead>
                    <TableRow >
                        <TableCell as="th">Migration Name</TableCell>
                        <TableCell as="th">Target Stack ID</TableCell>
                        <TableCell as="th">Scheduled Start</TableCell>
                        <TableCell as="th">Scheduled Cutover</TableCell>
                        <TableCell as="th">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visibleMigrations.filter(m => m.migrationName).map(migration =>

                            <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/migration/${migration.id}`)} key={migration.id}>
                                <TableCell>{migration.migrationName}</TableCell>
                                <TableCell>{Utils.shortGuid(migration.migrationTargetStackId)}</TableCell>
                                <TableCell >{migration.scheduledStart}</TableCell>
                                <TableCell >{migration.scheduledCutover}</TableCell>
                                <TableCell >{migration.status}</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </Flex >
    );
}
