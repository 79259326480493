import {
    Authenticator,
    useTheme,
    View,
    Image,
    useAuthenticator,
    Button,
    Link
} from '@aws-amplify/ui-react'
import Logo from '../logo.svg'

export default function AuthModal(props) {
    const { tokens } = useTheme();


    const components = {
        Header() {
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Link href="/">
                        <Image
                            position="0"
                            alt="Next Gen Healthcare"
                            width="75%"
                            src={Logo}
                        />
                    </Link>
                </View>
            );
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                    <View textAlign="center" padding={tokens.space.large}>
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },
        SetupTOTP: {
            Header() {
                return null
            }

        },
        ConfirmSignIn: {
            Header() {
                return null
            }
        },
        ResetPassword: {
            Header() {
                return null
            }
        },
        ConfirmResetPassword: {
            Header() {
                return null;
            }
        },
    };
    const formFields = {
        signIn: {
            username: {
                label: 'Email:',
                placeholder: 'Enter your email',
            },
            password: {
                label: 'Password:',
                placeholder: 'Enter your password',
            },
        },
        forceNewPassword: {
            password: {
                placeholder: 'Enter your password',
            },
        },
        resetPassword: {
            username: {
                label: 'Email:',
                placeholder: 'Enter your email',
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                placeholder: 'Enter your confirmation code',
                label: 'Email confirmation code:',
                isRequired: true,
            },
            password: {
                placeholder: 'Enter a new password',
                label: 'New password:',
                isRequired: true,
            },
            confirm_password: {
                placeholder: 'Re-enter your new password',
                label: 'Confirm new password:',
                isRequired: true,
            },
        },
        setupTOTP: {
            QR: {
                totpIssuer: 'NGE Environment Manager',
            },
            confirmation_code: {
                label: 'MFA Token:',
                placeholder: 'Enter your device token',
                isRequired: true,
            },
        },
        confirmSignIn: {
            confirmation_code: {
                label: 'MFA Token:',
                placeholder: 'Enter your device token',
                isRequired: true,
                autoFocus: true,
            },
        },
    };
    ;
    return (
        <Authenticator
            hideSignUp={true}
            formFields={formFields}
            components={components}>
            {props.children}
        </Authenticator>
    );
}

