import React from "react";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import {Recipe} from "../models";
import {DataStore} from "@aws-amplify/datastore";


class TerminalServers extends React.Component
{
    constructor(props)
    {
        super(props);

        /*        this.state = {
                    initial: 'state',
                    recipes: {}
                };*/

    }

    /*    async init()
        {
            await this.initRecipes();
        }


        async initRecipes()
        {
            const recipeSub = DataStore.observeQuery(Recipe,
                Predicates.ALL, {
                    sort: (r) => r.name(SortDirection.ASCENDING)
                }).subscribe(async ({ items }) => {
                    this.state.recipes = items;
                });

            return () => {
                recipeSub.unsubscribe();
            }
        }*/

    cur_packages = [];
    
    getFakeData(key)
    {
        return (sessionStorage.getItem(key) !== null) ? JSON.parse(sessionStorage.getItem(key)) : [];
    }


    setFakeData(key, value)
    {
        return sessionStorage.setItem(key, JSON.stringify(value));
    }


    getClientTerminalServers(clientId)
    {
        const fakeTerminalServerList    = this.getFakeData('fakeTerminalServerStorage');
        let clientTerminalServers       = [];

        for (let i = 0; i < fakeTerminalServerList.length; i++) {
            if (fakeTerminalServerList[i].tags.customerNum.toString() === clientId) {
                clientTerminalServers.push(fakeTerminalServerList[i]);
            }
        }

        return clientTerminalServers;
    }


    async buildTerminalServer(amiId, clientId, status = 'running', instanceCount = 1)
    {
        // TODO
    }


    /**
     * @param migrationId
     * @param type
     */
    async buildBaselineAmi(migrationId, type = 'CustomerBaseline')
    {
        // TODO
    }


    async buildSnapshotComponent(clientId, terminalServerInstanceId, snapshotButton)
    {
        snapshotButton.disabled    = true;
        snapshotButton.innerHTML   = 'Please wait...';

        return await this.buildSnapshot(clientId, terminalServerInstanceId);
    }


    async buildSnapshot(clientId, terminalServerInstanceId)
    {
        // TODO
    }


    getSnapshots(clientId, terminalServerInstanceId)
    {
        // TODO
    }


    getLatestSnapshot(clientId, terminalServerInstanceId)
    {
        // TODO
    }


    getAmi(amiId)
    {
        // TODO
    }

    add_current_inventory(packages, checked){
        console.log(checked)
        console.log(this.cur_packages)
        if(checked){
            this.cur_packages.push({
                key:packages.name,
                    value:packages
            });
        }
        else{
            this.cur_packages.pop(packages.name);
        }
        console.log(this.cur_packages);
    }

    showPackages(recipes, buttonComponent, clientId)
    {
        const packages                              = recipes;

        let clientInventoryStorage                  = this.getFakeData('fakeClientInventoryStorage'); // TODO - use listRecipes
        let packageSubmitContainerDefaultDisplay    = null;
        let clientPackageInventory                  = [];
        let packageChangeTally                      = 0;

        if (typeof clientId !== "undefined") {
            clientPackageInventory                  = (typeof clientInventoryStorage[clientId] !== "undefined") ? clientInventoryStorage[clientId].packages : [];
            packageSubmitContainerDefaultDisplay    = 'none';
        }

        let rows = [];

        for (let i = 0; i < packages.length; i++) {
            let asterisk    = packages[i].required === true ? '*' : null;
            let display     = packages[i].required === true ? 'none' : ''; // TODO - if hiding row for required/included packages, the "asterisk" variable set above is no longer needed
            let isChecked   = false;
            let thumbnail   = !packages[i].thumbnail ? '/img/generic-app-thumbnail.svg' : packages[i].thumbnail;

            for (let j = 0; j < clientPackageInventory.length; j++) {
                if (packages[i].id === clientPackageInventory[j].id) {
                    isChecked = true;
                }
            }

            rows.push(
                <TableRow style={{display:display}}>
                    <TableCell>
                         <input value='' onChange={
                            (e) => {
                                console.log(e);
                                this.add_current_inventory(packages[i], e.target.checked);
                            }
                        } 
                        name="choosePackages[]" type="checkbox" id={packages[i].id} disabled={packages[i].required === true} defaultChecked={(packages[i].required === true) || isChecked} />
                    </TableCell>
                    <TableCell>
                        <img src={thumbnail} style={{width:"25px", minHeight:"25px", verticalAlign: "middle"}} />&#160;&#160;
                        {packages[i].name}{asterisk}
                    </TableCell>
                    <TableCell>
                        <span style={{textDecoration:"underline"}}>Options&#160;&#187;</span>
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <TableBody>
                {rows}
                <TableRow id="packageSubmitContainer">
                    <TableCell colSpan={3}>
                        {buttonComponent}&#160;
                        <Button
                            id="reset"
                            onClick=
                                {
                                    () => {
                                        // document.getElementById('packageSubmitContainer').style.display = packageSubmitContainerDefaultDisplay;

                                        let choosePackages = document.querySelectorAll('input[name="choosePackages[]"]');

                                        for (let i = 0; i < choosePackages.length; i++) {
                                            choosePackages[i].checked = choosePackages[i].defaultChecked;
                                        }
                                        this.cur_packages = [];
                                        console.log(this.cur_packages);
                                    }
                                }
                            size="small"
                            name="scale">
                            Reset
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }


    async buildClientInventoryComponent(migrationId, choosePackages, buildButton, instanceCount = 1, action = 'build')
    {
        let ami;
        let chosenPackages      = [];

        buildButton.disabled    = true;
        buildButton.innerHTML   = 'Please wait...';

        for (let i = 0; i < choosePackages.length; i++) {
            if (choosePackages[i].checked === true) {
                chosenPackages.push(choosePackages[i].id);
            }
        }

        await this.buildClientInventory(chosenPackages, migrationId);

        if (action === 'rebuild') {
            console.log('TODO - stop all terminal servers by clientId');/*await this.stopTerminalServersByClient(clientId);*/
            ami = await this.buildBaselineAmi(migrationId, 'ModifyBaseline');
        } else if (action === 'build') {
            ami = await this.buildBaselineAmi(migrationId, 'CustomerBaseline');
        }

        console.log('TODO - buildTerminalServer() - need AMI data. Return or await this.buildTerminalServer() for ' + instanceCount + ' running instance(s)');
        console.log(ami);
        return ami;//await this.buildTerminalServer(amiId, clientId, 'running', instanceCount);
    }


    async scaleInstancesComponent(clientId, newInstanceCount, scaleButton) // TODO - change params to something like (oldInstanceCount, newInstanceCount, scaleButton)
    {
        scaleButton.disabled    = true;
        scaleButton.innerHTML   = 'Please wait...';

        const clientTerminalServers = this.getClientTerminalServers(clientId);

        /*** TODO - cleanup ***/
        let clientInventoryStorage  = this.getFakeData('fakeClientInventoryStorage');
        let runningInstances        = [];
        let amiId;

        for (let i = 0; i < clientTerminalServers.length; i++) {
            if (clientTerminalServers[i].status === 'running') {

                // get running instances
                runningInstances.push(clientTerminalServers[i]);

                // use any running instance AMI
                amiId = clientTerminalServers[i].tags.amiId;
            }
        }
        /*** END "TODO - cleanup" ***/

        let runningInstanceCount    = runningInstances.length; // TODO - to be passed in as param
        let instanceCount           = (newInstanceCount-runningInstanceCount); // TODO - I can't remember why I had to do this part! check the <StepperField /> field??

        // negative instance count - stop abs(instanceCount) terminal servers
        if (instanceCount < 0) {
            let stopInstanceCount = Math.abs(instanceCount);

            for (let j = 0; j < stopInstanceCount; j++) {
                console.log('stopping ' + runningInstances[j].instanceId + ' for scale DOWN...');
                await this.updateTerminalServer(runningInstances[j].instanceId, clientId, {status: 'stopped', tags: {stoppedReason:'scaleInstances'}});
            }

            // positive instance count - create instanceCount running terminal servers
        } else if (instanceCount > 0) {
            console.log('building/running new TS for scale UP...');
            await this.buildTerminalServer(amiId, clientId, 'running', instanceCount);

            // 0 instance count - do nothing
        } else {
            console.log('no instance count update action required...');
        }

        clientInventoryStorage[clientId].instanceCount  = newInstanceCount;

        return this.setFakeData('fakeClientInventoryStorage', clientInventoryStorage);
    }


    /**
     * @param terminalServerInstanceId
     * @param clientId
     * @param {Object} details
     */
    async updateTerminalServer(terminalServerInstanceId, clientId, details)
    {
        // TODO
    }


    async stopTerminalServersByClient(clientId)
    {
        // TODO
    }


    async buildClientInventory(packageIds, migrationId)
    {
        let save = [];

        for (let i = 0; i < packageIds.length; i++) {
            let recipe  = await DataStore.query(Recipe, packageIds[i]);
            let saved   = await DataStore.save(
                Recipe.copyOf(recipe, updated => {
                    updated._version                        = recipe._version;
                    updated.migrationCustomerInventoryId    = migrationId;
                })
            );

            if (recipe) {
                save.push(saved);
            } else {
                console.log('recipe ' + packageIds[i] + ' doesnt exist???'); // TODO - throw/log error
            }
        }

        return save;
    }


    async getClientInventory(migrationId)
    {
        return await DataStore.query(Recipe, (c) => c.migrationCustomerInventoryId.eq(migrationId));
    }


    packagesTableComponent(recipes, buttonComponent, clientId)
    {
        return (
            <Table highlightOnHover={true} variation="striped" style={{border: "3px solid rgb(173, 132, 82)", borderSpacing: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor:"rgb(173, 132, 82)", textAlign:"center"}} as="th" colSpan={3}>
                            <span style={{color: "white"}}>
                                Choose Packages
                            </span>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{textAlign:"right"}}>
                        <TableCell as="th" colSpan={3}>
                            <span style={{fontWeight:"normal"}}>
                                Included *
                            </span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {this.showPackages(recipes, buttonComponent, clientId)}
            </Table>
        );
    }
}


export default TerminalServers;