import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ServiceCatalogWrapper from '../../lib/servicecatalog-wrapper';
import {
    Badge,
    Button,
    Card,
    Flex,
    Grid,
    Text,
    ThemeProvider,
} from '@aws-amplify/ui-react';
import { nextGenTheme } from '../../themes';
import { ComponentDivider, LoadingWrapper } from '..';
import { useError } from '../../common/hooks';
import Utils from '../../lib/utils';

export default function ProductDetailsModal({
    awsRegion,
    credentials,
    onClose,
    provisionedProductName,
    show
}) {

    const [product, setProduct] = useState({})
    const [recordDetails, setRecordDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const { addError } = useError();

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        }
    }

    const renderStatusLabel = (product) => {
        const width = "fit-content"
        switch (product.Status) {
            case 'ERROR':
                return (
                    <Badge width={width} size="medium" variation="error">{product.Status}</Badge>
                );
            case 'AVAILABLE': return (<Badge width={width} size="medium" variation="success">{product.Status}</Badge>);
            default: return (<Badge width={width} size="medium" >{product.Status}</Badge>);
        }
    }

    useEffect(() => {
        setIsLoading(true)
        const listRecordDetails = async () => {
            try {
                const provisionedProducts = await ServiceCatalogWrapper.searchProvisionedProducts(credentials, awsRegion, provisionedProductName);
                const recordDetails = await ServiceCatalogWrapper.describeRecordDetails(provisionedProducts[0].LastRecordId, credentials, awsRegion);
                setProduct(provisionedProducts[0])
                setRecordDetails(recordDetails)
                setIsLoading(false)

            } catch (err) {
                addError(err.message, err.statusCode)
            }

        }

        if (show) {
            listRecordDetails();
        }

    }, [credentials, awsRegion, addError, provisionedProductName, show])

    const renderRecordDetails = () => {
        if (!recordDetails) {
            return null;
        }

        if (!recordDetails.RecordOutputs) {
            return null;
        }

        return recordDetails.RecordOutputs.map(output => {
            return (
                <Card paddingTop={0} key={output.OutputKey}>
                    <Text fontSize="small"><b>Description:  </b><code>{output.Description}</code> </Text>
                    <Text fontSize="small"><b>Key:  </b><code>{output.OutputKey}</code> </Text>
                    <Text fontSize="small"><b>Value:  </b><code>{output.OutputValue}</code> </Text>

                </Card >
            );
        });
    }

    const renderStackSummaries = () => {
        if (!recordDetails) {
            return null;
        }

        if (!recordDetails.StackSummaries) {
            return null;
        }

        return recordDetails.StackSummaries.map(summary => {
            return (
                <Card paddingTop={0} key={summary.StackId}>
                    <Text fontSize="small"><b>Account:  </b><code>{summary.Account}</code> </Text>
                    <Text fontSize="small"><b>Region:  </b><code>{summary.Region}</code> </Text>
                    <Text fontSize="small"><b>Stack Arn:  </b><code>{summary.StackId}</code> </Text>
                    <Text fontSize="small"><b>Status:  </b><code>{summary.StackInstanceStatus && summary.StackInstanceStatus.DetailedStatus}</code> </Text>
                    <Text fontSize="small"><b>Status Reason:  </b><code>{summary.StatusReason}</code> </Text>
                </Card >
            );
        });
    }


    const onModalClose = () => {
        setRecordDetails(null)
        onClose()
    }

    return (
        product && <Modal shouldCloseOnOverlayClick={false} style={modalStyles}
            width="33vw"
            appElement={document.getElementById('app')}
            isOpen={show}
            onRequestClose={onModalClose}>
            <ThemeProvider theme={nextGenTheme}>
                <Flex direction="column" width="600px">
                    {Utils.modalCloseButton(onModalClose)}
                    <ComponentDivider level={4} title={`Provisioned Product Details`}></ComponentDivider>
                    <LoadingWrapper isLoading={isLoading}>
                        <Grid marginTop="-40px" justifyContent="center">
                            <ComponentDivider level={7} title="Product Name"></ComponentDivider>
                            <Text>{product.Name}</Text>

                            <ComponentDivider level={7} title="Status"></ComponentDivider>
                            {renderStatusLabel(product)}
                            {product.StatusMessage &&
                                <>
                                    <ComponentDivider level={7} title="Status Message"></ComponentDivider>
                                    <code>{product.StatusMessage}</code>
                                </>
                            }
                            {recordDetails && recordDetails.StackSummaries && recordDetails.StackSummaries.length > 0 &&
                                <>
                                    <ComponentDivider level={7} title="Stack Details"></ComponentDivider>
                                    {renderStackSummaries()}
                                </>
                            }
                            {recordDetails && recordDetails.RecordOutputs && recordDetails.RecordOutputs.length > 0 &&
                                <>
                                    <ComponentDivider level={7} title="Outputs"></ComponentDivider>
                                    {renderRecordDetails()}
                                </>
                            }
                        </Grid>
                    </LoadingWrapper>

                </Flex>
                <Flex direction="row" alignContent="space-between" justifyContent="right">
                    <Button variation="primary" onClick={onModalClose}>Close</Button>
                </Flex>
            </ThemeProvider>
        </Modal >
    );
}
