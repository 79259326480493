import { ComponentDivider } from '..'
import { Flex, View } from '@aws-amplify/ui-react'
import { StackCreateForm, StackUpdateForm } from '../../ui-components'
import Utils from '../../lib/utils'
import { useCallback } from 'react'
export default function StackCreateUpdate({ stack, pod, onCloseModal }) {

    const formOverrides =
    {
        StackUpdateForm: {
            maxHeight: "700px",
        },
        StackCreateForm: {
            maxHeight: "700px",
        },
        ClearButton: {
            marginBottom: "30px"
        },
        ResetButton: {
            marginBottom: "30px"
        },
        SubmitButton: {
            marginBottom: "30px"
        },
        ...Utils.createAzOptions('az1'),
        ...Utils.createAzOptions('az2'),
        amiTermServBase: { label: "Base Server AMI" },
        amiAncServ: { label: "Ancillary Server AMI" },
        amiPxPServ: { label: "PXP Server AMI" },
        amiTermServ: { label: "Terminal Server AMI" },
        az1: { label: "Availability Zone 1" },
        az1Cidr: { label: "Primary CIDR", placeholder: "127.0.0.1" },
        az2: { label: "Availability Zone 2" },
        az2Cidr: { label: "Secondary CIDR", placeholder: "127.0.0.1" },
        instanceTypeTermServ: { label: "Terminal Server Instance Type", placeholder: "t3.medium" },
        stackName: { label: 'Stack Name' },

    }

    const onStackCreate = useCallback((fields) => {
        fields.podStacksId = pod.id
        return fields
    }, [pod])

    function renderForm() {
        if (stack) {
            return (
                <View>
                    {Utils.modalCloseButton(onCloseModal)}
                    <ComponentDivider title={`Edit Stack - ${stack.stackName}`} />
                    <StackUpdateForm
                        stack={stack}
                        onCancel={onCloseModal}
                        onSuccess={onCloseModal}
                        overrides={formOverrides} />
                </View>
            )
        }
        return (
            <View>
                {Utils.modalCloseButton(onCloseModal)}
                <ComponentDivider title="Create New Stack" />
                <StackCreateForm
                    onSubmit={onStackCreate}
                    onCancel={onCloseModal}
                    onSuccess={onCloseModal}
                    overrides={formOverrides} />
            </View>
        )
    }

    return (
        <Flex direction="column" width="33vw" >
            {renderForm()}
        </Flex>
    )
}
