/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { Client } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ClientCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    clientId: "",
    clientName: "",
    leveraged: false,
    multiTimezone: false,
    userCount: "",
    timeZone: "",
    purchasedStoraged: "",
    internalSubnets: [],
    osVersion: "",
    sqlDriveLetter: "",
  };
  const [clientId, setClientId] = React.useState(initialValues.clientId);
  const [clientName, setClientName] = React.useState(initialValues.clientName);
  const [leveraged, setLeveraged] = React.useState(initialValues.leveraged);
  const [multiTimezone, setMultiTimezone] = React.useState(
    initialValues.multiTimezone
  );
  const [userCount, setUserCount] = React.useState(initialValues.userCount);
  const [timeZone, setTimeZone] = React.useState(initialValues.timeZone);
  const [purchasedStoraged, setPurchasedStoraged] = React.useState(
    initialValues.purchasedStoraged
  );
  const [internalSubnets, setInternalSubnets] = React.useState(
    initialValues.internalSubnets
  );
  const [osVersion, setOsVersion] = React.useState(initialValues.osVersion);
  const [sqlDriveLetter, setSqlDriveLetter] = React.useState(
    initialValues.sqlDriveLetter
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setClientId(initialValues.clientId);
    setClientName(initialValues.clientName);
    setLeveraged(initialValues.leveraged);
    setMultiTimezone(initialValues.multiTimezone);
    setUserCount(initialValues.userCount);
    setTimeZone(initialValues.timeZone);
    setPurchasedStoraged(initialValues.purchasedStoraged);
    setInternalSubnets(initialValues.internalSubnets);
    setCurrentInternalSubnetsValue("");
    setOsVersion(initialValues.osVersion);
    setSqlDriveLetter(initialValues.sqlDriveLetter);
    setErrors({});
  };
  const [currentInternalSubnetsValue, setCurrentInternalSubnetsValue] =
    React.useState("");
  const internalSubnetsRef = React.createRef();
  const validations = {
    clientId: [{ type: "Required" }],
    clientName: [{ type: "Required" }],
    leveraged: [],
    multiTimezone: [],
    userCount: [{ type: "Required" }],
    timeZone: [],
    purchasedStoraged: [],
    internalSubnets: [],
    osVersion: [],
    sqlDriveLetter: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          clientId,
          clientName,
          leveraged,
          multiTimezone,
          userCount,
          timeZone,
          purchasedStoraged,
          internalSubnets,
          osVersion,
          sqlDriveLetter,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Client(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClientCreateForm")}
      {...rest}
    >
      <TextField
        label="Client Id:"
        isRequired={true}
        isReadOnly={false}
        value={clientId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId: value,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.clientId ?? value;
          }
          if (errors.clientId?.hasError) {
            runValidationTasks("clientId", value);
          }
          setClientId(value);
        }}
        onBlur={() => runValidationTasks("clientId", clientId)}
        errorMessage={errors.clientId?.errorMessage}
        hasError={errors.clientId?.hasError}
        {...getOverrideProps(overrides, "clientId")}
      ></TextField>
      <TextField
        label="Client Name:"
        isRequired={true}
        isReadOnly={false}
        value={clientName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName: value,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.clientName ?? value;
          }
          if (errors.clientName?.hasError) {
            runValidationTasks("clientName", value);
          }
          setClientName(value);
        }}
        onBlur={() => runValidationTasks("clientName", clientName)}
        errorMessage={errors.clientName?.errorMessage}
        hasError={errors.clientName?.hasError}
        {...getOverrideProps(overrides, "clientName")}
      ></TextField>
      <SwitchField
        label="Leveraged"
        defaultChecked={false}
        isDisabled={false}
        isChecked={leveraged}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged: value,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.leveraged ?? value;
          }
          if (errors.leveraged?.hasError) {
            runValidationTasks("leveraged", value);
          }
          setLeveraged(value);
        }}
        onBlur={() => runValidationTasks("leveraged", leveraged)}
        errorMessage={errors.leveraged?.errorMessage}
        hasError={errors.leveraged?.hasError}
        {...getOverrideProps(overrides, "leveraged")}
      ></SwitchField>
      <SwitchField
        label="Multi timezone"
        defaultChecked={false}
        isDisabled={false}
        isChecked={multiTimezone}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone: value,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.multiTimezone ?? value;
          }
          if (errors.multiTimezone?.hasError) {
            runValidationTasks("multiTimezone", value);
          }
          setMultiTimezone(value);
        }}
        onBlur={() => runValidationTasks("multiTimezone", multiTimezone)}
        errorMessage={errors.multiTimezone?.errorMessage}
        hasError={errors.multiTimezone?.hasError}
        {...getOverrideProps(overrides, "multiTimezone")}
      ></SwitchField>
      <TextField
        label="User count"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={userCount}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount: value,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.userCount ?? value;
          }
          if (errors.userCount?.hasError) {
            runValidationTasks("userCount", value);
          }
          setUserCount(value);
        }}
        onBlur={() => runValidationTasks("userCount", userCount)}
        errorMessage={errors.userCount?.errorMessage}
        hasError={errors.userCount?.hasError}
        {...getOverrideProps(overrides, "userCount")}
      ></TextField>
      <SelectField
        label="Time zone"
        placeholder="Please select an option"
        isDisabled={false}
        value={timeZone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone: value,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.timeZone ?? value;
          }
          if (errors.timeZone?.hasError) {
            runValidationTasks("timeZone", value);
          }
          setTimeZone(value);
        }}
        onBlur={() => runValidationTasks("timeZone", timeZone)}
        errorMessage={errors.timeZone?.errorMessage}
        hasError={errors.timeZone?.hasError}
        {...getOverrideProps(overrides, "timeZone")}
      >
        <option
          children="At"
          value="AT"
          {...getOverrideProps(overrides, "timeZoneoption0")}
        ></option>
        <option
          children="Az"
          value="AZ"
          {...getOverrideProps(overrides, "timeZoneoption1")}
        ></option>
        <option
          children="Ct"
          value="CT"
          {...getOverrideProps(overrides, "timeZoneoption2")}
        ></option>
        <option
          children="Et"
          value="ET"
          {...getOverrideProps(overrides, "timeZoneoption3")}
        ></option>
        <option
          children="Mt"
          value="MT"
          {...getOverrideProps(overrides, "timeZoneoption4")}
        ></option>
        <option
          children="Pt"
          value="PT"
          {...getOverrideProps(overrides, "timeZoneoption5")}
        ></option>
      </SelectField>
      <TextField
        label="Purchased storaged"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={purchasedStoraged}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged: value,
              internalSubnets,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.purchasedStoraged ?? value;
          }
          if (errors.purchasedStoraged?.hasError) {
            runValidationTasks("purchasedStoraged", value);
          }
          setPurchasedStoraged(value);
        }}
        onBlur={() =>
          runValidationTasks("purchasedStoraged", purchasedStoraged)
        }
        errorMessage={errors.purchasedStoraged?.errorMessage}
        hasError={errors.purchasedStoraged?.hasError}
        {...getOverrideProps(overrides, "purchasedStoraged")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets: values,
              osVersion,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            values = result?.internalSubnets ?? values;
          }
          setInternalSubnets(values);
          setCurrentInternalSubnetsValue("");
        }}
        currentFieldValue={currentInternalSubnetsValue}
        label={"Internal subnets"}
        items={internalSubnets}
        hasError={errors?.internalSubnets?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "internalSubnets",
            currentInternalSubnetsValue
          )
        }
        errorMessage={errors?.internalSubnets?.errorMessage}
        setFieldValue={setCurrentInternalSubnetsValue}
        inputFieldRef={internalSubnetsRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Internal subnets"
          isRequired={false}
          isReadOnly={false}
          value={currentInternalSubnetsValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.internalSubnets?.hasError) {
              runValidationTasks("internalSubnets", value);
            }
            setCurrentInternalSubnetsValue(value);
          }}
          onBlur={() =>
            runValidationTasks("internalSubnets", currentInternalSubnetsValue)
          }
          errorMessage={errors.internalSubnets?.errorMessage}
          hasError={errors.internalSubnets?.hasError}
          ref={internalSubnetsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "internalSubnets")}
        ></TextField>
      </ArrayField>
      <SelectField
        label="Os version"
        placeholder="Please select an option"
        isDisabled={false}
        value={osVersion}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion: value,
              sqlDriveLetter,
            };
            const result = onChange(modelFields);
            value = result?.osVersion ?? value;
          }
          if (errors.osVersion?.hasError) {
            runValidationTasks("osVersion", value);
          }
          setOsVersion(value);
        }}
        onBlur={() => runValidationTasks("osVersion", osVersion)}
        errorMessage={errors.osVersion?.errorMessage}
        hasError={errors.osVersion?.hasError}
        {...getOverrideProps(overrides, "osVersion")}
      >
        <option
          children="Server2019"
          value="Server2019"
          {...getOverrideProps(overrides, "osVersionoption0")}
        ></option>
      </SelectField>
      <SelectField
        label="Sql drive letter"
        placeholder="Please select an option"
        isDisabled={false}
        value={sqlDriveLetter}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              leveraged,
              multiTimezone,
              userCount,
              timeZone,
              purchasedStoraged,
              internalSubnets,
              osVersion,
              sqlDriveLetter: value,
            };
            const result = onChange(modelFields);
            value = result?.sqlDriveLetter ?? value;
          }
          if (errors.sqlDriveLetter?.hasError) {
            runValidationTasks("sqlDriveLetter", value);
          }
          setSqlDriveLetter(value);
        }}
        onBlur={() => runValidationTasks("sqlDriveLetter", sqlDriveLetter)}
        errorMessage={errors.sqlDriveLetter?.errorMessage}
        hasError={errors.sqlDriveLetter?.hasError}
        {...getOverrideProps(overrides, "sqlDriveLetter")}
      >
        <option
          children="D"
          value="D"
          {...getOverrideProps(overrides, "sqlDriveLetteroption0")}
        ></option>
        <option
          children="E"
          value="E"
          {...getOverrideProps(overrides, "sqlDriveLetteroption1")}
        ></option>
        <option
          children="F"
          value="F"
          {...getOverrideProps(overrides, "sqlDriveLetteroption2")}
        ></option>
        <option
          children="G"
          value="G"
          {...getOverrideProps(overrides, "sqlDriveLetteroption3")}
        ></option>
        <option
          children="H"
          value="H"
          {...getOverrideProps(overrides, "sqlDriveLetteroption4")}
        ></option>
        <option
          children="I"
          value="I"
          {...getOverrideProps(overrides, "sqlDriveLetteroption5")}
        ></option>
        <option
          children="J"
          value="J"
          {...getOverrideProps(overrides, "sqlDriveLetteroption6")}
        ></option>
        <option
          children="K"
          value="K"
          {...getOverrideProps(overrides, "sqlDriveLetteroption7")}
        ></option>
        <option
          children="L"
          value="L"
          {...getOverrideProps(overrides, "sqlDriveLetteroption8")}
        ></option>
        <option
          children="M"
          value="M"
          {...getOverrideProps(overrides, "sqlDriveLetteroption9")}
        ></option>
        <option
          children="N"
          value="N"
          {...getOverrideProps(overrides, "sqlDriveLetteroption10")}
        ></option>
        <option
          children="O"
          value="O"
          {...getOverrideProps(overrides, "sqlDriveLetteroption11")}
        ></option>
        <option
          children="P"
          value="P"
          {...getOverrideProps(overrides, "sqlDriveLetteroption12")}
        ></option>
        <option
          children="Q"
          value="Q"
          {...getOverrideProps(overrides, "sqlDriveLetteroption13")}
        ></option>
        <option
          children="R"
          value="R"
          {...getOverrideProps(overrides, "sqlDriveLetteroption14")}
        ></option>
        <option
          children="S"
          value="S"
          {...getOverrideProps(overrides, "sqlDriveLetteroption15")}
        ></option>
        <option
          children="T"
          value="T"
          {...getOverrideProps(overrides, "sqlDriveLetteroption16")}
        ></option>
        <option
          children="U"
          value="U"
          {...getOverrideProps(overrides, "sqlDriveLetteroption17")}
        ></option>
        <option
          children="V"
          value="V"
          {...getOverrideProps(overrides, "sqlDriveLetteroption18")}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Cancel"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(overrides, "CancelButton")}
          ></Button>
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
