import { Document } from '../..'
import SyntaxHighlighter from 'react-syntax-highlighter';

import {
    Tabs,
    TabItem,
    View
} from '@aws-amplify/ui-react'

export default function MigrationStepDms({ step }) {

    return (
        <Tabs spacing='equal'>
            <TabItem title={`${step}.1 - Stop the DMS Task`} value={`${step}.1`}>
                <View as="ol">
                    <View as="li">Goto the DMS Console in the Migration Factory AWS account, and stop the replication task.</View>
                    <View as="li">Since replication instances cannot be stopped, leave the instance running until the cutover is complete just in case
                        the replication task needs to be resumed.
                    </View>
                </View>
            </TabItem>
            <TabItem title={`${step}.2 - Prepare Target SQL Server Database for Cutover`} value={`${step}.2`}>
                <View as="ol">
                    <View as="li">On the target AWS database, generate the same alter script we used to prepare Blob columns for on-going replication:
                        <Document
                            assetPath='templates/sql/prep-blob-columns.sql'
                            showLineNumbers={true}
                            language="sql"
                        />
                    </View>
                    <View as="li">This time, change all instances of <code>NULL</code> to <code>NOT NULL</code> in the generated alter script, then execute the script.
                    </View>
                    <View as="li">Re-enable all triggers by running <SyntaxHighlighter>EXEC sp_msforeachtable "ALTER TABLE ? ENABLE TRIGGER all"</SyntaxHighlighter></View>
                </View>
            </TabItem>
        </Tabs >
    )
}

