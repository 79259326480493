import {
    Heading,
    Divider,
    Flex,
    useTheme
} from '@aws-amplify/ui-react';


export default function ComponentDivider({ title, level }) {
    const { tokens } = useTheme();

    return (
        <Flex direction="column">
            <Heading fontWeight={tokens.fontWeights.normal} color={tokens.colors.brand.primary[80]} level={level || 3} marginTop="25px">{title}</Heading>
            <Divider
                marginBottom="25px"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                size="small"
                orientation="horizontal"
            ></Divider>
        </Flex>)

}
