import { useRouteError } from "react-router-dom";
import { Alert, Text } from "@aws-amplify/ui-react";


export default function ErrorPage() {
    const error = useRouteError();

    const errorName = () => (error.name || error.status)
    const errorMessage = () => error.message || error.statusText
    const errorDetails = () => {
        if (error.stack) {
            return error.stack.split('\n')
                .slice(0, 9)
                .map((line, idx) => <Text key={idx}>{line}</Text>
                )
        }
        return error.data
    }

    return (
        <Alert
            variation={"warning"}
            isDismissible={false}
            hasIcon={true}
            heading={`${errorName()} - ${errorMessage()}`}

        >
            {errorDetails()}
        </Alert>
    );
}
