import {
    Button, Flex, Pagination, SearchField, Table, TableBody, TableCell,
    TableHead, TableRow, ThemeProvider, View, TextField, StepperField, ExpanderItem, Expander, Badge, Alert
} from '@aws-amplify/ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ComponentDivider, LoadingWrapper } from "..";
import Modal from "react-modal";
import { nextGenTheme } from "../../themes";
import Utils from "../../lib/utils";
import { Auth, API, DataStore, Predicates, SortDirection } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import ClientServicesCreateClient from "../../ui-components/ClientServicesCreateClient";
import { invokeCSSAOrchestrator, listCSSAClients, listCSSADatabases, listSupportClientRequests, listSupportMembers } from '../../graphql/queries';
import { deleteSupportClientRequest, deleteCSSAClient, deleteCSSADatabase } from '../../graphql/mutations';

export default function ClientServicesAdminDetail() {
    const [currentPage, setCurrentPage]                     = useState(1);
    const [totalPages, setTotalPages]                       = useState(1);
    const [isLoading, setIsLoading]                         = useState(true);
    const [deleteClientModalOpen, setDeleteClientModalOpen] = useState(false);
    const [allRequests, setAllRequests]                     = useState([]);
    const [visibleRequests, setVisibleRequests]             = useState([]);
    const [curClient, setCurClient]                         = useState([]);
    const [allSupportMemembers, setAllSupportMembers]       = useState([]);
    const [allDatabases, setAllDatabases]                   = useState([]);
    const navigate                                          = useNavigate();

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('ClientServicesSystemAccessAdmins')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const { clientId } = useParams();
    const pageSize = 25;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '30%'
        },
    };
    function resetRegion(region) {
        if (region) {
            return region.replaceAll("_", "-");
        }
    }
    const search = useCallback(async (event) => {
        const filter = event.target.value;

        API.graphql({
            query: listSupportClientRequests,
            variables: {
                filter: {
                    or: [
                        {
                            salesforceId: {
                                contains: filter
                            }
                        },
                        {
                            reason: {
                                contains: filter
                            }
                        }
                    ],
                }
            }

        }).then(
            (items) => {
                setCurrentPage(1);
                setAllRequests(items.data.listSupportClientRequests.items)
            }
        )
    });


    function openDeleteClientModal() {
        setDeleteClientModalOpen(true);
    }

    function closeDeleteClientModal() {
        setDeleteClientModalOpen(false);
    }

    async function clear() {
        // const clients = await DataStore.query(Client)
        // setClients(getTestRequests()); // SPA-1674 - test hack
    }

    function getSupportMember(id_) {
        return allSupportMemembers.filter(member => (member.id.includes(id_)))[0]
    }

    function getDatabase(id_) {
        return allDatabases.filter(database => (database.id.includes(id_)))[0]
    }

    function perminatelyDeleteClient() {
        for (let i = 0; i < allRequests.length; i++) {
            let payload = {
                action: 'delete',
                customer_id: curClient.clientId,
                region: resetRegion(curClient.region),
                customer_env: (getDatabase(allRequests[i].supportClientRequestDatabaseId)).databaseEnv,
                server_name: (getDatabase(allRequests[i].supportClientRequestDatabaseId)).serverName,
                customer_account_id: curClient.awsAccount,
                request_id: allRequests[i].id,
                username: (getSupportMember(allRequests[i].supportMemberSupportClientRequestsId)).email
            }
            var successful = true
            if (allRequests[i].status === 'Active') {
                API.graphql({
                    query: invokeCSSAOrchestrator,
                    variables: {
                        payload
                    }
                }).then(
                    (items) => {
                        let invokeCSSAOrchestratorResponse = JSON.parse(items.data.invokeCSSAOrchestrator)

                        if (invokeCSSAOrchestratorResponse.statusCode !== 200) {
                            successful = false
                            alert(invokeCSSAOrchestratorResponse.statusCode)
                        }
                    }
                )
            }
            if (successful === true) {
                API.graphql({
                    query: deleteSupportClientRequest,
                    variables: {
                        input: {
                            id: allRequests[i].id,
                            _version: allRequests[i]._version
                        }
                    }
                });

            }
        }

        for (let i = 0; i < allDatabases.length; i++) {
            API.graphql({
                query: deleteCSSADatabase,
                variables: {
                    input: {
                        id: allDatabases[i].id,
                        _version: allDatabases[i]._version
                    }
                }
            })
        }

        API.graphql({
            query: deleteCSSAClient,
            variables: {
                input: {
                    id: curClient.id,
                    _version: curClient._version
                }
            }
        }).then(
            () => {
                navigate('../client-services-system-access/admin');
            }
        );
    }

    useEffect(() => {
        API.graphql({
            query: listCSSAClients,
            variables: {
                filter: {
                    and: [
                        {
                            clientId: {
                                eq: clientId
                            }
                        },

                        {
                            _deleted: {
                                ne: true
                            }
                        }
                    ]
                }

            }
        }).then(
            (items) => {
                let client = items.data.listCSSAClients.items[0]
                setCurClient(client);
                API.graphql({
                    query: listSupportClientRequests,
                    variables: {
                        filter: {
                            and: [{
                                cSSAClientSupportClientRequestsId: {
                                    eq: client.id
                                }
                            },
                            {
                                _deleted: {
                                    ne: true
                                }
                            }
                            ]
                        }
                    }
                }).then(
                    (items) => {
                        setAllRequests(items.data.listSupportClientRequests.items);
                    }
                );
                API.graphql({
                    query: listCSSADatabases,
                    variables: {
                        filter: {
                            and: [{
                                cSSAClientServersId: {
                                    eq: client.id
                                }
                            },
                            {
                                _deleted: {
                                    ne: true
                                }
                            }
                            ]
                        }
                    }
                }).then(
                    (items) => {
                        setAllDatabases(items.data.listCSSADatabases.items);
                    }
                )
            })

        API.graphql({
            query: listSupportMembers
        }).then(
            (items) => {
                setAllSupportMembers(items.data.listSupportMembers.items);
            }
        )



        setIsLoading(false)

    }, []);

    useEffect(() => {
        setTotalPages(Utils.getPageCount(pageSize, allRequests.length))
        setVisibleRequests(Utils.getPageItems(allRequests, pageSize, currentPage))
    }, [allRequests, currentPage]);

    return (
        <View>
            <Modal shouldCloseOnOverlayClick={false} style={customStyles} appElement={document.getElementById('app')} isOpen={deleteClientModalOpen} onRequestClose={closeDeleteClientModal}>
                <ThemeProvider theme={nextGenTheme} >
                    {Utils.modalCloseButton(closeDeleteClientModal)}
                    <ComponentDivider level={4} title="Permanently Delete Client" />
                    <p>
                        <strong>
                            Are you sure you want to permanently delete {curClient.clientId}:{curClient.clientName} from the Client Support Systems Access Environment?
                        </strong>
                    </p>
                    <p>
                        All references to this client will be removed.
                    </p>
                    <Button variation="primary" size="small" onClick={() => { perminatelyDeleteClient() }}>Delete</Button>&#160;&#160;&#160;&#160;
                    <Button size="small" onClick={(e) => { closeDeleteClientModal(); }}>Cancel</Button>
                </ThemeProvider>
            </Modal>
            <LoadingWrapper isLoading={isLoading}>
                <Flex width="60rem" direction="column">
                    <View style={{ textAlign: "right", width: "60rem" }}>
                        <Button size="small" onClick={(e) => { openDeleteClientModal(); }}>Delete Client</Button>
                    </View>

                    <ComponentDivider title={`All request for ${curClient.clientId}: ${curClient.clientName}`} />

                    <Table border="0">
                        <TableBody>
                            <TableRow>
                                <TableCell width="80%" border="0">
                                    <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Table highlightOnHover={true} variation="striped" className="sortable">
                        <TableHead>
                            <TableCell as="th">
                                Salesforce ID
                            </TableCell>
                            <TableCell as="th">
                                Support Member
                            </TableCell>
                            <TableCell as="th">
                                Access Granted
                            </TableCell>
                            <TableCell as="th">
                                Access Expires
                            </TableCell>
                            <TableCell as="th">
                                Status
                            </TableCell>
                            <TableCell as="th">
                                Reason
                            </TableCell>
                            <TableCell as="th">
                                Revoked Method
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                visibleRequests.length === 0 ? <TableRow><TableCell colSpan="7" style={{textAlign: "center"}}>(No Results)</TableCell></TableRow> : visibleRequests.map(sm => (
                                    <TableRow key={sm.salesforceId} style={{ cursor: 'pointer' }}>
                                        <TableCell>{sm.salesforceId}</TableCell>
                                        <TableCell>{(getSupportMember(sm.supportMemberSupportClientRequestsId)).name}</TableCell>
                                        <TableCell>{sm.timeGranted}</TableCell>
                                        <TableCell>{sm.timeRevoked}</TableCell>
                                        <TableCell>{sm.status}</TableCell>
                                        <TableCell>{sm.reason}</TableCell>
                                        <TableCell>{sm.revokedMethod}</TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Flex>
            </LoadingWrapper>
        </View>
    );
}