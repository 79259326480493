import { useNavigate, useParams } from 'react-router-dom';
import {
    Badge,
    Button, Expander, ExpanderItem, Flex, Pagination, SearchField, Table, TableBody, TableCell,
    TableHead, TableRow, TextField, ThemeProvider, View
} from '@aws-amplify/ui-react'
import { ComponentDivider, LoadingWrapper } from "..";
import React, { useCallback, useEffect, useState } from "react";
import { DataStore, Predicates, SortDirection, Auth } from "aws-amplify";
import { nextGenTheme } from "../../themes";
import Modal from "react-modal";
import { Client, Migration } from "../../models";
import Utils from "../../lib/utils";
import { OnboardLegacyClient } from '../../ui-components';
import { createClient, createMigration } from '../../graphql/mutations';
import { API } from 'aws-amplify';



export default function TerminalServersClientList() {

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        // No access to Migration Engineers for term server functionality (NGEMF-833)
        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins')) {
            const error = new Error('401 Not Authorized');
            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [visibleClients, setVisibleClients] = useState([]);
    const [addExistingClientModalOpen, setAddExistingClientModalOpen] = useState(false);
    const [migrationId, setMigration] = useState({});
    const [clientId, setClientId] = useState('');
    const [clientName, setClientName] = useState('');
    const [accountId, setAccountId] = useState('');
    const [region, setRegion] = useState('');
    const [totalTerminalServers, setTotalTerminalServers] = useState('');

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    function openAddExistingClientModal() {
        setAddExistingClientModalOpen(true);
    }

    function closeAddExistingClientModal() {
        setAddExistingClientModalOpen(false);
    }

    const navigate = useNavigate();
    const pageSize = 25;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const search = useCallback(async (event) => {
        const filter = event.target.value.toLowerCase();
        const allClients = await DataStore.query(Client);
        const clients = allClients.filter(client =>
            (client.clientId && client.clientId.includes(filter)) ||
            (client.clientName && client.clientName.toLowerCase().includes(filter)) ||
            (client.timeZone && client.timeZone.toLowerCase().includes(filter))
        );
        setCurrentPage(1);
        setClients(clients);
    }, [])

    const addExistingClient = async (e) => {
        try {
            const new_client = API.graphql({
                query: createClient,
                variables: {
                    input: {
                        clientId: e.clientId,
                        clientName: e.clientName,
                        userCount: e.userCount
                    }
                }
            })

            const new_migration = API.graphql({
                query: createMigration,
                variables: {
                    input: {
                        clientMigrationsClientId: e.clientId,
                        migrationName: `${e.clientName}-migration`,
                        status: "Complete"
                    }
                }
            })

        } catch (error) {
            console.error("Error adding client ID", error);
        }
        closeAddExistingClientModal();
    };

    const checkTargetStackAdded = async (client) => {
        const stacks = await client.stacks.toArray();
        if (stacks.length !== 0) {
            navigate(`/terminal-servers/${client.clientId}`);
        }
        else {
            alert(`The client ${client.clientId} needs to be assigned to a target stack.`)
        }
    }

    async function clear() {
        const clients = await DataStore.observeQuery(Client)
        setClients(clients);
    }

    useEffect(() => {
        const clientSub = DataStore.observeQuery(Client,
            Predicates.ALL, {
            sort: (c) => c.clientName(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            setClients(items)
            setIsLoading(false)
            console.log("client", items)
            // Create a map to store totalTerminalServers count for each client
            const clientTotalServersMap = {};

            // Loop through each items of client
            for (const client of items) {
                if (client.migrations) {
                    let totalServers = 0;
                    const migrationsQueryResult = await client.migrations.toArray();
                    // Loop through each migration of the client
                    for (const migration of migrationsQueryResult) {
                        if (migration.terminalServers) {
                            const terminalServersQueryResult = await migration.terminalServers.values;

                            // Count the total number of terminal servers for the client
                            totalServers += terminalServersQueryResult.filter(ts => ts.instanceId).length;

                        }
                    }
                    // Store the totalTerminalServers count for the client, with clientId as key and totalServers as value
                    clientTotalServersMap[client.clientId] = totalServers;

                }
            }
            setTotalTerminalServers(clientTotalServersMap);
        });

        return () => {
            clientSub.unsubscribe();
        }
    }, [clientId])


    useEffect(() => {
        // This method will fire every time the clients or currentPage
        // dependencies are updated. It sets totalPages and visibleMigrations
        // state objects accordingly before re-rendering the component.
        setTotalPages(Utils.getPageCount(pageSize, clients.length))
        setVisibleClients(Utils.getPageItems(clients, pageSize, currentPage))

    }, [clients, currentPage])

    return (
        <LoadingWrapper isLoading={isLoading}>
            <View>
                <Flex width="60rem" direction="column">
                    <ComponentDivider title="All Clients" />
                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={addExistingClientModalOpen} onRequestClose={closeAddExistingClientModal}>
                        {Utils.modalCloseButton(closeAddExistingClientModal)}
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title="Add Existing Client" />
                            <OnboardLegacyClient
                                onCancel={closeAddExistingClientModal}
                                onSubmit={(e) => addExistingClient(e)}
                            />
                        </ThemeProvider>
                    </Modal>
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={openAddExistingClientModal} style={{ float: "right", width: "250px" }} size="small" variation="primary">Add Existing Client</Button>
                    </div>
                    <Table border="0">
                        <TableBody>
                            <TableRow>
                                <TableCell width="80%" border="0">
                                    <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Table highlightOnHover={true} variation="striped" className="sortable">
                        <TableHead>
                            <TableRow >
                                <TableCell as="th">Client ID</TableCell>
                                <TableCell as="th">Client Name</TableCell>
                                <TableCell as="th">Terminal Servers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                visibleClients.length === 0 ? <TableRow><TableCell colSpan="3" style={{textAlign: "center"}}>(No Results)</TableCell></TableRow> : visibleClients.map(client =>
                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => checkTargetStackAdded(client)}>
                                        <TableCell>{client.clientId}</TableCell>
                                        <TableCell>{client.clientName}</TableCell>
                                        <TableCell>{totalTerminalServers[client.clientId]}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Flex>
            </View>
        </LoadingWrapper >
    );
}