// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AvailabilityZone = {
  "US_EAST_1A": "us_east_1a",
  "US_EAST_1B": "us_east_1b",
  "US_EAST_1C": "us_east_1c",
  "US_EAST_1D": "us_east_1d",
  "US_EAST_1E": "us_east_1e",
  "US_EAST_1F": "us_east_1f",
  "US_EAST_2A": "us_east_2a",
  "US_EAST_2B": "us_east_2b",
  "US_EAST_2C": "us_east_2c",
  "US_WEST_2A": "us_west_2a",
  "US_WEST_2B": "us_west_2b",
  "US_WEST_2C": "us_west_2c",
  "US_WEST_2D": "us_west_2d"
};

const DriveLetter = {
  "D": "D",
  "E": "E",
  "F": "F",
  "G": "G",
  "H": "H",
  "I": "I",
  "J": "J",
  "K": "K",
  "L": "L",
  "M": "M",
  "N": "N",
  "O": "O",
  "P": "P",
  "Q": "Q",
  "R": "R",
  "S": "S",
  "T": "T",
  "U": "U",
  "V": "V"
};

const OperatingSystem = {
  "SERVER2019": "Server2019"
};

const Region = {
  "US_EAST_1": "us_east_1",
  "US_EAST_2": "us_east_2",
  "US_WEST_2": "us_west_2"
};

const RequestStatus = {
  "ACTIVE": "Active",
  "INACTIVE": "Inactive"
};

const PodSubId = {
  "POD0": "Pod0",
  "POD1": "Pod1",
  "POD2": "Pod2",
  "POD3": "Pod3",
  "POD4": "Pod4",
  "POD5": "Pod5",
  "POD6": "Pod6",
  "POD7": "Pod7",
  "POD8": "Pod8",
  "POD9": "Pod9"
};

const TimeZone = {
  "AT": "AT",
  "AZ": "AZ",
  "CT": "CT",
  "ET": "ET",
  "MT": "MT",
  "PT": "PT"
};

const AmiType = {
  "DEPRECATED_AMI": "DeprecatedAMI",
  "CUSTOMER_BASELINE": "CustomerBaseline",
  "MODIFY_BASELINE": "ModifyBaseline"
};

const Status = {
  "NOT_STARTED": "NotStarted",
  "IN_PROGRESS": "InProgress",
  "ON_HOLD": "OnHold",
  "BLOCKED": "Blocked",
  "COMPLETE": "Complete",
  "NOT_APPLICABLE": "NotApplicable"
};

const { AMI, Recipe, TerminalServer, Client, Migration, MigrationTask, Pod, Stack, CSSAClient, CSSADatabase, SupportMember, SupportClientRequest, MigrationRecipes, ClientSupport } = initSchema(schema);

export {
  AMI,
  Recipe,
  TerminalServer,
  Client,
  Migration,
  MigrationTask,
  Pod,
  Stack,
  CSSAClient,
  CSSADatabase,
  SupportMember,
  SupportClientRequest,
  MigrationRecipes,
  ClientSupport,
  AvailabilityZone,
  DriveLetter,
  OperatingSystem,
  Region,
  RequestStatus,
  PodSubId,
  TimeZone,
  AmiType,
  Status
};