import { ComponentDivider, Document, MigrationTaskTabTitle } from '../..'
import SyntaxHighlighter from 'react-syntax-highlighter';
import React from 'react';
import {
    Tabs,
    TabItem,
    View,
    Button
} from '@aws-amplify/ui-react'
import Utils from '../../../lib/utils';


export default function MigrationStepSqlFinal({ client = {}, dataRegion, migrationId, pod = {}, step }) {

    const clientId = client.clientId


    const download_sftpclient = () => {
        Utils.downloadFile('tools/sftp-client.zip', 'sftp-client.zip');
    }

    const sftpConnectionString = `ngcstmr${clientId}@${Utils.getSftpEndpointName(dataRegion)}`
    const startWinSCPCmd = `.\\start_winscp.bat ${sftpConnectionString}`

    const download_restoredb = () => {
        Utils.downloadRestoredb();
    }

    const download_postmigration = () => {
        Utils.downloadPostmigration();
    }

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="updateDatabasesCreateBackups" title="Create Backups" />}>
                <View as="ol">
                    <View as="li">
                        Once you have access to the customer's SQL Server instance, execute the backup script below for each customer database that you plan
                        to migrate to AWS.
                        <Document assetPath='templates/sql/backup.sql'
                            showLineNumbers={true}
                            language="sql"
                            templateVars={
                                {
                                    DB_NAME: 'nextgen',
                                    BACKUP_FOLDER: 'C:\\sql_backups'
                                }
                            } />
                    </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="updateDatabasesTransferUp" title="Transfer Backups to S3" />}>
                <ComponentDivider level={6} title="Option 1 - Using the WinSCP helper script" />
                <View as="ol">
                    <View as="li">
                        To <b>sync</b> all backups in one step can use the <code>sync.ps1</code> helper script:
                    </View>
                    <View as="ul">Ctrl+shift+right-click within the directory where you extracted the <Button alignSelf="center" size="small" marginLeft={"4px"} height="20px" onClick={download_sftpclient} variation="primary">sftp-client.zip</Button> to and Left Click <i>Open PowerShell window here</i> running the following PowerShell code.
                        <SyntaxHighlighter language='powershell'>{`.\\tasks\\sync.ps1 -target "${sftpConnectionString}" -source 'C:\\sql_backups'`}</SyntaxHighlighter>
                    </View>
                </View>
                <ComponentDivider level={6} title="Option 2 - Using WinSCP GUI" />
                <View as="ol">
                    <View as="li">Execute the following command to launch WinSCP: <SyntaxHighlighter>{startWinSCPCmd}</SyntaxHighlighter></View>
                    <View as="li">Enter the credentials from Step 2.3 when prompted.</View>
                    <View as="li">Click the Syncronize button or press CTRL+S to bring up the Syncronize modal.</View>
                    <View as="li">Select the backup folder used in step 4.1 as the source directory, and start the transfer. </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.3`} migrationId={migrationId} taskName="updateDatabasesTransferDown" title="Transfer Backups to Destination Server" />}>
                <View as="ol">
                    <View as="li">Using SecureLink, logon to the Ancillary server in the customer's new AWS environment. </View>
                    <View as="li">Create a folder called <code>{`B:\\Backup\\${clientId}\\migration`}</code></View>
                    <View as="li">Open a new Powershell prompt.</View>
                    <View as="li">Use the script template below to begin syncing files from S3 using the AWS CLI. <b>Note:</b> You don't need to enter any CLI credentials for this command since the customer instance should already have access to the customer bucket.
                        <Document
                            assetPath='templates/powershell/s3_sync_down.ps1'
                            customStyle={{ "paddingTop": "40px" }}
                            showLineNumbers={true}
                            language="powershell"
                            templateVars={
                                {
                                    CREATE_TARGET_PATH: true,
                                    S3_BUCKET: Utils.getCustomerBucketName(clientId),
                                    S3_PREFIX: 'databases',
                                    TARGET_PATH: Utils.generateSqlBackupPath(pod, clientId),
                                    INCLUDE_FILTER: "*.bak",
                                    EXCLUDE_FILTER: "[]"
                                }
                            } />

                    </View>

                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.4`} migrationId={migrationId} taskName="updateDatabasesRestore" title="Restore SQL Backups" />}>
                <View as="ol">
                    <View as="li">If you get errors when running the TSQL from Step 2 below <b>--></b> click the button to download the TSQL which you can run on that SQL Instance to create the missing <code> [sp_restoredb_full] </code> stored procedure.
                        <Button alignSelf="flex-end" size="small" marginLeft={"10px"} height="30px" onClick={download_restoredb} variation="primary">sp_restoredb_full.sql</Button>
                    </View>
                    <View as="li">
                        Execute the restore script below for each backup.
                        <Document assetPath='templates/sql/restore.sql'
                            showLineNumbers={true}
                            language="sql"
                            templateVars={
                                {
                                    BACKUP_FILE: `B:\\Backup\\${clientId}\\Migration\\DBBackup_name.bak`,
                                    DB_NAME: `NGTest_${clientId}`,
                                    DATA_PATH: `${client.sqlDriveLetter}:\\${clientId}\\DBNAME_HERE`,
                                    LOG_PATH: ``,
                                    NEXTGEN_INDEX: ``,
                                    NEXTGEN_LOB: ``,
                                    EXECUTE_TYPE: `FULL`,
                                    PRINT_SQL: `YES`,
                                    EXECUTE_SQL: `NO`
                                }
                            } />
                    </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.5`} migrationId={migrationId} taskName="updateDatabasesPostScript" title="Run Post-Migration Script" />}>
                <View as="ol">
                    <View as="li">If you get errors when running the TSQL from Step 2 below <b>--></b> click the button to download the TSQL which you can run on that SQL Instance to create the missing <code>[sp_PostMigration]</code> stored procedure.
                        <Button alignSelf="flex-end" size="small" marginLeft={"10px"} height="30px" onClick={download_postmigration} variation="primary">sp_PostMigration.sql</Button>
                    </View>
                    <View as="li">
                        Execute the post-migration stored procedure below.
                        <Document
                            assetPath='templates/sql/post-migration.sql'
                            showLineNumbers={true}
                            language="sql"
                            templateVars={
                                {
                                    DBNAME: `NGTest_${clientId}`,
                                    OLDIMAGES_PATH: `\\\\oldfileshare\\images\\Test`,
                                    NEWIMAGES_PATH: `\\\\hostedfileservername\\${clientId}-images\\Test`,
                                    OLDDOCS_PATH: `\\\\oldfileserver\\images-sharename\\Test\\EMR\\Documents\\docsaved\\`,
                                    NEWDOCS_PATH: `\\\\hostedfileservername\\${clientId}-NextGenRoot\\Test\\EMR\\Documents\\docsaved\\`,
                                    OLDNGROOT_PATH: `\\\\oldfileshare\\ngroot`,
                                    NEWNGROOT_PATH: `\\\\hostedfileservername\\${clientId}-NextGenRoot`,
                                    OLDBBP_PATH: `\\\\oldfileshare\\bbp_printer_share`,
                                    NEWBBP_PATH: `\\\\hostedfileservername\\${clientId}-Share`,
                                    OLDIMAGES_DELETE_PATH: `\\\\oldfileshare\\oldimagesdeletepath`,
                                    NEWIMAGES_DELETE_PATH: `\\\\hostedfileservername\\${clientId}-Images\\Test\\newimagesdeletepath`,
                                    OLD_LABRPTS_PATH: `\\\\oldfileshare\\oldNGRootShare`,
                                    NEW_LABRPTS_PATH: `\\\\hostedfileservername\\${clientId}-NextGenRoot`,
                                    PRINT_TSQL: `YES`,
                                    EXEC_TSQL: `NO`
                                }
                            } />
                    </View>
                </View>
            </TabItem>
        </Tabs>
    )
}