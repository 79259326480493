import { ComponentDivider, Document, MigrationTaskTabTitle } from '../..'
import SyntaxHighlighter from 'react-syntax-highlighter';
import React from 'react';
import {
    Tabs,
    Button,
    TabItem,
    View
} from '@aws-amplify/ui-react'

import Utils from '../../../lib/utils';


export default function MigrationStepFileshare({ clientId, dataRegion, migrationId, pod = {}, step, }) {

    const sftpConnectionString = `ngcstmr${clientId}@${Utils.getSftpEndpointName(dataRegion)}`
    const startWinSCPCmd = `.\\start_winscp.bat ${sftpConnectionString}`
    const onDownload = React.useCallback(() => {
        Utils.downloadFile('tools/sftp-client.zip', 'sftp-client.zip');
    }, [])

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="migrateFileshareSetup" title="Setup File Migration Utils" />}>
                <View as="ol">
                    <View as="li">Connect to customer Windows server and validate access to NGROOT share. </View>
                    <View as="li">Copy and unzip<Button alignSelf="center" size="small" marginLeft={"4px"} height="20px" onClick={onDownload} variation="primary">sftp-client.zip</Button> to server.</View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="migrateFileshareTransferUp" title="Transfer Files to S3" />}>
                <ComponentDivider level={6} title="Option 1 - Using the WinSCP helper script" />
                <View as="ol">
                    <View as="li">
                        Execute the SCP helper script with Task set to SyncUp:
                        <SyntaxHighlighter language='powershell'>{`.\\Invoke-SCPTask.ps1 -Task SyncUp -Hostname "${sftpConnectionString}" -LocalFolder '\\\\src\\directory' -RemoteFolder '.' -ExcludeFolders @('ICSImages')`}</SyntaxHighlighter>
                    </View>
                    <b>NOTE:</b> Provide a password if prompted. The password will be encrypted and cached for any future runs of the script in this location. <br />
                    <b>NOTE:</b> To sync targeted folders in parallel, run the <code>Invoke-SCPTask.ps1</code> script for each directory in the local folder separately. <br />
                </View>
                <ComponentDivider level={6} title="Option 2 - Using WinSCP GUI" />
                <View as="ol">
                    <View as="li">Execute the following command to launch WinSCP: <SyntaxHighlighter>{startWinSCPCmd}</SyntaxHighlighter></View>
                    <View as="li">Enter the credentials from Step 2.3 when prompted.</View>
                    <View as="li">Click the Synchronize button or press CTRL+S to bring up the Syncronize modal.</View>
                    <View as="li">Select the root folder you wish to sync. </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.3`} migrationId={migrationId} taskName="migrateFileshareTransferDown" title="Transfer Files from S3 to Target File Share" />}>
                <View as="ol">
                    <View as="li">Using SecureLink, logon to the Ancillary server in the customer's new AWS environment. </View>
                    <View as="li">Open a new Powershell prompt.</View>
                    <View as="li">Use the script template below to begin syncing files from S3 using the AWS CLI. <b>Note:</b> You don't need to enter any CLI credentials for this command since the customer instance should already have access to the customer bucket.
                        <Document
                            assetPath='templates/powershell/s3_sync_down.ps1'
                            customStyle={{ "paddingTop": "40px" }}
                            showLineNumbers={true}
                            language="powershell"
                            templateVars={
                                {
                                    CREATE_TARGET_PATH: false,
                                    S3_BUCKET: Utils.getCustomerBucketName(clientId),
                                    S3_PREFIX: 'ngroot',
                                    TARGET_PATH: Utils.generateFilesharePath(pod, clientId, 'nextgenroot'),
                                    INCLUDE_FILTER: "*",
                                    EXCLUDE_FILTER: "[]"

                                }
                            } />
                    </View>
                </View>
            </TabItem>
        </Tabs>
    )
}

