import { Component } from 'react'
import { LoadingWrapper, ComponentDivider, Document } from '.'
import SecretsManagerWrapper from '../lib/secretsmanager-wrapper'
import STSWrapper from '../lib/sts-wrapper.js'
import Utils from '../lib/utils'
import {
    Flex
} from '@aws-amplify/ui-react';

class SftpPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accessKey: null,
            expiresWhen: null,
            loading: true,
            password: null,
            region: null,
            secretKey: null,
            sessionToken: null,
            username: null,
        }
    }

    async getCredentials(awsRegion) {
        this.setState({ loading: true })

        // Get SFTP Credentials
        const secretResp = await SecretsManagerWrapper.getSecretValue(`/nextgen/${process.env.REACT_APP_ENV}/envmgr/sftp/${this.props.username}`, awsRegion);
        const secretObj = JSON.parse(secretResp.SecretString)

        // Get Temporary IAM Credentials
        const environment = process.env.REACT_APP_ENV
        const roleName = `nge-${environment}-envmgr-sftp-${this.props.username}`
        const credentialsObj = await STSWrapper.getRoleCredentials(roleName, awsRegion)

        // Set the state properties to display credentials in UI.
        this.setState({
            password: secretObj.Password,
            username: secretObj.Username,
            accessKey: credentialsObj.AccessKeyId,
            secretKey: credentialsObj.SecretAccessKey,
            sessionToken: credentialsObj.SessionToken,
            expiresWhen: credentialsObj.Expiration,
            loading: false
        });
    }

    async componentDidMount() {
        await this.getCredentials(Utils.formatRegion(this.props.region))
    }

    render() {
        const bucket_name = `nge-${process.env.REACT_APP_ENV}-envmgr-sftp-${this.props.username}`

        return (
            <Flex width={"33vw"} direction="column" >
                {Utils.modalCloseButton(this.props.onCloseModal)}
                <LoadingWrapper isLoading={this.state.loading}>
                    <ComponentDivider level={5} title="SFTP External Credentials - Use From Client Self Hosted Servers" />
                    <Document
                        assetPath='templates/powershell/launch_winscp.ps1'
                        customStyle={{ "paddingTop": "60px" }}
                        showLineNumbers={true}
                        language="powershell"
                        useLightTheme={true}
                        templateVars={
                            {
                                USERNAME: this.props.username,
                                PASSWORD: this.state.password,
                                SFTP_ENDPOINT: Utils.getSftpEndpointName(this.props.region)
                            }
                        } />
                    <ComponentDivider level={5} title="SFTP Internal Credentials - Use From Within Clients NGMCS Hosted Servers" />
                    <Document
                        assetPath='templates/powershell/launch_winscp.ps1'
                        customStyle={{ "paddingTop": "60px" }}
                        showLineNumbers={true}
                        language="powershell"
                        useLightTheme={true}
                        templateVars={
                            {
                                USERNAME: this.props.username,
                                PASSWORD: this.state.password,
                                SFTP_ENDPOINT: Utils.getInternalSftpEndpointName(this.props.region)
                            }
                        } />
                    <ComponentDivider level={5} title="Temporary IAM Credentials" />

                    <Flex direction="column">
                        <Document
                            assetPath='templates/powershell/launch_winscp_s3.ps1'
                            customStyle={{ "paddingTop": "60px" }}
                            showLineNumbers={true}
                            language="powershell"
                            useLightTheme={true}
                            templateVars={
                                {
                                    ACCESS_KEY: this.state.accessKey,
                                    SECRET_KEY: this.state.secretKey,
                                    SESSION_TOKEN: this.state.sessionToken,
                                    AWS_REGION: Utils.formatRegion(this.props.region),
                                    S3_BUCKET: bucket_name

                                }
                            } />
                    </Flex>
                </LoadingWrapper>
            </Flex>
        );
    }
}

export default SftpPassword;
