import { ComponentDivider, LoadingWrapper } from '..'
import { nextGenTheme } from '../../themes';
import { useEffect, useState } from 'react';
import { useError } from '../../common/hooks'
import _ from 'underscore'
import {
    Button,
    TextField,
    PasswordField,
    ThemeProvider,
    Flex,
    Grid,
    useTheme
} from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import ServiceCatalogWrapper from '../../lib/servicecatalog-wrapper'

export default function ProductLaunchModal({
    artifactId,
    awsRegion,
    credentials,
    launchPathId,
    onClose,
    productId,
    provisioningParams,
    resourceName,
    show,
    targetAccount,
    targetRegion,
    isUpdate
}) {

    const [defaultProvisioningParams, setDefaultProvisioningParams] = useState([])
    const [userParams, setUserParams] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { addError } = useError();
    const { tokens } = useTheme();

    const getProvisioningPreferences = () => {
        if (targetAccount && targetRegion) {
            return {
                StackSetAccounts: [targetAccount],
                StackSetRegions: [targetRegion]
            }
        }
        return null
    }
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        }
    }

    useEffect(() => {
        setIsLoading(true)
        const loadProvisioningParameters = async () => {
            try {
                const resp = await ServiceCatalogWrapper.describeProvisioningParameters(
                    productId,
                    artifactId,
                    launchPathId,
                    credentials,
                    awsRegion
                );
                setDefaultProvisioningParams(resp);
                setIsLoading(false)
            } catch (err) {
                addError(err.message, err.statusCode)
            }

        }
        if (launchPathId && show) {
            loadProvisioningParameters();
        }

    }, [productId, artifactId, launchPathId, credentials, awsRegion, addError, show])

    const onSubmit = async (event) => {
        setSubmitted(true)

        const formElements = Array.from(event.target.form.elements)
        let params = []

        // Grab all the param values from the form
        formElements.forEach(element => {
            if (element.dataset.servicecatalogkey) {
                params.push({ Key: element.dataset.servicecatalogkey, Value: element.value })
            }
        })

        try {
            const provisionRequest = {
                ProductId: productId,
                ProvisioningArtifactId: artifactId,
                PathId: launchPathId,
                ResourceName: resourceName,
                ProvisioningParams: params,
                ProvisioningPreferences: getProvisioningPreferences()
            }
            if (isUpdate) {
                await ServiceCatalogWrapper.updateProvisionedProduct(provisionRequest, credentials, awsRegion);
            }
            else {
                await ServiceCatalogWrapper.provisionProduct(provisionRequest, credentials, awsRegion);
            }

            onClose(true)
        } catch (err) {
            addError(err.message, err.statusCode)
            onClose(false)
        }
    };

    const renderProvisioningParams = () => {
        let missingValue = false;

        const params = _.sortBy(defaultProvisioningParams, 'ParameterKey').map((param, idx) => {
            const providedParam = provisioningParams.find(x => x.Key === param.ParameterKey)
            const paramValue = userParams[param.ParameterKey] || (providedParam ? providedParam.Value : param.DefaultValue)
            const isRequired = !('DefaultValue' in param)
            let inputStyles = {}

            if (isRequired && !paramValue) {
                inputStyles = {
                    backgroundColor: tokens.colors.red['10'].value,
                    border: `1px solid ${tokens.colors.font.error}`,
                }
                missingValue = true
            }

            if (param.ParameterKey.toLowerCase().includes('password')) {
                return (<PasswordField
                    inputStyles={inputStyles}
                    onChange={onParamChange}
                    key={param.ParameterKey}
                    data-servicecatalogkey={param.ParameterKey}
                    defaultValue={paramValue || ''}
                    label={param.ParameterKey + ':'}
                    size="small"
                />)
            } else {
                return (
                    <TextField
                        inputStyles={inputStyles}
                        onChange={onParamChange}
                        key={param.ParameterKey}
                        data-servicecatalogkey={param.ParameterKey}
                        data-isrequired={isRequired}
                        defaultValue={paramValue || ''}
                        label={param.ParameterKey + ':'}
                        size="small"
                    />
                );
            }
        });

        // Avoid infinite re-render loop by first making sure the submitDisabled
        // state is already set to the value we want.
        submitDisabled !== missingValue && setSubmitDisabled(missingValue)

        return params
    }

    const onCancel = () => {
        setDefaultProvisioningParams([])
        onClose(isUpdate)
    }


    const onParamChange = (event) => {
        setUserParams({ ...userParams, [event.target.dataset.servicecatalogkey]: event.target.value })
    }

    return (
        <Modal shouldCloseOnOverlayClick={false} style={modalStyles}
            appElement={document.getElementById('app')}
            isOpen={show}
            onRequestClose={onCancel}>
            <ThemeProvider theme={nextGenTheme}>
                <Flex as="form" direction="column" maxHeight="700px" width="600px">
                    <ComponentDivider level={4} title={`Confirm Parameters`}></ComponentDivider>
                    <LoadingWrapper isLoading={isLoading}>
                        <Grid marginTop="-40px" justifyContent="center" >
                            <ComponentDivider level={6} title="Provisioned Product Name"></ComponentDivider>
                            <TextField
                                size="small"
                                minWidth="500px"
                                value={resourceName}
                                disabled={true}
                            />
                            <ComponentDivider level={6} title="Provisioning Parameters"></ComponentDivider>
                            {renderProvisioningParams()}
                        </Grid>
                    </LoadingWrapper >
                    <Flex direction="row" alignContent="space-between" justifyContent="right" paddingBottom="30px">
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button variation="primary" isDisabled={submitDisabled} onClick={onSubmit} isLoading={submitted}>{isUpdate ? "Update" : "Launch"}</Button>
                    </Flex>
                </Flex>
            </ThemeProvider>
        </Modal>
    );
}
