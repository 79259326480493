import { DataStore } from 'aws-amplify';
import { Button, Flex } from '@aws-amplify/ui-react'
import React from 'react';

export default function DeleteModel({ onCancel, onDelete, name, model = {} }) {


    const deleteModel = () => {
        DataStore.delete(model);
        onDelete()
    }

    const cancel = () => {
        onCancel()
    }

    return (
        <Flex direction="column" >
            <Flex>
                Are you sure you want to delete {name}?
            </Flex>
            <Flex justifyContent="end" alignItems="flex-end">
                <Button onClick={deleteModel} variation="primary" size="small" >
                    Delete
                </Button>

                <Button onClick={cancel} size="small" >
                    Cancel
                </Button>
            </Flex>


        </Flex >
    )
}
