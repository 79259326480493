import { Client } from '../../models'
import { ClientCreateUpdate, LoadingWrapper } from '../'
import { ComponentDivider } from '..'
import { Auth, DataStore, Predicates, SortDirection } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal'
import { nextGenTheme } from '../../themes';
import { API } from 'aws-amplify';
import { createClient } from '../../graphql/mutations';
import {
    Button,
    Flex,
    Pagination,
    SearchField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ThemeProvider,
    View,
} from '@aws-amplify/ui-react'
import Utils from '../../lib/utils';



export default function ClientList() {
    const [clients, setClients] = useState([])
    const [createClientModalOpen, setCreateClientModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [totalPages, setTotalPages] = useState(1)
    const [visibleClients, setVisibleClients] = useState([])
    const [clientsWithPodNames, setClientsWithPodNames] = useState([]);

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const navigate = useNavigate();

    const pageSize = 25;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    function openCreateClientModal() {
        setCreateClientModalOpen(true);
    }
    function closeCreateClientModal() {
        setCreateClientModalOpen(false);
    }

    async function submitCreateClientModal(e) {
        const createNewClient = API.graphql({
            query: createClient,
            variables: {
                input: {
                    clientId: e.clientId,
                    clientName: e.clientName,
                    internalSubnets: e.internalSubnets,
                    leveraged: e.leveraged,
                    multiTimezone: e.multiTimezone,
                    osVersion: e.osVersion ? e.osVersion : 'Server2019',
                    purchasedStoraged: e.purchasedStoraged ? e.purchasedStoraged : 0,
                    sqlDriveLetter: e.sqlDriveLetter ? e.sqlDriveLetter : 'D',
                    timeZone: e.timeZone,
                    userCount: e.userCount ? e.userCount : 0
                }
            }
        })
        closeCreateClientModal();
    }
    const search = useCallback(async (event) => {
        const filter = event.target.value.toLowerCase();
        const allClients = await DataStore.query(Client);
        const clients = allClients.filter(client =>
            (client.clientId && client.clientId.includes(filter)) ||
            (client.clientName && client.clientName.toLowerCase().includes(filter)) ||
            (client.timeZone && client.timeZone.toLowerCase().includes(filter))
        );
        setCurrentPage(1);
        setClients(clients);
    }, [])

    async function clear() {
        const clients = await DataStore.query(Client)
        setClients(clients);
    }

    useEffect(() => {
        const clientSub = DataStore.observeQuery(Client,
            Predicates.ALL, {
            sort: (c) => c.clientName(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            console.log("client", items);
            setClients(items)
            setIsLoading(false)
        });

        return () => {
            clientSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        // This method will fire every time the clients or currentPage
        // dependencies are updated. It sets totalPages and visibleMigrations
        // state objects accordingly before re-rendering the component.
        setTotalPages(Utils.getPageCount(pageSize, clients.length))
        setVisibleClients(Utils.getPageItems(clients, pageSize, currentPage))
        console.log("visibleClients:", visibleClients);
    }, [clients, currentPage])

    useEffect(() => {
        const fetchPodNames = async () => {
            const clientsData = await Promise.all(clients.map(async (client) => {
                const stacks = await client.stacks.toArray();

                const podNames = await Promise.all(stacks.map(async (stack) => {
                    const pod = await stack.pod;
                    return pod ? pod.podName : null;
                }));
                const filteredPodNames = podNames.filter(name => name !== null);
                const podNameToDisplay = filteredPodNames.length > 0 ? filteredPodNames[0] : 'N/A';
                return { ...client, podName: podNameToDisplay };
            }));

            setClientsWithPodNames(clientsData);
        };

        if (clients.length > 0) {
            fetchPodNames();
        } else {
            setClientsWithPodNames([]);
        }
    }, [clients]);

    return (
        <View>
            <LoadingWrapper isLoading={isLoading}>
                <Flex width="60rem" direction="column">
                    <ComponentDivider title="All Clients" />
                    <Flex direction="row" justifyContent="space-between">
                        <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
                        <Button onClick={openCreateClientModal} size="small" variation="primary">Add Client</Button>
                    </Flex>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Table highlightOnHover={true} variation="striped">
                        <TableHead>
                            <TableRow >
                                <TableCell as="th">Client ID</TableCell>
                                <TableCell as="th">Client Name</TableCell>
                                <TableCell as="th">User Count</TableCell>
                                <TableCell as="th">Time Zone</TableCell>
                                <TableCell as="th">Leveraged?</TableCell>
                                <TableCell as="th">Multi-Timezone?</TableCell>
                                <TableCell as="th">POD Name Attached To Client</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleClients.map(client => {
                                const clientWithPodNames = clientsWithPodNames.find(c => c.clientId === client.clientId) || {};
                                return (
                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/client/${client.clientId}`)} key={client.clientId}>
                                        <TableCell>{client.clientId}</TableCell>
                                        <TableCell>{client.clientName}</TableCell>
                                        <TableCell>{client.userCount}</TableCell>
                                        <TableCell>{client.timeZone}</TableCell>
                                        <TableCell>{client.leveraged ? "Yes" : "No"}</TableCell>
                                        <TableCell>{client.multiTimezone ? "Yes" : "No"}</TableCell>
                                        <TableCell>
                                            {clientWithPodNames.podName && clientWithPodNames.podName.length > 0 ? clientWithPodNames.podName : 'N/A'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </Flex >
                <View>
                    <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={createClientModalOpen} onRequestClose={closeCreateClientModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ClientCreateUpdate onCloseModal={closeCreateClientModal} onSubmitModal={e => submitCreateClientModal(e)} />
                        </ThemeProvider>
                    </Modal>
                </View>
            </LoadingWrapper>
        </View>
    );
}
