import { ComponentDivider, StackCreateUpdate, DeleteModel } from '..'
import { DataStore } from "@aws-amplify/datastore";
import { Stack } from '../../models';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { nextGenTheme } from '../../themes';
import Utils from '../../lib/utils';
import {
    Button,
    Flex,
    Table,
    TableBody,
    TableCell,
    TableRow,
    ThemeProvider,
    View
} from '@aws-amplify/ui-react'
import {Auth} from "aws-amplify";



export default function StackDetails() {
    const [stack, setStack] = useState({})
    const [createStackModalOpen, setCreateStackModalOpen] = useState(false)
    const [deleteModelModalOpen, setDeleteModelModalOpen] = useState(false)
    const navigate = useNavigate();
    const { stackId } = useParams();

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups     = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function openCreateStackModal() {
        setCreateStackModalOpen(true);
    }
    function closeCreateStackModal() {
        setCreateStackModalOpen(false);
    }

    function openDeleteModelModal() {
        setDeleteModelModalOpen(true);
    }

    function closeDeleteModelModal() {
        setDeleteModelModalOpen(false);
    }

    function navigateToStackList() {
        navigate('/stacks');
    }

    function stackDetailsRow(name, value) {
        return (
            <TableRow>
                <TableCell as="th" textAlign="right">{name}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    useEffect(() => {
        const stackSub = DataStore.observeQuery(Stack, p => p.id.eq(stackId))
            .subscribe(({ items }) => {
                setStack(items[0])
            });

        return () => {
            stackSub.unsubscribe();
        }
    }, [stackId])

    return (
        <Flex direction="row">
            <Flex width="60rem" direction="column">
                <ComponentDivider title={`Stack Details - ${stack.stackName}`} />
                <Flex direction="row" justifyContent="end" alignItems="flex-end">
                    <View />
                    <Button onClick={openDeleteModelModal} size="small" variation="secondary">
                        Delete
                    </Button>
                    <Button onClick={openCreateStackModal} size="small" variation="primary">
                        Edit Details
                    </Button>
                </Flex>
                <Table size="small" variation='bordered'>
                    <TableBody>
                        {stackDetailsRow("Stack Name", stack.stackName)}
                        {stackDetailsRow("Stack ID", Utils.shortGuid(stack.id))}
                        {stackDetailsRow("Primary AZ", Utils.formatRegion(stack.az1))}
                        {stackDetailsRow("Primary CIDR", stack.az1Cidr)}
                        {stackDetailsRow("Secondary AZ", Utils.formatRegion(stack.az2))}
                        {stackDetailsRow("Secondary CIDR", stack.az2Cidr)}


                        {stackDetailsRow("Terminal Server Instance Type", stack.instanceTypeTermServ)}

                    </TableBody>
                </Table>
            </Flex >
            <View>
                <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={createStackModalOpen} onRequestClose={closeCreateStackModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <StackCreateUpdate stack={stack} onCloseModal={closeCreateStackModal} />
                    </ThemeProvider>
                </Modal>
                <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={deleteModelModalOpen} onRequestClose={closeDeleteModelModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <DeleteModel model={stack} onCancel={closeDeleteModelModal} onDelete={navigateToStackList} name={stack.stackName} />
                    </ThemeProvider>
                </Modal>

            </View>
        </Flex>
    )
}

