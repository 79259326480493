import { ComponentDivider, StackCreateUpdate, PodCreateUpdate, DeleteModel } from '..'
import { DataStore } from "@aws-amplify/datastore";
import { useEffect, useState } from 'react';
import { Pod, Stack, Client } from '../../models';
import Modal from 'react-modal';
import { nextGenTheme } from '../../themes';
import Utils from '../../lib/utils';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    Badge,
    Button,
    Flex,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ThemeProvider,
    View
} from '@aws-amplify/ui-react'
import {Auth} from "aws-amplify";


export default function PodDetails() {
    const [pod, setPod] = useState({})
    const [stacks, setStacks] = useState([])
    const [podclientCount, setpodclientCount] = useState([])
    const [podUserCounts, setUserCounts] = useState([])
    const [createStackModalOpen, setCreateStackModalOpen] = useState(false)
    const [updatePodModalOpen, setUpdatePodModalOpen] = useState(false)
    const [deleteModelModalOpen, setDeleteModelModalOpen] = useState(false)

    const navigate = useNavigate();
    const { podId } = useParams();

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups     = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const renderpodusrcount = (podUserCounts) => {
        let badgeVariation, tooltip

        if (podUserCounts < [350]) {
            badgeVariation = 'success'
            tooltip = 'The POD if leveraged does not yet have the maximum number client end users assigned to it'
        }

        else if (podUserCounts >= [350]) {
            badgeVariation = 'error'
            tooltip = `ALERT -- The POD has the maximum number client end users assigned to it if this is a leveraged POD!!!`
        }

        return (
            <Badge variation={badgeVariation} size="medium" >
                <div className="tooltip" > {podUserCounts}
                    <span className="tooltiptext">{tooltip}</span>
                </div>
            </Badge>
        )
    }

    const renderpodclientcount = (podclientCount) => {
        let badgeVariation, tooltip

        if (podclientCount < [10]) {
            badgeVariation = 'success'
            tooltip = 'The POD if leveraged does not yet have the maximum number (10) of clients assigned to it'
        }

        else if (podclientCount >= [10]) {
            badgeVariation = 'error'
            tooltip = `ALERT -- The POD has the maximum number of clients assigned to it if this is a leveraged POD!!!`
        }

        return (
            <Badge variation={badgeVariation} size="medium" >
                <div className="tooltip" > {podclientCount}
                    <span className="tooltiptext">{tooltip}</span>
                </div>
            </Badge>
        )
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function openCreateStackModal() {
        setCreateStackModalOpen(true);
    }
    function closeCreateStackModal() {
        setCreateStackModalOpen(false);
    }
    function openUpdatePodModal() {
        setUpdatePodModalOpen(true);
    }
    function closeUpdatePodModal() {
        setUpdatePodModalOpen(false);
    }

    function openDeleteModelModal() {
        setDeleteModelModalOpen(true);
    }

    function closeDeleteModelModal() {
        setDeleteModelModalOpen(false);
    }

    function navigateToPodList() {
        navigate('/pods');
    }


    function podDetailsRow(name, value) {
        return (
            <TableRow>
                <TableCell as="th" textAlign="right">{name}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    useEffect(() => {
        const podSub = DataStore.observeQuery(Pod, p => p.id.eq(podId))
            .subscribe(({ items }) => {
                setPod(items[0])
            });

        const stackSub = DataStore.observeQuery(Stack, s => s.pod.id.eq(podId))
            .subscribe(({ items }) => {
                setStacks(items)
            });

        const clientsub = DataStore.observeQuery(Client, c => c.stacks.pod.id.eq(podId))
            .subscribe(({ items }) => {
                // Little trick from Stack Overflow to sum up properties across an array of objects.
                // Can probably move something like this to the Utils.
                const podUserCounts = (items.reduce((n, { userCount }) => n + userCount, 0))
                setUserCounts(podUserCounts)
            });

        const clientssub = DataStore.observeQuery(Client, c => c.stacks.pod.id.eq(podId))
            .subscribe(({ items }) => {
                // Little trick from Stack Overflow to sum up properties across an array of objects.
                // Can probably move something like this to the Utils.
                const podclientCount = items.length
                setpodclientCount(podclientCount)
            });

        return () => {
            stackSub.unsubscribe();
            podSub.unsubscribe();
            clientsub.unsubscribe();
            clientssub.unsubscribe();
        }
    }, [podId], [podUserCounts], [podclientCount])


    return (
        <Flex direction="row">
            <Flex width="60rem" direction="column">
                <ComponentDivider title={`Pod Details - ${pod.podName}`} />
                <Flex direction="row" justifyContent="end" alignItems="flex-end">
                    <View />
                    <Button onClick={openDeleteModelModal} size="small" variation="secondary">
                        Delete
                    </Button>

                    <Button onClick={openUpdatePodModal} size="small" variation="primary">
                        Edit Details
                    </Button>
                </Flex>

                <Table size="small" variation='bordered'>
                    <TableBody>
                        {podDetailsRow("Pod Name", pod.podName)}
                        {podDetailsRow("AWS Account ID", pod.awsAccountId)}
                        {podDetailsRow("AWS Region", Utils.formatRegion(pod.awsRegion))}
                        {podDetailsRow("Domain", pod.domain)}
                        {podDetailsRow("Security Group - Active Directory", pod.securityGroupAD)}
                        {podDetailsRow("Security Group - File Server", pod.securityGroupFile)}
                        {podDetailsRow("Security Group - Management", pod.securityGroupMgmt)}
                        {podDetailsRow("Security Group - SQL", pod.securityGroupSql)}
                        {podDetailsRow("Time Zone", pod.timeZone)}
                        {podDetailsRow("VPC ID", pod.vpcId)}
                        {podDetailsRow("VPC CIDR", pod.vpcCidr)}
                        {podDetailsRow("Multi-Timezone", pod.multiTimezone ? "Yes" : "No")}
                        {podDetailsRow("Primary DNS IP", pod.dnsIpPrimary)}
                        {podDetailsRow("Secondary DNS IP", pod.dnsIpSecondary)}
                        {podDetailsRow("Total Client End-User Count for POD", renderpodusrcount(podUserCounts))}
                        {podDetailsRow("Total Clients assigned to POD", renderpodclientcount(podclientCount))}
                    </TableBody>
                </Table>
                <ComponentDivider title="Stacks" level={4} />
                <Flex direction="row" justifyContent="space-between" alignItems="flex-end">
                    <View />
                    <Button onClick={openCreateStackModal} size="small" variation="primary">
                        Create Stack</Button>

                </Flex>
                <Table highlightOnHover={true} variation="striped">
                    <TableHead>
                        <TableRow >
                            <TableCell as="th" >Stack ID</TableCell>
                            <TableCell as="th">AZ 1</TableCell>
                            <TableCell as="th">AZ 2</TableCell>
                            <TableCell as="th">Primary CIDR</TableCell>
                            <TableCell as="th">Secondary CIDR</TableCell>
                            <TableCell as="th">Is Assigned?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            stacks.map(stack =>

                                <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/stack/${stack.id}`, { podName: pod.podName })} key={stack.id}>
                                    <TableCell>{stack.stackName}</TableCell>
                                    <TableCell>{Utils.formatRegion(stack.az1)}</TableCell>
                                    <TableCell>{Utils.formatRegion(stack.az2)}</TableCell>
                                    <TableCell>{stack.az1Cidr}</TableCell>
                                    <TableCell>{stack.az2Cidr}</TableCell>
                                    <TableCell>{stack.clientStacksClientId ? "Yes" : "No"}</TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Flex >
            <View>
                <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={createStackModalOpen} onRequestClose={closeCreateStackModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <StackCreateUpdate pod={pod} onCloseModal={closeCreateStackModal} />
                    </ThemeProvider>
                </Modal>
                <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={updatePodModalOpen} onRequestClose={closeUpdatePodModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <PodCreateUpdate pod={pod} onCloseModal={closeUpdatePodModal} />
                    </ThemeProvider>
                </Modal>
                <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={deleteModelModalOpen} onRequestClose={closeDeleteModelModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <DeleteModel model={pod} onCancel={closeDeleteModelModal} onDelete={navigateToPodList} name={pod.podName} />
                    </ThemeProvider>
                </Modal>
            </View>
        </Flex>
    )
}

