import { DataStore } from 'aws-amplify'

import {
  Flex,
  View,
} from '@aws-amplify/ui-react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import {
  ClientDetails,
  ClientList,
  ClientStackAssign,
  ErrorNotification,
  ErrorPage,
  MigrationDetails,
  MigrationList,
  NavBar,
  PodDetails,
  PodList,
  StackDetails,
  StackList,
  TerminalServersClientList,
  TerminalServersClientDetails,
  ClientServicesSupport,
  ClientServicesAdmin,
  ClientServicesAdminDetail
} from './ui-components-nge'
import { ErrorProvider } from './common/providers'
import { useError } from './common/hooks';


export default function App({ signOut, user }) {
  const { addError } = useError()
  const appElement = (mainElement) => {

    return (
      <ErrorProvider>
        {
          DataStore.configure({
            errorHandler: (error) => {
              addError(error.message)
            }
          })}
        <View id="app">
          <NavBar signOut={signOut} user={user} />
          <ErrorNotification />
          <Flex margin="30px" id="main" justifyContent="center" align-items="center">
            {mainElement}
          </Flex>
        </View >
      </ErrorProvider>
    )
  }

  const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];
  let index           = <ClientList />;

  if (cognitoGroups.includes('ClientServicesSystemAccessUsers')) {
    index = <ClientServicesSupport />;
  }

  if (cognitoGroups.includes('ClientServicesSystemAccessAdmins')) {
    index = <ClientServicesAdmin />;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: appElement(<Outlet />),
      errorElement: appElement(<ErrorPage />),
      children: [
        { index: true, element: index },
        { path: "client/:clientId", element: <ClientDetails /> },
        { path: "client/:clientId/stacks/assign", element: <ClientStackAssign /> },
        { path: "clients", element: <ClientList /> },
        { path: "client-services-system-access/admin", element: <ClientServicesAdmin /> },
        { path: "client-services-system-access/admin/:clientId", element: <ClientServicesAdminDetail /> },
        { path: "client-services-system-access", element: <ClientServicesSupport /> },
        { path: "migration/:migrationId", element: <MigrationDetails /> },
        { path: "migrations", element: <MigrationList /> },
        { path: "pod/:podId", element: <PodDetails /> },
        { path: "pods", element: <PodList />, },
        { path: "terminal-servers", element: <TerminalServersClientList /> },
        { path: "terminal-servers/:clientId", element: <TerminalServersClientDetails /> },
        { path: "stack/:stackId", element: <StackDetails /> },
        { path: "stacks", element: <StackList /> },
      ]
    }
  ]);

  return (
    <RouterProvider router={router} />
  )
}
