import {
    Tabs,
    TabItem,
    View, Button, TableRow, TableCell, TableBody, Table, TableHead
} from '@aws-amplify/ui-react'
import {
    ComponentDivider,
    ManageProduct,
    MigrationTaskTabTitle
} from '../..'
import React, {useEffect, useState} from 'react'
import {Auth, DataStore, Predicates, SortDirection} from 'aws-amplify';
import Utils from '../../../lib/utils';
import TerminalServers from '../../../lib/terminalservers';
import {AMI, Client, Migration, Recipe} from "../../../models";

const ts = new TerminalServers();

export default function MigrationStepCustomerStack({ client = {}, migrationId, pod = {}, stack = {}, step }) {
    const [credentials, setCredentials] = React.useState(null);
    const [userProps, setUserProps]     = React.useState({});
    const listParam                     = Utils.converToListParam(client.internalSubnets);
    const [state, setState]             = useState(null);
    const [recipes, setRecipes]         = useState({});
    const [amis, setAMIs]               = useState({});


    useEffect(() => {
        Auth.currentCredentials().then(credentials => {
            setCredentials(Auth.essentialCredentials(credentials))
        });
        setUserProps(Utils.getProvisioningProps(client.userCount))
    }, [client]);


    useEffect(() => {
        const recipeSub = DataStore.observeQuery(Recipe,
            Predicates.ALL, {
                sort: (r) => r.name(SortDirection.ASCENDING)
            }).subscribe(async ({items}) => {
            setRecipes(items);
        });

        const AMISub = DataStore.observeQuery(AMI, a => a.migrationModifiedCustomerBaselineAMIId.eq(migrationId))
            .subscribe(async ({items}) => {
            setAMIs(items);
        });

        return () => {
            recipeSub.unsubscribe();
            AMISub.unsubscribe();
        }
    }, []);

    const refreshManagePackages = () => {
        setState(managePackages(recipes));
    };


    //TEMPORARY - remove after testing
    async function resetRecipes() {
        let save = [];

        for (let i = 0; i < recipes.length; i++) {
            let recipe  = await DataStore.query(Recipe, recipes[i].id);
            console.log(recipe);
            let saved   = await DataStore.save(
                Recipe.copyOf(recipe, updated => {
                    updated._version                        = recipe._version;
                    updated.migrationCustomerInventoryId    = null;
                })
            );

            if (recipe) {
                save.push(saved);
            } else {
                console.log('recipe ' + recipes[i].id + ' doesnt exist???');
            }
        }

        console.log(save);

        return refreshManagePackages();
    }


    //TEMPORARY - remove after testing
    async function resetAMIs() {
        let save = [];

        for (let i = 0; i < amis.length; i++) {
            let ami     = await DataStore.query(AMI, amis[i].id);
            console.log(ami);
            let saved   = await DataStore.save(
                AMI.copyOf(ami, updated => {
                    updated._version                                    = ami._version;
                    updated.migrationModifiedCustomerBaselineAMIId      = null;
                })
            );

            if (ami) {
                save.push(saved);
            } else {
                console.log('AMI ' + amis[i].id + ' doesnt exist???');
            }
        }

        console.log(save);

        return refreshManagePackages();
    }


    // TEMPORARY - remove after testing
    async function listAMIs()
    {
        console.log(amis);
    }


    async function buildInventory()
    {
        const buildButton   = document.getElementById('buildInventory');
        let choosePackages  = document.querySelectorAll('input[name="choosePackages[]"]');

        return await ts.buildClientInventoryComponent(migrationId, choosePackages, buildButton).then(refreshManagePackages);
    }


    function managePackages(recipes)
    {
        let customerRecipes = [];

        for (let i = 0; i < recipes.length; i++) {
            if (recipes[i].migrationCustomerInventoryId === migrationId) {
                customerRecipes.push(recipes[i]);
            }
        }

        if (customerRecipes.length <= 0 && amis.length <= 0) {
            const button = (<Button id="buildInventory" onClick={buildInventory} size="small" variation="primary">Launch</Button>);

            return (
                <View style={{width:"500px"}}>
                    {ts.packagesTableComponent(recipes, button)}
                </View>
            );
        } else {
            let amiItems = [];

            for (let j = 0; j < amis.length; j++) {
                amiItems.push(
                    <TableRow>
                        <TableCell>"{amis[j].name}"</TableCell>
                        <TableCell>{amis[j].createdAt}</TableCell>
                    </TableRow>
                );
            }

            return (
                <View>
                    <View>
                        <ComponentDivider level="5" title="Customer AMIs" />
                        <Table variation="striped" border="0">
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">
                                        Name
                                    </TableCell>
                                    <TableCell as="th">
                                        Created
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {amiItems}
                            </TableBody>
                        </Table>
                    </View>
{/*                    <View>
                        <Button size="small" onClick={(e) => {e.preventDefault();window.location.href = '/terminal-servers/' + client.clientId;}} variation="primary">Manage Terminal Servers</Button>
                    </View>*/}
                </View>
            );
        }
    }

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="deployStackBaseline" title="Create Baseline Target Account" />}>
                <View as="ol">
                    <View as="li">Log into the target workload account <b>{pod.awsAccountId}</b> using the AWS Console</View>
                    <View as="li">Navigate to Service Catalog in AWS</View>
                    <View as="li">Check whether <code>lynx-stackset-exec-role</code> has been created or not under the <b>Provisioned products</b> tab before going to next step.</View>
                    <View as="li">If <code>lynx-stackset-exec-role</code> exist under the <b>Provisioned products</b> tab, select it and click on <b>Actions</b> and then click on <b>update</b>, if not proceed to next step.</View>
                    <View as="li">Select the <b>Products</b> item under <b>Provisioning</b>  in the sidebar</View>
                    <View as="li">Select the <code>lynx-stackset-exec-role</code> product from the list</View>
                    <View as="li">Select the  <b>Launch Product</b>  button</View>
                    <View as="li">Enter a name for the deployed instance of the product</View>
                    <View as="li">Select the latest version listed for the product</View>
                    <View as="li">Provide the following input parameters:
                        <View as="ul">
                            <View as="li"><b>LynxHubAccountId</b>: <code>{process.env.REACT_APP_ENVMGR_ACCOUNT_ID}</code>  </View>
                            <View as="li"><b>BuildAccountId</b>: <code>{process.env.REACT_APP_BUILD_ACCOUNT_ID}</code>  </View>
                        </View>
                    </View>
                    <View as="li">Select the “Launch Product” button</View>
                    <View as="li">Wait for resources to be created</View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="deployStackLaunch" title="Launch Customer Stack" />}>
                <ManageProduct
                    step='launch-customer-stack'
                    credentials={credentials}
                    productName={Utils.getProductName('customer-stack')}
                    provisionedProductName={`${pod.awsAccountId}-${Utils.formatRegion(pod.awsRegion)}-${client.clientId}-lynx-customer-stack`}
                    provisioningParams={[
                        { Key: "ActiveDirectoryShort", Value: pod.domain },
                        { Key: "ActiveDirectoryUserName", Value: "jcarter" },
                        { Key: "ActiveDirectoryPassword", Value: '' },
                        { Key: "AMIAncillary", Value: stack.amiAncServ },
                        { Key: "AMIBase", Value: stack.amiTermServBase },
                        { Key: "AMIPxP", Value: stack.amiPxPServ },
                        { Key: "AMITerminalServer", Value: stack.amiTermServBase },
                        { Key: "BaseServerFleetSize", Value: stack.createBaseTermServer ? "1" : "0" },
                        { Key: "CustomerID", Value: client.clientId },
                        { Key: "CustomerBucketName", Value: Utils.getCustomerBucketName(client.clientId) },
                        { Key: "CustomerName", Value: client.clientName },
                        { Key: "CustomerRemoteCIDR", Value: listParam },
                        { Key: "CustomerTimezone", Value: Utils.covertToLongTimeZone(client.timeZone) },
                        { Key: "DNSPrimary", Value: pod.dnsIpPrimary },
                        { Key: "DNSSecondary", Value: pod.dnsIpSecondary },
                        { Key: "EbsSizeAnc", Value: 100 },
                        { Key: "Env", Value: process.env.REACT_APP_ENV },
                        { Key: "ExecutionID", Value: Utils.generateRandomId() },
                        { Key: "InstanceTypeAncServer", Value: userProps.ancInstanceType },
                        { Key: "InstanceTypeTermServer", Value: userProps.termInstanceType },
                        { Key: "InstanceTypeTermServerBase", Value: "t3.medium" },
                        { Key: "InstanceTypeTestServer", Value: userProps.testInstanceType },
                        { Key: "KeyName", Value: pod.instanceKeyPair },
                        { Key: "LoadBalancer", Value: userProps.loadBalancer },
                        { Key: "ManagementGroupId", Value: pod.securityGroupMgmt },
                        { Key: "MedFusionCIDR", Value: pod.medFusionCidr },
                        { Key: "PxPServerSize", Value: userProps.pxpInstanceType },
                        { Key: "PxPServerFleetSize", Value: stack.createPxpServer ? "1" : "0" },
                        { Key: "ServerOS", Value: Utils.trimServerVersion(client.osVersion) },
                        { Key: "SGCustAD", Value: pod.securityGroupAD },
                        { Key: "SGFileServer", Value: pod.securityGroupFile },
                        { Key: "SGManagement", Value: pod.securityGroupMgmt },
                        { Key: "SGSQLServer", Value: pod.securityGroupSql },
                        { Key: "Subnet1AZ", Value: Utils.formatRegion(stack.az1) },
                        { Key: "Subnet2AZ", Value: Utils.formatRegion(stack.az2) },
                        { Key: "Subnet1Cidr", Value: stack.az1Cidr },
                        { Key: "Subnet2Cidr", Value: stack.az2Cidr },
                        { Key: "SQLPod", Value: pod.podName },
                        { Key: "TermServerFleetSize", Value: "0" },
                        { Key: "TestServerFleetSize", Value: "0" },
                        { Key: "TransitGatewayId", Value: pod.tgwAttachmentId },
                        { Key: "VpcId", Value: pod.vpcId }
                    ]}
                    targetAccount={pod.awsAccountId}
                    targetRegion={Utils.formatRegion(pod.awsRegion)}
                />
            </TabItem>
        </Tabs>
    )
}