/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Button,
  Flex,
  Grid,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
export default function ClientServicesRequestPassword(props) {
  const { onSubmit, onValidate, onChange, overrides, ...rest } = props;
  const initialValues = {
    clientId: undefined,
    ttl: undefined,
    salesforceId: "",
    reason: "",
  };
  const [clientId, setClientId] = React.useState(initialValues.clientId);
  const [ttl, setTtl] = React.useState(initialValues.ttl);
  const [salesforceId, setSalesforceId] = React.useState(
    initialValues.salesforceId
  );
  const [reason, setReason] = React.useState(initialValues.reason);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setClientId(initialValues.clientId);
    setTtl(initialValues.ttl);
    setSalesforceId(initialValues.salesforceId);
    setReason(initialValues.reason);
    setErrors({});
  };
  const validations = {
    clientId: [{ type: "Required" }],
    ttl: [{ type: "Required" }],
    salesforceId: [{ type: "Required" }],
    reason: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        const modelFields = {
          clientId,
          ttl,
          salesforceId,
          reason,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        await onSubmit(modelFields);
      }}
      {...getOverrideProps(overrides, "ClientServicesRequestPassword")}
      {...rest}
    >
      <Autocomplete
        label="Client ID"
        descriptiveText="The Client SAP ID."
        isRequired={true}
        options={[]}
        onSelect={({ id, label }) => {
          setClientId(id);
          runValidationTasks("clientId", id);
        }}
        onClear={() => {
          setClientId("");
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId: value,
              ttl,
              salesforceId,
              reason,
            };
            const result = onChange(modelFields);
            value = result?.clientId ?? value;
          }
          if (errors.clientId?.hasError) {
            runValidationTasks("clientId", value);
          }
          setClientId(value);
        }}
        onBlur={() => runValidationTasks("clientId", clientId)}
        errorMessage={errors.clientId?.errorMessage}
        hasError={errors.clientId?.hasError}
        labelHidden={false}
        {...getOverrideProps(overrides, "clientId")}
      ></Autocomplete>
      <Autocomplete
        label="Length of Time Requested (in hours)"
        isRequired={true}
        options={[]}
        onSelect={({ id, label }) => {
          setTtl(id);
          runValidationTasks("ttl", id);
        }}
        onClear={() => {
          setTtl("");
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              ttl: value,
              salesforceId,
              reason,
            };
            const result = onChange(modelFields);
            value = result?.ttl ?? value;
          }
          if (errors.ttl?.hasError) {
            runValidationTasks("ttl", value);
          }
          setTtl(value);
        }}
        onBlur={() => runValidationTasks("ttl", ttl)}
        errorMessage={errors.ttl?.errorMessage}
        hasError={errors.ttl?.hasError}
        labelHidden={false}
        {...getOverrideProps(overrides, "ttl")}
      ></Autocomplete>
      <TextField
        label="Salesforce ID"
        descriptiveText="The WR or CR ID from Salesforce."
        isRequired={true}
        value={salesforceId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              ttl,
              salesforceId: value,
              reason,
            };
            const result = onChange(modelFields);
            value = result?.salesforceId ?? value;
          }
          if (errors.salesforceId?.hasError) {
            runValidationTasks("salesforceId", value);
          }
          setSalesforceId(value);
        }}
        onBlur={() => runValidationTasks("salesforceId", salesforceId)}
        errorMessage={errors.salesforceId?.errorMessage}
        hasError={errors.salesforceId?.hasError}
        {...getOverrideProps(overrides, "salesforceId")}
      ></TextField>
      <TextAreaField
        label="Reason"
        descriptiveText="Please explain why you need access."
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              ttl,
              salesforceId,
              reason: value,
            };
            const result = onChange(modelFields);
            value = result?.reason ?? value;
          }
          if (errors.reason?.hasError) {
            runValidationTasks("reason", value);
          }
          setReason(value);
        }}
        onBlur={() => runValidationTasks("reason", reason)}
        errorMessage={errors.reason?.errorMessage}
        hasError={errors.reason?.hasError}
        {...getOverrideProps(overrides, "reason")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
