import { ComponentDivider, Document, MigrationTaskTabTitle } from '../..'
import SyntaxHighlighter from 'react-syntax-highlighter';
import Utils from '../../../lib/utils'


import {
    Tabs,
    TabItem,
    View
} from '@aws-amplify/ui-react'

export default function MigrationStepDms({ clientId, migrationId, pod = {}, step }) {

    const sftpConnectionString = `ngcstmr${clientId}@${Utils.getSftpEndpointName(pod.awsRegion)}`
    const startWinSCPCmd = `.\\start_winscp.bat ${sftpConnectionString}`

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="setupDms" title="Setup DMS" />}>
                DMS can only be used if NextGen has provided the customer with a private VPN or Direct Connection between their on-prem environment and their new SQL Server instance in AWS.
                If a private connection is not available, un-select the "Use DMS" option in the Migration Details, and follow the standard database migration procedure using native SQL
                backup/restore. Otherwise, you can proceed with the steps below:
                <View as="ol">
                    <View as="li">Identify the subnet in <b>{pod.vpcId}</b> in account <b>{pod.awsAccountId}</b> where the customer's SQL Server instance is hosted. </View>
                    <View as="li">Share the subnet with the Environment Manager AWS account by following the guide in
                        <u><a href="https://aws.amazon.com/premiumsupport/knowledge-center/vpc-share-subnet-with-another-account/"> this link</a></u>.
                    </View>
                    <View as="li">In the Environment Manager AWS account, confirm a role named <b>dms-{Utils.formatRegion(pod.awsRegion)}-vpc-role</b> exists with the <b>AmazonDMSVPCManagementRole</b> policy attached, and
                        the trust relationship:
                        <Document assetPath='templates/json/iam-trust-relationship.json'
                            showLineNumbers={true}
                            language="json"
                            templateVars={
                                {
                                    AWS_SERVICE_ENDPOINT: `dms.${Utils.formatRegion(pod.awsRegion)}.amazonaws.com`
                                }
                            } />
                    </View>
                    <View as="li">In the same account, confirm a role named<code>dms-cloudwatch-logs-role</code> exists with the <b>AmazonDMSCloudWatchLogsRole</b> policy attached, and
                        the trust relationship:
                        <Document assetPath='templates/json/iam-trust-relationship.json'
                            showLineNumbers={true}
                            language="json"
                            templateVars={
                                {
                                    AWS_SERVICE_ENDPOINT: "dms.amazonaws.com"
                                }
                            } />
                    </View>
                    <View as="li">Still in the Environment Manager account, Navigate to the Database Migration Service in the AWS Console and click <b>Create Replication Instance</b>.</View>
                    <View as="li">In Instance configuration, select the instance class <b>dms.r5.large</b>, with the Engine Version at 3.4.7 or most recent.</View>
                    <View as="li">Under storage configuration, select at least 50gb.</View>
                    <View as="li">Under the Connectivity and Security options, select IPv4 and then select the VPC called <b>DMS-Sharing</b>, and then the Security Group <b>DMS-SG</b></View>
                </View>
            </TabItem >
            <TabItem title={<MigrationTaskTabTitle step={`${step}.2`} migrationId={migrationId} taskName="createBackupsDms" title="Create Backups" />}>
                <View as="ol">
                    <View as="li">
                        Once you have access to the customer's SQL Server instance, execute the backup script below for each customer database that you plan
                        to migrate to AWS.
                        <Document assetPath='templates/sql/backup.sql'
                            showLineNumbers={true}
                            language="sql"
                            templateVars={
                                {
                                    DB_NAME: 'nextgen',
                                    BACKUP_FOLDER: 'C:\\sql_backups'
                                }
                            } />
                    </View>
                    <View as="li">Capture the LSN for the backup somewhere you'll be able to retrieve it later. We will need this to initialize DMS Task for on-going Replication.
                        <Document assetPath='templates/sql/capture-backup-lsn.sql'
                            showLineNumbers={true}
                            language="sql" />
                    </View>


                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.3`} migrationId={migrationId} taskName="configureDms" title="Configure Customer Database for DMS" />}>
                <View as="ol">
                    <View as="li">Add DMS_User as a sysadmin on the customer database.</View>
                    <View as="li">Enable Distributor on customer's SQL Server instance: </View>
                    <Document
                        assetPath='templates/sql/create-distributor.sql'
                        showLineNumbers={true}
                        language="sql"

                    />
                </View>
            </TabItem >
            <TabItem title={<MigrationTaskTabTitle step={`${step}.4`} migrationId={migrationId} taskName="updateDatabasesTransferUpDms" title="Transfer Backups to S3" />}>
                <ComponentDivider level={6} title="Option 1 - Using the WinSCP helper script" />
                <View as="ol">
                    <View as="li">
                        To sync all backups in one step using the <code>sync.ps1</code> helper script, run the following command from the <code>nge-file-migration-utils</code> directory.
                        <SyntaxHighlighter language='powershell'>{`.\\tasks\\sync.ps1 -target "${sftpConnectionString}" -source 'C:\\sql_backups'`}</SyntaxHighlighter>
                    </View>
                </View>
                <ComponentDivider level={6} title="Option 2 - Using WinSCP GUI" />
                <View as="ol">
                    <View as="li">Execute the following command to launch WinSCP: <SyntaxHighlighter>{startWinSCPCmd}</SyntaxHighlighter></View>
                    <View as="li">Enter the credentials from Step 2.3 when prompted.</View>
                    <View as="li">Click the Syncronize button or press CTRL+S to bring up the Syncronize modal.</View>
                    <View as="li">Select the backup folder used in step 4.1 as the source directory, and start the transfer. </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.5`} migrationId={migrationId} taskName="updateDatabasesTransferDownDms" title="Transfer Backups to Destination Server" />}>
                <View as="ol">
                    <View as="li">Using SecureLink, logon to the SQL Server instance located in the customer's Pod.</View>
                    <View as="li">Copy and unzip nge-file-migration-utils.zip to the desktop if it is not already present.</View>
                    <View as="li">Create a folder called <code>{`D:\\IncomingSQLBackups\\${clientId}\\initial`}</code></View>
                    <View as="li">Launch WinSCP: <SyntaxHighlighter>{startWinSCPCmd}</SyntaxHighlighter></View>
                    <View as="li">Enter the password from Step 2.3 when prompted.</View>
                    <View as="li">Click the Syncronize button or press CTRL+S to bring up the Syncronize modal.</View>
                    <View as="li">Select <b>{`D:\\IncomingSQLBackups\\${clientId}\\initial`}</b> as the target, and begin the transfer.</View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.6`} migrationId={migrationId} taskName="updateDatabasesRestoreDms" title="Restore SQL Backups" />}>
                <View as="ol">
                    <View as="li">
                        Execute the restore script below for each backup.
                        <Document assetPath='templates/sql/restore.sql'
                                  showLineNumbers={true}
                                  language="sql"
                                  templateVars={
                                      {
                                          BACKUP_FILE: `D:\\IncomingSQLBackups\\${clientId}\\initial\\backupname.bak`,
                                          DB_NAME: 'Test',
                                          DATA_PATH: `D:\\IncomingSQLBackups\\${clientId}\\initial\\new_data`,
                                          LOG_PATH: `D:\\IncomingSQLBackups\\${clientId}\\initial\\new_log`,
                                          NEXTGEN_INDEX: 'D:\\index',
                                          NEXTGEN_LOB: 'D:\\db_lob',
                                          EXECUTE_TYPE: 'FULL+TRN',
                                          TRANSACTION_BACKUP_PATH: `D:\\IncomingSQLBackups\\${clientId}\\initial`
                                      }
                                  } />
                    </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.7`} migrationId={migrationId} taskName="migrateDatabasesPostScriptDms" title="Run Post-Migration Script" />}>
                <View as="ol">
                    <View as="li">
                        Execute the post-migration stored procedure below.
                        <Document
                            assetPath='templates/sql/post-migration.sql'
                            showLineNumbers={true}
                            language="sql"
                            templateVars={
                                {
                                    IMAGES_PATH: `\\\\fileshare\\images`,
                                    NGROOT_PATH: `\\\\fileshare\\ngroot`,
                                    BB_PATH: `\\\\fileshare\\bb`,
                                    IMAGES_DELETE_PATH: `\\\\fileshare\\imagestodelete`
                                }
                            } />
                    </View>
                </View>
            </TabItem>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.8`} migrationId={migrationId} taskName="updateDatabasesStartDms" title="Start On-Going Replication in DMS" />}>
                <View as="ol">
                    <View as="li">Go to the Database Migration console in the Environment Manager AWS account</View>
                    <View as="li">Create a "Source" endpoint using the necessary connection details for the customer's SQL Server instance. In the endpoint settings,
                        select "use endpoint connection attributes" and add the setting <code>setUpMsCdcForTables=true</code>.
                    </View>
                    <View as="li">Create a "Target" endpoint using the necessary connection details for the target SQL Server instance in AWS.</View>
                    <View as="li">Create a new DMS Task using the Source and Target endpoints just created, configured with the following settings:
                        <View as="ol">
                            <View as="li"><b>Migration type</b>: <b></b>Replicate data changes only</View>
                            <View as="li"><b>CDC start mode for source translations</b>: Enable</View>
                            <View as="li"><b>Custom CDC start point for source transactions</b>: <i></i>Select "specify a log sequence number", then enter
                                the LSN number captured during the initial backup step.
                            </View>
                            <View as="li"><b>Target table preparation mode</b>: "Do nothing"</View>
                            <View as="li"><b>CDC start mode for source translations</b>: Enable</View>
                            <View as="li"><b>Include LOB columns in replication</b>: "Full LOB mode"</View>
                            <View as="li"><b>LOB chunk size</b>: 64kb</View>
                            <View as="li"><b>Task Logs</b>: "Turn on CloudWatch logs"</View>
                            <View as="li"><b>Enable control tables</b>: {"(Enable all options)"}</View>
                            <View as="li"><b>Maximum number of tables to load in parallel</b>: 25</View>
                            <View as="li"><b>Migration task startup configuration</b>: "Manually Later"</View>
                        </View>
                    </View>
                    <View as="li">On the target AWS database, generate an alter script to prepare Blob columns for on-going replication:
                        <Document
                            assetPath='templates/sql/prep-blob-columns.sql'
                            showLineNumbers={true}
                            language="sql"
                        />
                    </View>
                    <View as="li">Execute the generated alter script in a new query window.</View>
                    <View as="li">Disable all triggers by running <SyntaxHighlighter>EXEC sp_msforeachtable "ALTER TABLE ? DISABLE TRIGGER all"</SyntaxHighlighter></View>
                    <View as="li">Finally, return to the DMS console and start the migration task to begin on-going replication.</View>
                </View>
            </TabItem>
        </Tabs >
    )
}