import {
    Button, Flex, Pagination, SearchField, Table, TableBody, TableCell,
    TableHead, TableRow, ThemeProvider, View, ExpanderItem, Expander
} from '@aws-amplify/ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ComponentDivider, LoadingWrapper } from "..";
import Modal from "react-modal";
import { nextGenTheme } from "../../themes";
import Utils from "../../lib/utils";
import { Auth, API } from "aws-amplify";
import { ClientServicesRequestPassword } from '../../ui-components';
import { invokeCSSAOrchestrator, listSupportMembers, listCSSAClients, listSupportClientRequests, listCSSADatabases, getSupportMember } from '../../graphql/queries';
import { createSupportClientRequest, createSupportMember, updateSupportClientRequest } from '../../graphql/mutations';

export default function ClientServicesSupport() {
    const [currentPage, setCurrentPage]                                 = useState(1);
    const [totalPages, setTotalPages]                                   = useState(1);
    const [isLoading, setIsLoading]                                     = useState(true);
    const [deleteModalOpen, setDeleteModalOpen]                         = useState(false);
    const [passwordModalOpen, setPasswordModalOpen]                     = useState(false);
    const [accessModalOpen, setAccessModalOpen]                         = useState(false);
    const [cssaUsername, setCSSAUsername]                               = useState("");
    const [cssaPassword, setCSSAPassword]                               = useState("");
    const [currentUser, setCurrentUser]                                 = useState({});
    const [activeClientRequests, setActiveClientRequests]               = useState([]);
    const [visibleActiveClientRequests, setVisibleActiveClientRequests] = useState([]);
    const [clientList, setClientList]                                   = useState([]);
    const [currentRequest, setCurrentRequest]                           = useState({});
    const [isloadingPassword, setIsLoadingPassword]                     = useState(false);
    const [allDatabases, setAllDatabases]                               = useState([]);

    const MAXLENGTHOFTIME   = 8
    const pageSize          = 25;

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        sessionStorage.setItem('userAttributesName', user.attributes.name);
        sessionStorage.setItem('userAttributesEmail', user.attributes.email);

        if (!cognitoGroups.includes('ClientServicesSystemAccessUsers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const formOverrides = {
        clientId: {
            options: getAllClients()
        },
        ttl: {
            options: getLengthOfTime()
        }
    }

    function getLengthOfTime() {
        var myList = []
        for (let i = 1; i <= MAXLENGTHOFTIME; i++) {
            myList.push({ 'id': i, 'label': `${i}` })
        }
        return myList
    }

    function validateSalesforceId(src) {
        var tempVal = src.toLowerCase()
        let passes = false
        if (tempVal.slice(0, 2) === 'cr') {
            const regex = new RegExp("^cr[0-9]{5}$")
            passes = regex.test(tempVal)
        }

        else if (tempVal.slice(0, 2) === 'wr') {
            const regex = new RegExp("^wr[0-9]{6}$")
            passes = regex.test(tempVal)
        }
        else if (!isNaN(tempVal.charAt(0))) {
            const regex = new RegExp("^[0-9]{8}$")
            passes = regex.test(tempVal)
        }
        if (passes === false) {
            return {
                hasError: !passes,
                errorMessage: "Provide valid support work ID"
            }
        }

        else {
            return {
                hasError: !passes
            }
        }
    }

    function resetRegion(region) {
        if (region) {
            return region.replaceAll("_", "-");
        }
    }

    function getSupportClientRequestPerClient(id_) {
        return activeClientRequests.filter(val => (val.cSSAClientSupportClientRequestsId.includes(id_)))
    }

    function getAllClients() {
        var myList = []
        for (let i = 0; i < allDatabases.length; i++) {
            let curClient = getCurClient(allDatabases[i].cSSAClientServersId)
            if (curClient) {
                myList.push({ 'id': allDatabases[i].id, 'label': `${curClient.clientId}: ${curClient.clientName} (${allDatabases[i].databaseEnv})` });
            }
            else {
                // TOOD: This is ugly, but it keeps us from throwing an error to the user
                console.error("Unable to lookup CSSA Server id %s", allDatabases[i].cSSAClientServersId);
            }
        }
        myList.sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            // names must be equal
            return 0;
        });

        return myList
    }

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const search = useCallback(async (event) => {
        const filter = event.target.value;
        API.graphql({
            query: listSupportClientRequests,
            variables: {
                filter: {
                    or: [
                        {
                            salesforceId: {
                                contains: filter.toLowerCase()
                            }
                        },
                        {
                            reason: {
                                contains: filter.toLowerCase()
                            }
                        }
                    ],
                    status: {
                        eq: 'Active'
                    }
                }
            }
        }).then(
            (items) => {
                setCurrentPage(1);
                setActiveClientRequests(items.data.listSupportClientRequests.items);
            }
        )
    }, [])

    function openDeleteModal(item) {
        setCurrentRequest(item);
        setDeleteModalOpen(true);
    }
    function deleteWorkRequestPrematurely(workRequest) {
        // Update work request when user wishes for it
        API.graphql({
            query: listCSSADatabases,
            variables: {
                filter: {
                    id: {
                        eq: workRequest.supportClientRequestDatabaseId
                    }
                }
            }
        }).then(
            (items) => {
                let databaseInfo = items.data.listCSSADatabases.items[0];
                let clientInfo = getCurClient(workRequest.cSSAClientSupportClientRequestsId);
                let payload = {
                    action: 'delete',
                    customer_id: clientInfo.clientId,
                    region: resetRegion(clientInfo.region),
                    customer_env: databaseInfo.databaseEnv,
                    server_name: databaseInfo.serverName,
                    request_id: workRequest.id,
                    customer_account_id: clientInfo.awsAccount,
                    username: currentUser.email
                }

                API.graphql({
                    query: invokeCSSAOrchestrator,
                    variables: {
                        payload
                    }
                }).then(
                    (items) => {
                        API.graphql(
                            {
                                query: updateSupportClientRequest,
                                variables: {
                                    input:
                                    {
                                        id: workRequest.id,
                                        _version: workRequest._version,
                                        status: 'Inactive',
                                        revokedMethod: 'Manually'
                                    }
                                }
                            }
                        ).then(
                            () => {
                                closeDeleteModal();
                                window.location.reload();
                            }
                        )
                    }
                ).catch((e) => {
                    let errors = '';

                    for (let i = 0; i < e.errors.length; i++) {
                        errors += e.errors[i].errorType + ' ' + e.errors[i].message + "\r\n\r\n";
                    }

                    alert(errors);

                    closeDeleteModal();
                });
            })
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false);
    }

    function openPasswordModal() {
        setPasswordModalOpen(true);
    }

    function closePasswordModal() {
        setPasswordModalOpen(false);
    }

    function openAccessModal() {
        setAccessModalOpen(true);
    }

    function closeAccessModal() {
        setAccessModalOpen(false);
        window.location.reload();
    }

    function getCurClient(id_) {
        return clientList.filter(client => (client.id.includes(id_)))[0]
    }

    function getTime(ttl) {
        let now = new Date();
        let grantedTime = new Date(now);
        let futureTime = new Date();
        futureTime.setHours(futureTime.getHours() + ttl);
        return [grantedTime, futureTime];
    }

    const convertToLocal = (date) => {
        const df = new Intl.DateTimeFormat("default", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            calendar: "iso8601",
            timeZoneName: "short",
            numberingSystem: "latn",
            hourCycle: "h12",
        });
        return df.format(date);
    };

    const createCredential = async (e) => {
        // Get Database information
        closePasswordModal();
        openAccessModal();
        setIsLoadingPassword(true)
        const databaseInfo = allDatabases.filter(database => (database.id.includes(e.clientId)))[0]
        const clientInfo = getCurClient(databaseInfo.cSSAClientServersId)

        let oldClientRequest = []
        if (activeClientRequests.length > 0) {
            // Revoke access to old password
            oldClientRequest = getSupportClientRequestPerClient(clientInfo.id);

            for (let i = 0; i < oldClientRequest.length; i++) {
                let payload = {
                    action: 'delete',
                    customer_id: clientInfo.clientId,
                    region: resetRegion(clientInfo.region),
                    server_name: databaseInfo.serverName,
                    customer_account_id: clientInfo.awsAccount,
                    username: currentUser.email,
                    request_id: oldClientRequest[i].id,
                    customer_env: databaseInfo.databaseEnv
                }

                let val = await API.graphql({
                    query: invokeCSSAOrchestrator,
                    variables: { payload }
                }).then(
                    (items) => {
                        let date = new Date();
                        API.graphql({
                            query: updateSupportClientRequest,
                            variables: {
                                input: {
                                    _version: oldClientRequest[i]._version,
                                    status: 'Inactive',
                                    id: oldClientRequest[i].id,
                                    timeRevoked: new Date(date),
                                    revokedMethod: 'Overwritten'
                                }
                            }
                        })
                    }).catch((e) => {
                        let errors = '';

                        for (let i = 0; i < e.errors.length; i++) {
                            errors += e.errors[i].errorType + ' ' + e.errors[i].message + "\r\n\r\n";
                        }

                        alert(errors);

                        closeAccessModal();
                    });
            }
        }

        let accessTime = getTime(e.ttl);
        let create_client_support = await API.graphql(
            {
                query: createSupportClientRequest,
                variables: {
                    input: {
                        salesforceId: e.salesforceId,
                        reason: e.reason,
                        timeToLive: e.ttl,
                        status: 'Active',
                        timeRevoked: accessTime[1],
                        timeGranted: accessTime[0],
                        supportMemberSupportClientRequestsId: currentUser.id,
                        cSSAClientSupportClientRequestsId: clientInfo.id,
                        supportClientRequestDatabaseId: databaseInfo.id
                    }
                }
            }
        )

        let payload = {
            action: 'create',
            customer_id: clientInfo.clientId,
            region: resetRegion(clientInfo.region),
            server_name: databaseInfo.serverName,
            customer_account_id: clientInfo.awsAccount,
            username: currentUser.email,
            ttl: e.ttl,
            customer_env: databaseInfo.databaseEnv,
            request_id: create_client_support.data.createSupportClientRequest.id
        }

        API.graphql({
            query: invokeCSSAOrchestrator,
            variables: { payload }
        }).then(
            (items) => {
                let invokeCSSAOrchestratorResponse = JSON.parse(items.data.invokeCSSAOrchestrator);

                if (invokeCSSAOrchestratorResponse.statusCode !== 200) {
                    setIsLoadingPassword(false)
                    alert(invokeCSSAOrchestratorResponse.message)
                }
                else {
                    setCSSAUsername(invokeCSSAOrchestratorResponse.username);
                    setCSSAPassword(invokeCSSAOrchestratorResponse.password);
                    setIsLoadingPassword(false)
                }
            }
        )
    }

    function clear() {
        API.graphql({
            query: listSupportClientRequests,
            variables: {
                filter: {
                    and: [{
                        supportMemberSupportClientRequestsId: {
                            eq: currentUser.id
                        }
                    },
                    {
                        status: {
                            eq: "Active"
                        }
                    }
                    ]
                }
            }
        }).then(
            (items) => {
                setActiveClientRequests(items.data.listSupportClientRequests.items)
                setIsLoading(false);
                setTotalPages(Utils.getPageCount(pageSize, items.data.listSupportClientRequests.items.length))
            })
    }

    useEffect(() => {
        let curUserName = sessionStorage.getItem('userAttributesName');
        let curUserEmail = sessionStorage.getItem('userAttributesEmail');
        let supportId = {}
        API.graphql({
            query: listSupportMembers,
            variables: {
                filter: {
                    name: {
                        eq: curUserName
                    }
                }
            }
        }).then(
            (items) => {
                if (items.data.listSupportMembers.items.length === 0) {
                    API.graphql({
                        query: createSupportMember,
                        variables: {
                            input: {
                                name: curUserName,
                                email: curUserEmail
                            }
                        }
                    }).then(
                        (items) => {
                            let tempSupport = items.data.createSupportMember.items.id
                            API.graphql({
                                query: getSupportMember,
                                variables: {
                                    input: {
                                        id: tempSupport
                                    }
                                }
                            }).then(
                                (item) => {
                                    supportId = item.data.getSupportMember
                                }
                            )
                        }
                    )
                }
                else {
                    supportId = items.data.listSupportMembers.items[0]
                }

                setCurrentUser(supportId)
                API.graphql({

                    query: listSupportClientRequests,
                    variables: {
                        filter: {
                            and: [{
                                supportMemberSupportClientRequestsId: {
                                    eq: supportId.id
                                }
                            },
                            {
                                status: {
                                    eq: "Active"
                                }
                            },
                            {
                                _deleted: {
                                    ne: true
                                }
                            }
                            ]
                        }
                    }
                }).then(
                    (items) => {
                        setActiveClientRequests(items.data.listSupportClientRequests.items)
                        setIsLoading(false);
                        setTotalPages(Utils.getPageCount(pageSize, items.data.listSupportClientRequests.items.length))
                    })
            }
        )

        API.graphql({
            query: listCSSAClients,
        }).then(
            (items) => {
                setClientList(items.data.listCSSAClients.items);
                API.graphql({
                    query: listCSSADatabases,
                    variables: {
                        filter: {
                            _deleted: {
                                ne: true
                            }
                        }
                    }
                }).then(
                    (items) => {
                        setAllDatabases(items.data.listCSSADatabases.items);
                    }
                )
            }
        )
    }, []);

    useEffect(() => {
        setTotalPages(Utils.getPageCount(pageSize, activeClientRequests.length));
        setVisibleActiveClientRequests(Utils.getPageItems(activeClientRequests, pageSize, currentPage));
    }, [activeClientRequests, currentPage]);

    return (
        <View>
            <Modal shouldCloseOnOverlayClick={false} style={customStyles} appElement={document.getElementById('app')} isOpen={deleteModalOpen} onRequestClose={closeDeleteModal}>
                <ThemeProvider theme={nextGenTheme} >
                    {Utils.modalCloseButton(closeDeleteModal)}
                    <ComponentDivider level={4} title="Disable Credentials" />
                    <div>
                        <p>
                            Are you sure you want to disable the credentials for work request <strong>{currentRequest.salesforceId}</strong>?
                        </p>
                    </div>
                    <Button size="small" variation="primary" onClick={(e) => { deleteWorkRequestPrematurely(currentRequest); e.target.classList.remove('amplify-button--primary'); e.target.innerHTML = "Please Wait"; e.target.disabled = true; }}>Yes</Button>&#160;
                </ThemeProvider>
            </Modal>
            <Modal shouldCloseOnOverlayClick={false} style={customStyles} appElement={document.getElementById('app')} isOpen={passwordModalOpen} onRequestClose={closePasswordModal}>
                <ThemeProvider theme={nextGenTheme} >
                    {Utils.modalCloseButton(closePasswordModal)}
                    <ComponentDivider level={4} title="Generate Temporary New Password" />
                    <ClientServicesRequestPassword
                        overrides={formOverrides}
                        onValidate={{ salesforceId: validateSalesforceId }}
                        onSubmit={(e) => createCredential(e)}
                    />
                    <br /><br />
                </ThemeProvider>
            </Modal>

            <Modal shouldCloseOnOverlayClick={false} style={customStyles} appElement={document.getElementById('app')} isOpen={accessModalOpen} onRequestClose={closeAccessModal}>
                <ThemeProvider theme={nextGenTheme} >
                    {Utils.modalCloseButton(closeAccessModal)}
                    <ComponentDivider level={4} title="Temporary Credentials" />
                    <LoadingWrapper isLoading={isloadingPassword}>
                        <p>
                            Username: {cssaUsername}
                        </p>
                        <div>
                            <input id="password-generator" style={{ width: "500px" }} type="text" disabled="disabled" value={cssaPassword} />
                        </div>
                        <Button size="small" variation="primary" onClick={() => { Utils.copyToClipboard('password-generator'); alert('Your newly generated one-time password has been copied to your clipboard.'); }}>Copy</Button>&#160;
                        <Button size="small" onClick={() => closeAccessModal()}>Close</Button>
                    </LoadingWrapper>
                </ThemeProvider>
            </Modal>
            <LoadingWrapper isLoading={isLoading}>
                <Flex width="60rem" direction="column">
                    <ComponentDivider title={`Welcome, ${currentUser.name}`} />
                    <View style={{ textAlign: "center", width: "60rem" }}>
                        <Button variation="primary" style={{ width: "80%", marginBottom: "2em" }} size="small" onClick={(e) => { openPasswordModal() }}>Generate Temporary New Password</Button>
                    </View>
                    <ComponentDivider title="All Credentials" level="4" />
                    <Table border="0">
                        <TableBody>
                            <TableRow>
                                <TableCell width="80%" border="0">
                                    <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Table highlightOnHover={true} variation="striped" className="sortable">
                        <TableHead>
                            <TableCell as="th">
                                Salesforce ID
                            </TableCell>
                            <TableCell as="th">
                                SAP ID
                            </TableCell>
                            <TableCell as="th">
                                Client Name
                            </TableCell>
                            <TableCell as="th">
                                Access Granted
                            </TableCell>
                            <TableCell as="th">
                                Access Expires
                            </TableCell>
                            <TableCell as="th">
                                Reason
                            </TableCell>
                            <TableCell as="th">

                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                visibleActiveClientRequests.length === 0 ? <TableRow><TableCell colSpan="7" style={{textAlign: "center"}}>(No Results)</TableCell></TableRow> : visibleActiveClientRequests.map(cl => (
                                    <TableRow key={cl.clientId} style={{ cursor: 'pointer' }}>
                                        <TableCell>{cl.salesforceId}</TableCell>
                                        <TableCell>{(getCurClient(cl.cSSAClientSupportClientRequestsId)).clientId}</TableCell>
                                        <TableCell>{(getCurClient(cl.cSSAClientSupportClientRequestsId)).clientName}</TableCell>
                                        <TableCell>{convertToLocal(new Date(cl.timeGranted))}</TableCell>
                                        <TableCell>{convertToLocal(new Date(cl.timeRevoked))}</TableCell>
                                        <TableCell>{cl.reason}</TableCell>
                                        <TableCell><Button size="small" variation="error" onClick={(e) => { openDeleteModal(cl) }}>Disable</Button></TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Flex>
            </LoadingWrapper>
        </View>
    );
}
