import { ComponentDivider } from '..'
import { Flex, View } from '@aws-amplify/ui-react'
import { MigrationTaskCreateForm, MigrationTaskUpdateForm } from '../../ui-components'
import { useCallback, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import Utils from '../../lib/utils'

export default function MigrationTaskCreateUpdate({ onCloseModal, migrationId, migrationTask, name, step, title, }) {

    const [currentUser, setCurrentUser] = useState("")

    useEffect(() => {
        // Get the current user's name and e-mail from the session when the component is loaded.
        Auth.currentUserInfo().then((info) => {
            if (info.attributes) {
                setCurrentUser(`${info.attributes.name} <${info.attributes.email}>`)
            }
        })

    }, [])

    const closeModal = () => {
        if (typeof onCloseModal === 'function') {
            return onCloseModal()
        }
        return null
    }

    function onCancel() {
        closeModal()
    }

    function onSuccess() {
        closeModal()
    }

    const onMigrationTaskCreate = useCallback((fields) => {
        fields.migrationTasksId = migrationId
        fields.title = title
        fields.name = name
        fields.updatedBy = currentUser
        return fields
    }, [migrationId, name, currentUser, title])



    const formOverrides = {
        // Hide fields we don't care about for the UI. We still want to store
        // these in the database in case we want to run queries on them later.
        name: { display: "none" },
        title: { display: "none" },
        updatedBy: {
            label: "Last Update",
            disabled: "disabled",
            // Don't show the updated by field if no migrationTask exists yet in the database.
            display: migrationTask ? "" : "none",
            value: migrationTask ? `${migrationTask.updatedBy}, ${Utils.timestampToString(migrationTask._lastChangedAt)}` : ""
        },
        comment: {
            type: "textAreaField"
        }
    }
    function renderForm() {
        if (migrationTask) {
            return (
                <View>
                    <ComponentDivider title={`${step} - ${title}`} />
                    <MigrationTaskUpdateForm
                        onSubmit={onMigrationTaskCreate}
                        onCancel={onCancel}
                        migrationTask={migrationTask}
                        onSuccess={onSuccess}
                        overrides={formOverrides} />
                </View>
            )
        }
        else {
            return (
                <View direction="column" width="33vw" >
                    <ComponentDivider title={`${step} - ${title}`} />
                    <MigrationTaskCreateForm
                        onSubmit={onMigrationTaskCreate}
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        overrides={formOverrides} />
                </View>)
        }
    }

    return (
        <Flex direction="column" width="33vw" >
            {renderForm()}
            <br />
        </Flex>
    )
}

