/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { Migration } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function MigrationCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    dataMigrationRegion: "",
    migrationName: "",
    scheduledStart: "",
    scheduledCutover: "",
    status: "",
    useDms: false,
  };
  const [dataMigrationRegion, setDataMigrationRegion] = React.useState(
    initialValues.dataMigrationRegion
  );
  const [migrationName, setMigrationName] = React.useState(
    initialValues.migrationName
  );
  const [scheduledStart, setScheduledStart] = React.useState(
    initialValues.scheduledStart
  );
  const [scheduledCutover, setScheduledCutover] = React.useState(
    initialValues.scheduledCutover
  );
  const [status, setStatus] = React.useState(initialValues.status);
  const [useDms, setUseDms] = React.useState(initialValues.useDms);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDataMigrationRegion(initialValues.dataMigrationRegion);
    setMigrationName(initialValues.migrationName);
    setScheduledStart(initialValues.scheduledStart);
    setScheduledCutover(initialValues.scheduledCutover);
    setStatus(initialValues.status);
    setUseDms(initialValues.useDms);
    setErrors({});
  };
  const validations = {
    dataMigrationRegion: [],
    migrationName: [],
    scheduledStart: [],
    scheduledCutover: [],
    status: [],
    useDms: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          dataMigrationRegion,
          migrationName,
          scheduledStart,
          scheduledCutover,
          status,
          useDms,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Migration(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "MigrationCreateForm")}
      {...rest}
    >
      <SelectField
        label="Data migration region"
        placeholder="Please select an option"
        isDisabled={false}
        value={dataMigrationRegion}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion: value,
              migrationName,
              scheduledStart,
              scheduledCutover,
              status,
              useDms,
            };
            const result = onChange(modelFields);
            value = result?.dataMigrationRegion ?? value;
          }
          if (errors.dataMigrationRegion?.hasError) {
            runValidationTasks("dataMigrationRegion", value);
          }
          setDataMigrationRegion(value);
        }}
        onBlur={() =>
          runValidationTasks("dataMigrationRegion", dataMigrationRegion)
        }
        errorMessage={errors.dataMigrationRegion?.errorMessage}
        hasError={errors.dataMigrationRegion?.hasError}
        {...getOverrideProps(overrides, "dataMigrationRegion")}
      >
        <option
          children="Us east 1"
          value="us_east_1"
          {...getOverrideProps(overrides, "dataMigrationRegionoption0")}
        ></option>
        <option
          children="Us east 2"
          value="us_east_2"
          {...getOverrideProps(overrides, "dataMigrationRegionoption1")}
        ></option>
        <option
          children="Us west 2"
          value="us_west_2"
          {...getOverrideProps(overrides, "dataMigrationRegionoption2")}
        ></option>
      </SelectField>
      <TextField
        label="Migration name"
        isRequired={false}
        isReadOnly={false}
        value={migrationName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion,
              migrationName: value,
              scheduledStart,
              scheduledCutover,
              status,
              useDms,
            };
            const result = onChange(modelFields);
            value = result?.migrationName ?? value;
          }
          if (errors.migrationName?.hasError) {
            runValidationTasks("migrationName", value);
          }
          setMigrationName(value);
        }}
        onBlur={() => runValidationTasks("migrationName", migrationName)}
        errorMessage={errors.migrationName?.errorMessage}
        hasError={errors.migrationName?.hasError}
        {...getOverrideProps(overrides, "migrationName")}
      ></TextField>
      <TextField
        label="Scheduled start"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={scheduledStart}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion,
              migrationName,
              scheduledStart: value,
              scheduledCutover,
              status,
              useDms,
            };
            const result = onChange(modelFields);
            value = result?.scheduledStart ?? value;
          }
          if (errors.scheduledStart?.hasError) {
            runValidationTasks("scheduledStart", value);
          }
          setScheduledStart(value);
        }}
        onBlur={() => runValidationTasks("scheduledStart", scheduledStart)}
        errorMessage={errors.scheduledStart?.errorMessage}
        hasError={errors.scheduledStart?.hasError}
        {...getOverrideProps(overrides, "scheduledStart")}
      ></TextField>
      <TextField
        label="Scheduled cutover"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={scheduledCutover}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion,
              migrationName,
              scheduledStart,
              scheduledCutover: value,
              status,
              useDms,
            };
            const result = onChange(modelFields);
            value = result?.scheduledCutover ?? value;
          }
          if (errors.scheduledCutover?.hasError) {
            runValidationTasks("scheduledCutover", value);
          }
          setScheduledCutover(value);
        }}
        onBlur={() => runValidationTasks("scheduledCutover", scheduledCutover)}
        errorMessage={errors.scheduledCutover?.errorMessage}
        hasError={errors.scheduledCutover?.hasError}
        {...getOverrideProps(overrides, "scheduledCutover")}
      ></TextField>
      <SelectField
        label="Status"
        placeholder="Please select an option"
        isDisabled={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion,
              migrationName,
              scheduledStart,
              scheduledCutover,
              status: value,
              useDms,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      >
        <option
          children="Not started"
          value="NotStarted"
          {...getOverrideProps(overrides, "statusoption0")}
        ></option>
        <option
          children="In progress"
          value="InProgress"
          {...getOverrideProps(overrides, "statusoption1")}
        ></option>
        <option
          children="On hold"
          value="OnHold"
          {...getOverrideProps(overrides, "statusoption2")}
        ></option>
        <option
          children="Blocked"
          value="Blocked"
          {...getOverrideProps(overrides, "statusoption3")}
        ></option>
        <option
          children="Complete"
          value="Complete"
          {...getOverrideProps(overrides, "statusoption4")}
        ></option>
        <option
          children="Not applicable"
          value="NotApplicable"
          {...getOverrideProps(overrides, "statusoption5")}
        ></option>
      </SelectField>
      <SwitchField
        label="Use dms"
        defaultChecked={false}
        isDisabled={false}
        isChecked={useDms}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              dataMigrationRegion,
              migrationName,
              scheduledStart,
              scheduledCutover,
              status,
              useDms: value,
            };
            const result = onChange(modelFields);
            value = result?.useDms ?? value;
          }
          if (errors.useDms?.hasError) {
            runValidationTasks("useDms", value);
          }
          setUseDms(value);
        }}
        onBlur={() => runValidationTasks("useDms", useDms)}
        errorMessage={errors.useDms?.errorMessage}
        hasError={errors.useDms?.hasError}
        {...getOverrideProps(overrides, "useDms")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
