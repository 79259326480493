import { ComponentDivider } from '..'
import { Flex, View } from '@aws-amplify/ui-react'
import { PodCreateForm, PodUpdateForm } from '../../ui-components'
import Utils from '../../lib/utils'


export default function PodCreateUpdate({ pod, onCloseModal }) {

    const formOverrides =
    {
        PodUpdateForm: {
            maxHeight: "700px",
        },
        PodCreateForm: {
            maxHeight: "700px",
        },
        ClearButton: {
            marginBottom: "30px"
        },
        ResetButton: {
            marginBottom: "30px"
        },
        SubmitButton: {
            marginBottom: "30px"
        },
        ...Utils.createAzOptions('fileServerAz'),
        ...Utils.createAzOptions('sqlAz'),
        ...Utils.createAzOptions('sqlReportAz'),
        ...Utils.createPodSubIdOptions('podSubId'),
        ...Utils.createTzOptions('timeZone'),
        awsAccountId: { label: "Account ID", placeholder: "000000000000" },
        awsRegion: { label: "AWS Region" },
        awsRegionoption0: { children: "us-east-1" },
        awsRegionoption1: { children: "us-east-2" },
        awsRegionoption2: { children: "us-west-2" },
        domain: { label: "Domain" },
        domainUser: { label: "Domain User", value: "jcarter" },
        dnsIpPrimary: { label: "Primary DNS IP", placeholder: "127.0.0.1" },
        dnsIpSecondary: { label: "Secondary DNS IP", placeholder: "127.0.0.1" },
        fileServerAddress: { label: "File Server IP Address", placeholder: "127.0.0.1" },
        fileServerAz: { label: "File Server AZ", },
        fileServerName: { label: "File Server Name", placeholder: "[default]" },
        instanceKeyPair: { label: "EC2 Key Pair", placeholder: "keypair" },
        medFusionCidr: { label: "Medfusion CIDR", placeholder: "127.0.0.1" },
        podName: { label: "Pod Name" },
        podSubId: { label: "PodSubId" },
        securityGroupAD: { label: "Security Group - Active Directory", placeholder: "sg-0a143xxxx45c9fae" },
        securityGroupFile: { label: "Security Group - File Server", placeholder: "sg-0a143xxxx45c9fae" },
        securityGroupMgmt: { label: "Security Group - Management", placeholder: "sg-0a143xxxx45c9fae" },
        securityGroupSql: { label: "Security Group - SQL", placeholder: "sg-0a143xxxx45c9fae" },
        sqlAddress: { label: "Prod SQL IP Address", placeholder: "127.0.0.1" },
        sqlAz: { label: "Prod SQL Availability Zone" },
        sqlName: { label: "Prod SQL Instance Name", placeholder: "[default]" },
        sqlReportAddress: { label: "Report SQL IP Address", placeholder: "127.0.0.1" },
        sqlReportAz: { label: "Report SQL Availability Zone" },
        sqlReportName: { label: "Report SQL Instance Name", placeholder: "[default]" },
        tgwAttachmentId: { label: "Transit Gateway ID", placeholder: "tgw-01f8100bc7EXAMPLE" },
        timeZone: { label: "Time Zone" },
        vpcCidr: { label: "VPC CIDR", placeholder: "127.0.0.0/8" },
        vpcId: { label: "VPC ID", placeholder: "vpc-01234567890abcdef" },
    }

    function renderForm() {
        if (pod) {
            return (
                <View>
                    <ComponentDivider title={`Edit Pod - ${pod.podName}`} />
                    <PodUpdateForm
                        pod={pod}
                        onCancel={onCloseModal}
                        onSuccess={onCloseModal}
                        overrides={formOverrides} />
                </View>
            )
        }
        return (
            <View marginBottom="30px">
                <ComponentDivider title="Create New Pod" />
                <PodCreateForm
                    onCancel={onCloseModal}
                    onSuccess={onCloseModal}
                    overrides={formOverrides} />
            </View>
        )
    }

    return (
        <Flex direction="column" width="33vw" >
            {Utils.modalCloseButton(onCloseModal)}
            {renderForm()}
            <br />
        </Flex>
    )
}
