
import { Badge, ThemeProvider, View } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { MigrationTask, Status, } from '../../models';
import { MigrationTaskCreateUpdate } from '../'
import { nextGenTheme } from '../../themes';
import { useState, useEffect } from 'react';
import { DataStore } from "aws-amplify";
import Utils from '../../lib/utils'



export default function MigrationTaskTabTitle({ step, taskName, title, migrationId }) {
    const [migrationTaskModalOpen, setMigrationTaskModalOpen] = useState(false)
    const [migrationTask, setMigrationTask] = useState({})

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function openMigrationTaskModal() {
        setMigrationTaskModalOpen(true);
    }
    function closeMigrationTaskModal() {
        setMigrationTaskModalOpen(false);
    }

    useEffect(() => {
        // Get the migration task model that matches the provided migration ID and task name.
        const migrationTasksSub = DataStore.observeQuery(MigrationTask,
            m => m.and(m => [
                m.migration.id.eq(migrationId),
                m.name.eq(taskName)
            ]))
            .subscribe(({ items }) => {
                setMigrationTask(items[0])
            });

        return () => {
            migrationTasksSub.unsubscribe();
        }

    }, [migrationId, taskName])

    function getBadgeVariation() {

        const status = migrationTask && migrationTask.status
        switch (status) {
            case Status.BLOCKED:
                return "error"
            case Status.IN_PROGRESS:
                return "info"
            case Status.ON_HOLD:
                return "warning"
            case Status.COMPLETE:
                return "success"
            default: return "default"
        }
    }

    function getToolTipText() {
        if (migrationTask) {
            return (
                <span className="tooltiptext">
                    Status: {migrationTask.status}<br />
                    Updated By: {migrationTask.updatedBy} <br />
                    Updated Time: {Utils.timestampToString(migrationTask._lastChangedAt)} <br />
                    {migrationTask.comment &&
                        `Comment: ${migrationTask.comment}`
                    }
                </span>
            )


        }
        else {
            return (
                <span className="tooltiptext">Status: Not Started</span>
            )
        }
    }

    return (
        <View>
            <Badge border={"1px solid black"} onClick={openMigrationTaskModal} size="small" variation={getBadgeVariation()}>
                <div style={{ cursor: "pointer" }} className="tooltip"> {step}
                    {getToolTipText()}
                </div>

            </Badge>
            <br />
            {title}
            <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={migrationTaskModalOpen} onRequestClose={closeMigrationTaskModal}>
                <ThemeProvider theme={nextGenTheme} >
                    <MigrationTaskCreateUpdate name={taskName} title={title} migrationId={migrationId} migrationTask={migrationTask} step={step} onCloseModal={closeMigrationTaskModal} />
                </ThemeProvider>
            </Modal>
        </View>
    )
}
