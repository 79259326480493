import { Pod } from '../../models';
import { ComponentDivider, PodCreateUpdate } from '..'
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Flex,
    Pagination,
    SearchField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    View,
    ThemeProvider
} from '@aws-amplify/ui-react'
import Modal from 'react-modal'
import { nextGenTheme } from '../../themes';
import Utils from '../../lib/utils'


import { useEffect, useState } from 'react';
import {Auth} from "aws-amplify";


export default function PodList() {
    const [createPodModalOpen, setCreatePodModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pods, setPods] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [visiblePods, setVisiblePods] = useState([])

    const navigate = useNavigate();

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups     = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const pageSize = 25;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    function openCreatePodModal() {
        setCreatePodModalOpen(true);
    }
    function closeCreatePodModal() {
        setCreatePodModalOpen(false);
    }
    const search = async (event) => {
        const filter = event.target.value.toLowerCase();
        const allPods = await DataStore.query(Pod);
        const pods = allPods.filter(pod =>
            (pod.podName && pod.podName.toLowerCase().includes(filter)) ||
            (pod.awsRegion && pod.awsRegion.toLowerCase().includes(filter)) ||
            (pod.awsAccountId && pod.awsAccountId.includes(filter))
        );
        setCurrentPage(1);
        setPods(pods);
    }




    const clear = async () => {
        const pods = await DataStore.query(Pod)
        setPods(pods);
    }

    useEffect(() => {
        const podsSub = DataStore.observeQuery(Pod,
            Predicates.ALL, {
            sort: (p) => p.podName(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            setPods(items)
        });
        return () => {
            podsSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        // This useEffect function will force the view to re-render, setting the
        // total page count and the visible list of migrations every time the
        // migration list is updated, or the current page is changed.
        setTotalPages(Utils.getPageCount(pageSize, pods.length))
        setVisiblePods(Utils.getPageItems(pods, pageSize, currentPage))

    }, [pods, currentPage])

    return (
        <Flex width="60rem" direction="column">
            <ComponentDivider title="All Pods" />

            <Flex direction="row" justifyContent="space-between">
                <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} ></SearchField>
                <Button onClick={openCreatePodModal} size="small" variation="primary">
                    Create Pod</Button>
            </Flex>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                onChange={handleOnChange}
            />
            <Table highlightOnHover={true} variation="striped">
                <TableHead>
                    <TableRow >
                        <TableCell as="th" >PodName</TableCell>
                        <TableCell as="th">Account ID</TableCell>
                        <TableCell as="th">Region</TableCell>
                        <TableCell as="th">VPC CIDR</TableCell>
                        <TableCell as="th">Multi-Timezone?</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visiblePods.map(pod =>
                            <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/pod/${pod.id}`)} key={pod.id}>
                                <TableCell>{pod.podName}</TableCell>
                                <TableCell>{pod.awsAccountId}</TableCell>
                                <TableCell>{Utils.formatRegion(pod.awsRegion)}</TableCell>
                                <TableCell>{pod.vpcCidr}</TableCell>
                                <TableCell>{pod.multiTimezone ? "Yes" : "No"}</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
            <View>
                <Modal shouldCloseOnOverlayClick={false} style={customStyles} maxHeight="500px" width="800px" appElement={document.getElementById('app')} isOpen={createPodModalOpen} onRequestClose={closeCreatePodModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <PodCreateUpdate onCloseModal={closeCreatePodModal} />
                    </ThemeProvider>
                </Modal>
            </View>
        </Flex >
    );
}
