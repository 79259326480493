/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Button,
  Flex,
  Grid,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
export default function ClientServicesCreateClient(props) {
  const { onSubmit, onValidate, onChange, overrides, ...rest } = props;
  const initialValues = {
    clientId: "",
    clientName: "",
    accountId: "",
    region: undefined,
    serverName: "",
    databaseEnvironment: "",
  };
  const [clientId, setClientId] = React.useState(initialValues.clientId);
  const [clientName, setClientName] = React.useState(initialValues.clientName);
  const [accountId, setAccountId] = React.useState(initialValues.accountId);
  const [region, setRegion] = React.useState(initialValues.region);
  const [serverName, setServerName] = React.useState(initialValues.serverName);
  const [databaseEnvironment, setDatabaseEnvironment] = React.useState(
    initialValues.databaseEnvironment
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setClientId(initialValues.clientId);
    setClientName(initialValues.clientName);
    setAccountId(initialValues.accountId);
    setRegion(initialValues.region);
    setServerName(initialValues.serverName);
    setDatabaseEnvironment(initialValues.databaseEnvironment);
    setErrors({});
  };
  const validations = {
    clientId: [{ type: "Required" }],
    clientName: [{ type: "Required" }],
    accountId: [{ type: "Required" }],
    region: [{ type: "Required" }],
    serverName: [],
    databaseEnvironment: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        const modelFields = {
          clientId,
          clientName,
          accountId,
          region,
          serverName,
          databaseEnvironment,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        await onSubmit(modelFields);
      }}
      {...getOverrideProps(overrides, "ClientServicesCreateClient")}
      {...rest}
    >
      <TextField
        label="Client ID"
        descriptiveText="SAP ID"
        isRequired={true}
        value={clientId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId: value,
              clientName,
              accountId,
              region,
              serverName,
              databaseEnvironment,
            };
            const result = onChange(modelFields);
            value = result?.clientId ?? value;
          }
          if (errors.clientId?.hasError) {
            runValidationTasks("clientId", value);
          }
          setClientId(value);
        }}
        onBlur={() => runValidationTasks("clientId", clientId)}
        errorMessage={errors.clientId?.errorMessage}
        hasError={errors.clientId?.hasError}
        {...getOverrideProps(overrides, "clientId")}
      ></TextField>
      <TextField
        label="Client Name"
        isRequired={true}
        value={clientName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName: value,
              accountId,
              region,
              serverName,
              databaseEnvironment,
            };
            const result = onChange(modelFields);
            value = result?.clientName ?? value;
          }
          if (errors.clientName?.hasError) {
            runValidationTasks("clientName", value);
          }
          setClientName(value);
        }}
        onBlur={() => runValidationTasks("clientName", clientName)}
        errorMessage={errors.clientName?.errorMessage}
        hasError={errors.clientName?.hasError}
        {...getOverrideProps(overrides, "clientName")}
      ></TextField>
      <TextField
        label="AWS Account ID"
        isRequired={true}
        value={accountId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              accountId: value,
              region,
              serverName,
              databaseEnvironment,
            };
            const result = onChange(modelFields);
            value = result?.accountId ?? value;
          }
          if (errors.accountId?.hasError) {
            runValidationTasks("accountId", value);
          }
          setAccountId(value);
        }}
        onBlur={() => runValidationTasks("accountId", accountId)}
        errorMessage={errors.accountId?.errorMessage}
        hasError={errors.accountId?.hasError}
        {...getOverrideProps(overrides, "accountId")}
      ></TextField>
      <Autocomplete
        label="AWS Region"
        isRequired={true}
        options={[]}
        onSelect={({ id, label }) => {
          setRegion(id);
          runValidationTasks("region", id);
        }}
        onClear={() => {
          setRegion("");
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              accountId,
              region: value,
              serverName,
              databaseEnvironment,
            };
            const result = onChange(modelFields);
            value = result?.region ?? value;
          }
          if (errors.region?.hasError) {
            runValidationTasks("region", value);
          }
          setRegion(value);
        }}
        onBlur={() => runValidationTasks("region", region)}
        errorMessage={errors.region?.errorMessage}
        hasError={errors.region?.hasError}
        labelHidden={false}
        {...getOverrideProps(overrides, "region")}
      ></Autocomplete>
      <TextField
        label="Server Name"
        value={serverName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              accountId,
              region,
              serverName: value,
              databaseEnvironment,
            };
            const result = onChange(modelFields);
            value = result?.serverName ?? value;
          }
          if (errors.serverName?.hasError) {
            runValidationTasks("serverName", value);
          }
          setServerName(value);
        }}
        onBlur={() => runValidationTasks("serverName", serverName)}
        errorMessage={errors.serverName?.errorMessage}
        hasError={errors.serverName?.hasError}
        {...getOverrideProps(overrides, "serverName")}
      ></TextField>
      <TextField
        label="Database Environment"
        value={databaseEnvironment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              clientId,
              clientName,
              accountId,
              region,
              serverName,
              databaseEnvironment: value,
            };
            const result = onChange(modelFields);
            value = result?.databaseEnvironment ?? value;
          }
          if (errors.databaseEnvironment?.hasError) {
            runValidationTasks("databaseEnvironment", value);
          }
          setDatabaseEnvironment(value);
        }}
        onBlur={() =>
          runValidationTasks("databaseEnvironment", databaseEnvironment)
        }
        errorMessage={errors.databaseEnvironment?.errorMessage}
        hasError={errors.databaseEnvironment?.hasError}
        {...getOverrideProps(overrides, "databaseEnvironment")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
