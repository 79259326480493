/* 
* This file has been modified and converted to TypeScript from the original source:
* https://github.com/amazon-archives/aws-service-catalog-react-components/blob/master/src/ServiceCatalog/index.js 
*
*/

import { v4 as uuidv4 } from 'uuid';
import ServiceCatalog from "aws-sdk/clients/servicecatalog";
import CloudFormation from "aws-sdk/clients/cloudformation";
import Utils from './utils';

class ServiceCatalogWrapper {
  static async getServiceCatalogClient(credentials, region) {
    let awsRegion = !region ? "us-east-2" : region;
    return new ServiceCatalog({
      region: awsRegion,
      credentials,
    });
  };

  static async getCloudFormationClient(credentials, region) {
    let awsRegion = !region ? "us-east-2" : region;
    return new CloudFormation({
      region: awsRegion,
      credentials,
    });
  };


  static async searchProducts(credentials, region, filter) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    const products = [];
    const options = filter ? { Filters: { FullTextSearch: [filter] } } : {}
    const searchResult = await serviceCatalog.searchProducts(options).promise();

    if (searchResult.ProductViewSummaries) {
      for (const product of searchResult.ProductViewSummaries) {

        if (product.ProductId) {
          const productDetailsPromise = serviceCatalog.describeProduct({ Id: product.ProductId }).promise();
          const productLaunchPathsPromise = serviceCatalog.listLaunchPaths({ ProductId: product.ProductId }).promise();

          const response = await Promise.all([productDetailsPromise, productLaunchPathsPromise]);
          const productDetails = response[0];
          const productLaunchPaths = response[1];

          products.push({
            product: productDetails.ProductViewSummary,
            artifacts: productDetails.ProvisioningArtifacts,
            launchPaths: productLaunchPaths.LaunchPathSummaries,
          });
        }
      }
    }
    return products;
  };

  static async describeProvisioningParameters(productId, artifactId, launchPathId, credentials, region) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    const response = await serviceCatalog.describeProvisioningParameters({
      ProductId: productId,
      ProvisioningArtifactId: artifactId,
      PathId: launchPathId,
    }).promise();
    return response.ProvisioningArtifactParameters;
  };

  static async provisionProduct(provisionRequest, credentials, region) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    return await serviceCatalog.provisionProduct({
      ProductId: provisionRequest.ProductId,
      ProvisionToken: uuidv4(),
      ProvisionedProductName: provisionRequest.ResourceName,
      ProvisioningArtifactId: provisionRequest.ProvisioningArtifactId,
      PathId: provisionRequest.PathId,
      ProvisioningParameters: provisionRequest.ProvisioningParams,
      ProvisioningPreferences: provisionRequest.ProvisioningPreferences
    }).promise();
  };

  static async updateProvisionedProduct(provisionRequest, credentials, region) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    return await serviceCatalog.updateProvisionedProduct({
      ProductId: provisionRequest.ProductId,
      ProvisionedProductName: provisionRequest.ResourceName,
      ProvisioningArtifactId: provisionRequest.ProvisioningArtifactId,
      PathId: provisionRequest.PathId,
      ProvisioningParameters: provisionRequest.ProvisioningParams,
      ProvisioningPreferences: provisionRequest.ProvisioningPreferences
    }).promise();
  };

  static async searchProvisionedProducts(credentials, region, filter) {
    const options = filter ? { Filters: { SearchQuery: [filter] } } : {}

    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    const response = await serviceCatalog.searchProvisionedProducts(options).promise();
    return response.ProvisionedProducts;
  };

  static async terminateProvisionedProduct(provisionedProductName, idempotencyToken, credentials, region,) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);
    const response = await serviceCatalog.terminateProvisionedProduct({
      TerminateToken: idempotencyToken,
      ProvisionedProductName: provisionedProductName,
    }).promise();
    return response.RecordDetail;
  };

  static async describeRecordDetails(recordId, credentials, region,) {
    const serviceCatalog = await this.getServiceCatalogClient(credentials, region);


    const stackSetDetails = await serviceCatalog.describeRecord({ Id: recordId }).promise();

    // If the provisioned product is a StackSet, we need to grab the status
    // details from the stack(s) contained within the set.
    const stackSetArnOutput = stackSetDetails.RecordOutputs.find(x => x.OutputKey === 'CloudformationStackSetARN')
    if (stackSetArnOutput) {
      const stackSetName = Utils.parseStackSetNameFromArn(stackSetArnOutput.OutputValue)
      const cloudFormation = await this.getCloudFormationClient(credentials, region);

      const stackDetails = await cloudFormation.listStackInstances({ StackSetName: stackSetName }).promise()
      stackSetDetails.StackSummaries = stackDetails.Summaries
      console.log(stackSetDetails)
    }


    return stackSetDetails
  };
}

export default ServiceCatalogWrapper
