import { ComponentDivider } from '..'
import { TimeZone, Region } from '../../models'
import { Flex, View } from '@aws-amplify/ui-react'
import { MigrationCreateForm, MigrationUpdateForm } from '../../ui-components'
import { useCallback } from 'react'
import Utils from "../../lib/utils";

export default function MigrationCreateUpdate({ client, migration, onCloseModal }) {

    const closeModal = () => {
        if (typeof onCloseModal === 'function') {
            return onCloseModal()
        }
        return null
    }

    const onCreate = useCallback((fields) => {
        // Automatically select the data migration region based on the customer time zone
        // when creating a new migration. 
        fields.dataMigrationRegion = selectRegionFromTZ(client.timeZone)
        fields.clientMigrationsClientId = client.clientId
        return fields

    }, [client])

    function onCancel() {
        closeModal()
    }

    function onSuccess() {
        closeModal()
    }

    function selectRegionFromTZ(timeZone) {
        let region = ''
        switch (timeZone) {
            case TimeZone.ET:
                region = Region.US_EAST_1
                break
            case TimeZone.CT:
                region = Region.US_EAST_2
                break
            default:
                region = Region.US_WEST_2

        }
        return region

    }

    const formOverrides = {
        dataMigrationRegionoption0: { children: "us-east-1" },
        dataMigrationRegionoption1: { children: "us-east-2" },
        dataMigrationRegionoption2: { children: "us-west-2" },
        // Don't display the dataMigrationRegion option when creating a new migration so that it can be selected
        // automatically. We know it is a new migration if a migration object is not passed in to
        // the MigrationCreateUpdate component.
        dataMigrationRegion: { label: "Data Migration Region", display: migration ? undefined : 'none' },
        migrationTargetStackId: { label: "Migration Target Stack Id" },
        scheduledCutover: { label: "Estimated Cutover" },
        scheduledStart: { label: "Estimated Start" },
        useDms: { label: "Use Database Migration Service (DMS)" }
    }

    function renderForm() {
        if (migration) {
            return (
                <View>
                    {Utils.modalCloseButton(onCloseModal)}
                    <ComponentDivider title={"Update Migration"} />
                    <MigrationUpdateForm
                        onCancel={onCancel}
                        migration={migration}
                        onSuccess={onSuccess}
                        overrides={formOverrides} />
                </View>
            )
        }
        else {
            return (
                <View direction="column" width="33vw" >
                    {Utils.modalCloseButton(onCloseModal)}
                    <ComponentDivider title="Create New Migration" />
                    <MigrationCreateForm
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onSubmit={onCreate}
                        overrides={formOverrides} />
                </View>)
        }
    }

    return (
        <Flex direction="column" width="33vw" >
            {renderForm()}
            <br />
        </Flex>
    )
}

