import {
  Tabs,
  TabItem,
  View
} from '@aws-amplify/ui-react'
import {
    ManageProduct,
    MigrationTaskTabTitle
} from '../..'
import React from 'react'
import { Auth } from 'aws-amplify';
import Utils from '../../../lib/utils';


export default function MigrationStepTermServerFleet({ client = {}, migrationId, pod = {}, stack = {}, step, }) {
    const [credentials, setCredentials] = React.useState(null)
    const [userProps, setUserProps] = React.useState({})
    const listParam = Utils.converToListParam(client.internalSubnets)

    React.useEffect(() => {
        Auth.currentCredentials().then(credentials => {
            setCredentials(Auth.essentialCredentials(credentials))
        });
        setUserProps(Utils.getProvisioningProps(client.userCount))
    }, [client])

    return (
        <Tabs spacing='equal'>
            <TabItem title={<MigrationTaskTabTitle step={`${step}.1`} migrationId={migrationId} taskName="deployFleetUpdate" title="Update Customer Stack" />}>
                <ManageProduct
                    step='update-customer-stack'
                    credentials={credentials}
                    productName={Utils.getProductName('customer-stack')}
                    provisionedProductName={`${pod.awsAccountId}-${Utils.formatRegion(pod.awsRegion)}-${client.clientId}-lynx-customer-stack`}
                    provisioningParams={[
                        { Key: "ActiveDirectoryShort", Value: pod.domain },
                        { Key: "ActiveDirectoryUserName", Value: "jcarter" },
                        { Key: "ActiveDirectoryPassword", Value: '' },
                        { Key: "AMIAncillary", Value: stack.amiAncServ },
                        { Key: "AMIBase", Value: stack.amiTermServBase },
                        { Key: "AMIPxP", Value: stack.amiPxPServ },
                        { Key: "AMITerminalServer", Value: stack.amiTermServ },
                        { Key: "BaseServerFleetSize", Value: stack.createBaseTermServer ? "1" : "0" },
                        { Key: "CustomerBucketName", Value: Utils.getCustomerBucketName(client.clientId) },
                        { Key: "CustomerID", Value: client.clientId },
                        { Key: "CustomerName", Value: client.clientName },
                        { Key: "CustomerRemoteCIDR", Value: listParam },
                        { Key: "CustomerTimezone", Value: Utils.covertToLongTimeZone(client.timeZone) },
                        { Key: "DNSPrimary", Value: pod.dnsIpPrimary },
                        { Key: "DNSSecondary", Value: pod.dnsIpSecondary },
                        { Key: "EbsSizeAnc", Value: 100 },
                        { Key: "Env", Value: process.env.REACT_APP_ENV },
                        { Key: "ExecutionID", Value: Utils.generateRandomId() },
                        { Key: "InstanceTypeAncServer", Value: userProps.ancInstanceType },
                        { Key: "InstanceTypeTermServer", Value: userProps.termInstanceType },
                        { Key: "InstanceTypeTermServerBase", Value: 't3.medium' },
                        { Key: "InstanceTypeTestServer", Value: userProps.testInstanceType },
                        { Key: "KeyName", Value: pod.instanceKeyPair },
                        { Key: "LoadBalancer", Value: userProps.loadBalancer },
                        { Key: "ManagementGroupId", Value: pod.securityGroupMgmt },
                        { Key: "MedFusionCIDR", Value: pod.medFusionCidr },
                        { Key: "PxPServerSize", Value: userProps.pxpInstanceType },
                        { Key: "PxPServerFleetSize", Value: stack.createPxpServer ? "1" : "0" },
                        { Key: "ServerOS", Value: Utils.trimServerVersion(client.osVersion) },
                        { Key: "SGCustAD", Value: pod.securityGroupAD },
                        { Key: "SGFileServer", Value: pod.securityGroupFile },
                        { Key: "SGManagement", Value: pod.securityGroupMgmt },
                        { Key: "SGSQLServer", Value: pod.securityGroupSql },
                        { Key: "Subnet1AZ", Value: Utils.formatRegion(stack.az1) },
                        { Key: "Subnet2AZ", Value: Utils.formatRegion(stack.az2) },
                        { Key: "Subnet1Cidr", Value: stack.az1Cidr },
                        { Key: "Subnet2Cidr", Value: stack.az2Cidr },
                        { Key: "SQLPod", Value: pod.podName },
                        { Key: "TermServerFleetSize", Value: userProps.termServers },
                        { Key: "TestServerFleetSize", Value: userProps.testServers },
                        { Key: "TransitGatewayId", Value: pod.tgwAttachmentId },
                        { Key: "VpcId", Value: pod.vpcId }
                    ]}
                    targetAccount={pod.awsAccountId}
                    targetRegion={Utils.formatRegion(pod.awsRegion)}
                />
            </TabItem>
        </Tabs>
    )
}