import Logo from '../logo.svg'
import { useState } from 'react';
import {
  useParams,
  useNavigate
} from 'react-router-dom';
import {
  Divider,
  Flex,
  Heading,
  Image,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  View,
  useTheme,
} from '@aws-amplify/ui-react';



export default function Navbar({ signOut, user = { attributes: {} } }) {
  const { tokens } = useTheme();
  const { view } = useParams()
  const [exclusiveValue, setExclusiveValue] = useState(view);

  const baseUrl = "https://nextgenhealthcare.atlassian.net"
  const projectId = 18444
  const helpUrl = `${baseUrl}/wiki/spaces/NGEMF/pages/346196974/Lynx+User+Guide`
  const fileBugUrl = `${baseUrl}/secure/CreateIssue.jspa?issuetype=1&pid=${projectId}`
  const featureRequestUrl = `${baseUrl}/secure/CreateIssue.jspa?issuetype=2&pid=${projectId}`

  const navigate = useNavigate();

  function launchUrl(url) {
    window.open(url, '_blank');
  }


  function getNavBarButton(name, destination) {
    const nameLower = name.toLowerCase();
    const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

    destination = (typeof destination === 'undefined') ? ('/' + nameLower) : destination;

    if (name === 'Terminal Servers' && !cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins')) {
      return;
    }

    if (name === 'Client Services System Access' && !cognitoGroups.includes('ClientServicesSystemAccessUsers')) {
      return;
    }

    if (name === 'Client Services System Access Administration' && !cognitoGroups.includes('ClientServicesSystemAccessAdmins')) {
      return;
    }

    if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
      if (name === 'Clients' || name === 'Pods' || name === 'Stacks' || name === 'Migrations' || name === 'Terminal Servers') {
        return;
      }
    }

    return (
      <ToggleButton
        className="navbar-toggle-button"
        height={tokens.space.xxl}
        borderRadius={0}
        border="none"
        value={nameLower}
        onClick={() => { navigate(`${destination}`) }}
      >
        <Heading level={6}
          fontWeight={tokens.fontWeights.normal}
          color={tokens.colors.font.inverse}>
          {name}
        </Heading>
      </ToggleButton >
    )
  }

  function getCognitoGroupMenuItem() {
    let groupName = 'No User Group Assigned'

    if (
      user &&
      user.signInUserSession &&
      user.signInUserSession.accessToken &&
      user.signInUserSession.accessToken.payload &&
      user.signInUserSession.accessToken.payload['cognito:groups'] &&
      user.signInUserSession.accessToken.payload['cognito:groups'][0]) {
      var match = user.signInUserSession.accessToken.payload['cognito:groups'][0].match(/[A-Z][a-z]+/g
      )
      if (match) {
        groupName = match.join(" ")
      }
    }
    return (
      <MenuItem style={{ cursor: "unset" }} size="small" fontSize={tokens.fontSizes.xs} isDisabled >
        {groupName} Group
      </MenuItem>
    )
  }

  return (
    <View position="sticky" top="0" style={{ zIndex: "110" }} id="navBar" backgroundColor={tokens.colors.brand.primary['80'].value} width="100vw">
      <Flex direction="row" justifyContent="space-between" >
        <Flex
          justifyContent="left"
          alignItems="center"
          position="relative"
          gap="0px"
          rowGap="0px">
          <Flex padding={tokens.space.zero}>
            <Image
              padding={tokens.space.xs}
              paddingLeft={tokens.space.medium}
              paddingRight={tokens.space.medium}
              src={Logo}
              height={tokens.space.xxl}
              alt="Logo" />
          </Flex>
          <ToggleButtonGroup
            value={exclusiveValue}
            isExclusive
            isSelectionRequired
            onChange={(value) => setExclusiveValue(value)} >
            {getNavBarButton('Clients')}
            {getNavBarButton('Pods')}
            {getNavBarButton('Stacks')}
            {getNavBarButton('Migrations')}
            {getNavBarButton('Terminal Servers', 'terminal-servers')}
            {getNavBarButton('Client Services System Access', 'client-services-system-access')}
            {getNavBarButton('Client Services System Access Administration', 'client-services-system-access/admin')}
          </ToggleButtonGroup>
        </Flex>
        <Flex justifyContent="right" alignItems="center" position="relative">
          <Menu menuAlign="end" color={tokens.colors.brand.primary['80'].value}>
            <MenuItem size="small" style={{ cursor: "unset" }} fontSize={tokens.fontSizes.xs} isDisabled >
              {user.attributes.name} ({user.attributes.email})
            </MenuItem>
            {getCognitoGroupMenuItem()}
            <Divider />

            <MenuItem onClick={() => launchUrl(helpUrl)}>
              User Guide
            </MenuItem>
            <MenuItem onClick={() => launchUrl(fileBugUrl)}>
              Report Bug
            </MenuItem>
            <MenuItem onClick={() => launchUrl(featureRequestUrl)}>
              Feature Request
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => signOut()}>
              Sign Out
            </MenuItem>
          </Menu>
        </Flex>
      </Flex>
    </View>
  )
}
