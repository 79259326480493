import {
    ComponentDivider,
    DeleteModel,
    MigrationCreateUpdate,
    MigrationStepBaseApps,
    MigrationStepDms,
    MigrationStepDmsFinal,
    MigrationStepFileshare,
    MigrationStepFileshareFinal,
    MigrationStepSftp,
    MigrationStepSql,
    MigrationStepSqlFinal,
    MigrationStepTermServerFleet,
    MigrationStepQuestionnaire,
    MigrationTargetStackAssign,
    MigrationTaskTabTitle,
    MigrationStepCustomerStack,
    LoadingWrapper,
    SftpPassword,
} from '..'
import { Auth, DataStore } from 'aws-amplify';
import { Migration, Client, Stack, Pod } from '../../models';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Badge,
    Button,
    Expander,
    TableHead,
    Tabs,
    TabItem,
    Flex,
    Table,
    TableBody,
    TableCell,
    TableRow,
    ThemeProvider,
    View,
    ExpanderItem
} from '@aws-amplify/ui-react'
import Utils from '../../lib/utils'
import Modal from 'react-modal';
import { nextGenTheme } from '../../themes';


export default function MigrationDetails() {
    const [client, setClient] = useState({})
    const [deleteModelModalOpen, setDeleteModelModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [migration, setMigration] = useState({})
    const [sftpCredentialsModalOpen, setSftpCredentialsModalOpen] = useState(false)
    const [targetPod, setTargetPod] = useState({})
    const [targetStack, setTargetStack] = useState({})
    const [targetStackModalOpen, setTargetStackModalOpen] = useState(false)
    const [updateMigrationModalOpen, setUpdateMigrationModalOpen] = useState(false)

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const { migrationId } = useParams();
    const navigate = useNavigate();
    let sftpUsername = `ngcstmr${client.clientId}`;
    function openUpdateMigrationModal() {
        setUpdateMigrationModalOpen(true);
    }
    function closeUpdateMigrationModal() {
        setUpdateMigrationModalOpen(false);
    }

    function openSftpCredentialsModal() {
        setSftpCredentialsModalOpen(true);
    }
    function closeSftpCredentialsModal() {
        setSftpCredentialsModalOpen(false);
    }

    function openTargetStackModal() {
        setTargetStackModalOpen(true);
    }
    function closeTargetStackModal() {
        setTargetStackModalOpen(false);
    }

    function openDeleteModelModal() {
        setDeleteModelModalOpen(true);
    }

    function closeDeleteModelModal() {
        setDeleteModelModalOpen(false);
    }

    function navigateToMigrationList() {
        navigate('/migrations');
    }

    function migrationDetailsRow(name, value) {
        return (
            <TableRow>
                <TableCell as="th" textAlign="right">{name}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    const renderDataMigrationStatus = (dataMigrationRegion, targetRegion) => {
        // https://ui.docs.amplify.aws/react/components/badge
        let badgeVariation, tooltip

        if (dataMigrationRegion === targetRegion) {
            badgeVariation = 'success'
            tooltip = 'The data migration region matches the target stack - this is the optimal configuration for transferring files from S3 to the target fileshare.'
        }

        else if (targetRegion && dataMigrationRegion !== targetRegion) {
            badgeVariation = 'error'
            tooltip = `The data migration region does not match the target stack region of ${Utils.formatRegion(targetRegion)}. Additional support may be required to transfer files from S3 to the target fileshare.`
        }

        else {
            badgeVariation = 'default'
            tooltip = 'A target stack has not yet been assigned for this migration. Check this status again once a target stack has been assigned to ensure the optimal region has been selected.'
        }

        return (
            <Badge variation={badgeVariation} size="medium" >
                <div className="tooltip">{Utils.formatRegion(dataMigrationRegion)}
                    <span className="tooltiptext">{tooltip}</span>
                </div>
            </Badge>
        )


    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        const clientSub = DataStore.observeQuery(Client, c => c.migrations.id.eq(migrationId))
            .subscribe(({ items }) => {
                setClient(items[0])
                sftpUsername = `ngcstmr${client.clientId}`;
            });

        const migrationSub = DataStore.observeQuery(Migration, m => m.id.eq(migrationId))
            .subscribe(async ({ items }) => {
                const migration = items[0]
                setMigration(migration)
                setIsLoading(false)
            });

        return () => {
            clientSub.unsubscribe();
            migrationSub.unsubscribe();
        }
    }, [migrationId])

    useEffect(() => {
        if (migration && migrationId) {
            const stackSub = DataStore.observeQuery(Stack, s => s.client.migrations.id.eq(migration.id))
                .subscribe(async ({ items }) => {
                    const targetStack = items[0]
                    setTargetStack(targetStack)
                });

            return () => {
                stackSub.unsubscribe();
            }
        }

    }, [migration])

    useEffect(() => {
        if (targetStack && targetStack.id) {
            const podSub = DataStore.observeQuery(Pod, p => p.stacks.id.contains(targetStack.id))
                .subscribe(async ({ items }) => {
                    setTargetPod(items[0])
                });

            return () => {
                podSub.unsubscribe();
            }
        }

    }, [targetStack])

    return (
        <LoadingWrapper isLoading={isLoading}>
            <View>
                <Flex width={"70vw"} direction="column">
                    <ComponentDivider title={`Migration Details - ${migration.migrationName}`} />
                    <Expander isCollapsible={true}>
                        <ExpanderItem title="Migration Details" value="migration-details">
                            <View>
                                <Table width="30vw" size="small" variation='bordered'>
                                    <TableBody>
                                        {migrationDetailsRow("Client ID", client.clientId)}
                                        {migrationDetailsRow("Client Name", client.clientName)}
                                        {migrationDetailsRow("Estimated Start", migration.scheduledStart)}
                                        {migrationDetailsRow("Estimated Cutover", migration.scheduledStart)}
                                        {migrationDetailsRow("Customer Time Zone", client.timeZone)}
                                        {migrationDetailsRow("Data Migration Region", renderDataMigrationStatus(migration.dataMigrationRegion, targetPod.awsRegion))}
                                        {migrationDetailsRow("Use Database Migration Service?", migration.useDms ? "Yes" : "No")}
                                        {migrationDetailsRow("Pod Client is Attached To", targetPod.podName)}
                                    </TableBody>
                                </Table>
                                <ComponentDivider title="Target Stack" level={4} />
                                <Table highlightOnHover={true} variation="striped">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell as="th">Stack Name</TableCell>
                                            <TableCell as="th">Primary AZ</TableCell>
                                            <TableCell as="th">Primary CIDR</TableCell>
                                            <TableCell as="th">Secondary AZ</TableCell>
                                            <TableCell as="th">Secondary CIDR</TableCell>
                                            <TableCell as="th">POD Attached To Stack</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {targetStack &&
                                            (<TableRow style={{ cursor: 'pointer' }}
                                                onClick={() => navigate(`/stack/${targetStack.id}`)}
                                                key={targetStack.id}>
                                                <TableCell>{targetStack.stackName}</TableCell>
                                                <TableCell>{Utils.formatRegion(targetStack.az1)}</TableCell>
                                                <TableCell>{targetStack.az1Cidr}</TableCell>
                                                <TableCell>{Utils.formatRegion(targetStack.az2)}</TableCell>
                                                <TableCell>{targetStack.az2Cidr}</TableCell>
                                                <TableCell>{targetPod.podName}</TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </View>
                        </ExpanderItem>

                    </Expander>
                    <Flex direction="row" justifyContent="end" alignItems="flex-end">
                        <View />
                        <Button onClick={openDeleteModelModal} size="small" variation="secondary">
                            Delete
                        </Button>
                        <Button onClick={openTargetStackModal} size="small" variation="primary">
                            Select Target Stack
                        </Button>
                        <Button onClick={openSftpCredentialsModal} size="small" variation="primary">
                            View SFTP Credentials
                        </Button>
                        <Button onClick={openUpdateMigrationModal} size="small" variation="primary">
                            Edit Details
                        </Button>
                    </Flex>
                    <ComponentDivider title="Migration Workflow" />
                    <Tabs spacing="relative">
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="1" taskName="questionnaire" title="Migration Questionnaire" />}>
                            <MigrationStepQuestionnaire step="1" clientId={client.clientId} migrationId={migration.id} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="2" taskName="sftp" title="Provision SFTP" />}>
                            <MigrationStepSftp step="2" clientId={client.clientId} migrationId={migration.id} dataRegion={migration.dataMigrationRegion} pod={targetPod} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="3" taskName="migrateFileshare" title="Migrate Fileshare" />}>
                            <MigrationStepFileshare step="3" clientId={client.clientId} dataRegion={migration.dataMigrationRegion} migrationId={migration.id} pod={targetPod} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="4" taskName="migrateDatabases" title="Migrate Databases" />}>
                            {
                                migration.useDms ?
                                    <MigrationStepDms step="4" clientId={client.clientId} migrationId={migration.id} pod={targetPod} /> :
                                    <MigrationStepSql step="4" client={client} dataRegion={migration.dataMigrationRegion} migrationId={migration.id} pod={targetPod} />
                            }
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="5" taskName="deployStack" title="Deploy Stack" />}>
                            <MigrationStepCustomerStack step="5" migrationId={migration.id} pod={targetPod} stack={targetStack} client={client} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="6" taskName="configureApps" title="Configure Apps" />}>
                            <MigrationStepBaseApps step="6" migrationId={migration.id} pod={targetPod} stack={targetStack} client={client} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="7" taskName="deployFleet" title="Deploy Fleet" />}>
                            <MigrationStepTermServerFleet step="7" migrationId={migration.id} pod={targetPod} stack={targetStack} client={client} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="8" taskName="updateFileshare" title="Update Fileshare" />}>
                            <MigrationStepFileshareFinal step="9" migrationId={migration.id} dataRegion={migration.dataMigrationRegion} pod={targetPod} clientId={client.clientId} />
                        </TabItem>
                        <TabItem title={<MigrationTaskTabTitle migrationId={migration.id} step="9" taskName="updateDatabases" title="Update Databases" />}>
                            {
                                migration.useDms ?
                                    <MigrationStepDmsFinal step="9" migrationId={migration.id} pod={targetPod} clientId={client.clientId} /> :
                                    <MigrationStepSqlFinal step="9" migrationId={migration.id} dataRegion={migration.dataMigrationRegion} pod={targetPod} clientId={client.clientId} />
                            }
                        </TabItem>
                    </Tabs>
                </Flex>
                <Modal style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={targetStackModalOpen} onRequestClose={closeTargetStackModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <MigrationTargetStackAssign migration={migration} client={client} onCloseModal={closeTargetStackModal} />
                    </ThemeProvider>
                </Modal>
                <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={updateMigrationModalOpen} onRequestClose={closeUpdateMigrationModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <MigrationCreateUpdate client={client} migration={migration} onCloseModal={closeUpdateMigrationModal} />
                    </ThemeProvider>
                </Modal>
                <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={sftpCredentialsModalOpen} onRequestClose={closeSftpCredentialsModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <SftpPassword region={migration.dataMigrationRegion} username={sftpUsername} onCloseModal={closeSftpCredentialsModal} />
                    </ThemeProvider>
                </Modal>
                <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={deleteModelModalOpen} onRequestClose={closeDeleteModelModal}>
                    <ThemeProvider theme={nextGenTheme} >
                        <DeleteModel model={migration} onCancel={closeDeleteModelModal} onDelete={navigateToMigrationList} name={migration.migrationName} />
                    </ThemeProvider>
                </Modal>
            </View >
        </LoadingWrapper >
    )
}


