import { Flex, TextField, Button, View } from '@aws-amplify/ui-react'
import { Storage } from 'aws-amplify';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { rainbow as darkTheme, atomOneLight as lightTheme} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useError } from '../common/hooks'

export default function Document({ templateVars = {}, customStyle = {}, language, showLineNumbers, enableUpload, uploadPath, assetPath, useLightTheme}) {
    const [rawDocument, setRawDocument] = React.useState('')
    const [isUploading, setIsUploading] = React.useState(false)
    const { addError } = useError();
    const paddingTop = Object.keys(templateVars).length > 0 ? "1em" : 0


    const [processedDocument, setProcessedDocument] = React.useState('')
    const [fields, setFields] = React.useState(templateVars)
    const buttonShadowStyle = "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"

    const onTemplateFieldChange = React.useCallback((event) => {
        setFields(existingValues => ({
            ...existingValues,
            [event.target.dataset.templatekey]: event.target.value,
        }))
    }, [])

    const onCopyCode = () => {
        navigator.clipboard.writeText(processedDocument)
    }


    async function onUpload(e) {
        setIsUploading(true)
        try {
            await Storage.put(uploadPath, processedDocument, {
                cacheControl: "no-cache"
            });
        } catch (err) {
            addError(err.message, err.statusCode)
        }
        finally {
            setTimeout(() => {
                setIsUploading(false)

            }, 2000);
        }
    }


    const onDownload = React.useCallback((event) => {
        const element = document.createElement('a');
        const fileName = assetPath.substring(assetPath.lastIndexOf('/') + 1);

        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(processedDocument)}`);
        element.setAttribute('download', fileName);
        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }, [processedDocument, assetPath])

    React.useEffect(() => {
        let newDocument = rawDocument;
        for (const [key, value] of Object.entries(fields)) {
            newDocument = newDocument.replace(RegExp(`{{${key}}}`, 'g'), value)
        }
        setProcessedDocument(newDocument)
    }, [rawDocument, fields])


    React.useEffect(() => {
        const getRawDocument = async () => {
            const result = await Storage.get(assetPath, { download: true })
            let text = await result.Body.text()
            setRawDocument(text)
        }
        getRawDocument()
    }, [assetPath])

    return (
        <Flex paddingTop={paddingTop} direction="column">
            <Flex direction="row" wrap="wrap">
                {
                    Object.keys(templateVars).map((key) =>
                        <TextField
                            size="small"
                            key={key}
                            minWidth="300px"
                            data-templatekey={key}
                            onChange={onTemplateFieldChange}
                            defaultValue={templateVars[key]}
                            label={`${key}:`}
                        />
                    )
                }
            </Flex>
            <View position={"relative"}>
                <Flex direction="row" position={"absolute"} top="30px" right="20px">
                    <Button alignSelf="flex-end" size="small" height="30px" onClick={onCopyCode} variation="primary" boxShadow={buttonShadowStyle}>Copy</Button>
                    <Button alignSelf="flex-end" size="small" height="30px" onClick={onDownload} variation="primary" boxShadow={buttonShadowStyle}>Download</Button>
                    {enableUpload && <Button alignSelf="flex-end" size="small" isLoading={isUploading} height="30px" onClick={onUpload} variation="primary" boxShadow={buttonShadowStyle}>Upload</Button>}
                </Flex>
                <SyntaxHighlighter customStyle={customStyle} style={useLightTheme ? lightTheme : darkTheme} language={language} showLineNumbers={showLineNumbers} >
                    {processedDocument}
                </SyntaxHighlighter>
            </View>
        </Flex >
    )
}

