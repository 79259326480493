import {
    Button, Flex, Pagination, SearchField, Table, TableBody, TableCell,
    TableHead, TableRow, ThemeProvider, View, ExpanderItem, Expander, Alert
} from '@aws-amplify/ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ComponentDivider, LoadingWrapper } from "..";
import Modal from "react-modal";
import { nextGenTheme } from "../../themes";
import Utils from "../../lib/utils";
import {Auth, API, DataStore} from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { CSSAClient } from "../../models";
import { createCSSAClient, createCSSADatabase } from '../../graphql/mutations';
import ClientServicesCreateClient from "../../ui-components/ClientServicesCreateClient";
import { listCSSAClients, listSupportClientRequests } from '../../graphql/queries';


export default function ClientServicesAdmin() {
    const [clients, setClients]                     = useState([]);
    const [visibleClients, setVisibleClients]       = useState([]);
    const [currentPage, setCurrentPage]             = useState(1);
    const [totalPages, setTotalPages]               = useState(1);
    const [isLoading, setIsLoading]                 = useState(true);
    const [onboardModalOpen, setOnboardModalOpen]   = useState(false);
    const [clientRequest, setRequests]              = useState([])
    const navigate                                  = useNavigate();


    function get_region(region) {
        if (region) {
            return region.replaceAll("-", "_");
        }
    }

    const formOverrides = {
        region: {
            options: getAllRegions()
        }
    }

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

        sessionStorage.setItem('userAttributesName', user.attributes.name);

        if (!cognitoGroups.includes('ClientServicesSystemAccessAdmins')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });
    function getAllRegions() {
        return [
            { id: "us-east-1", label: 'us-east-1' },
            { id: "us-east-2", label: 'us-east-2' },
            { id: "us-west-2", label: 'us-west-2' }
        ]
    }

    const pageSize = 25;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function getLastCredentialCreated(curClient) {
        const specificClientRequests = clientRequest.filter(function (el) {
            return el.cSSAClientSupportClientRequestsId == curClient.id
        })
        if (specificClientRequests.length == 0) {
            return "No Credentials Created"
        }
        else {
            return (convertToLocal(
                new Date(Math.max.apply(null, specificClientRequests.map(function (e) {
                    return new Date(e.createdAt);
                }
                )
                )
                )
            ));
        }
    }

    const convertToLocal = (date) => {
        const df = new Intl.DateTimeFormat("default", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            calendar: "iso8601",
            timeZoneName: "short",
            numberingSystem: "latn",
            hourCycle: "h12",
        });
        return df.format(date);
    };

    function getNumberOfOpenCredentials(curClient) {
        const specificClientRequests = clientRequest.filter(function (el) {
            return el.cSSAClientSupportClientRequestsId == curClient.id &&
                el.status == 'Active'
        })
        return specificClientRequests.length;
    }

    const search = useCallback(async (event) => {
        const filter = event.target.value;
        API.graphql({
            query: listCSSAClients,
            variables: {
                filter: {
                    or: [
                        {
                            clientId: {
                                contains: filter
                            }
                        },
                        {
                            clientName: {
                                contains: filter.toLowerCase()
                            }
                        }
                    ]
                }
            }
        }).then(
            (items) => {
                setCurrentPage(1);
                setClients(items.data.listCSSAClients.items);
            })
    }, [])


    function openOnboardModal() {
        setOnboardModalOpen(true);
    }

    function closeOnboardModal() {
        setOnboardModalOpen(false);
    }

    function addClientToDatabase(e) {
        // Add the NextGen Client to the database if they do not exist else add a new database env
        try {
            // check to see if customer exists
            API.graphql(
                {
                    query: listCSSAClients,
                    variables: {
                        filter: {
                            and: [{
                                clientId: {
                                    eq: e.clientId
                                }
                            },
                            {
                                _deleted:
                                {
                                    ne: true
                                }
                            }]
                        }
                    }
                }).then(
                    (items) => {
                        // If customer exist add a new database
                        if (items.data.listCSSAClients.items.length !== 0) {
                            API.graphql({
                                query: createCSSADatabase,
                                variables: {
                                    input: {
                                        serverName: e.serverName,
                                        databaseEnv: e.databaseEnvironment,
                                        cSSAClientServersId: items.data.listCSSAClients.items[0].id
                                    }
                                }
                            })
                        }
                        else {
                            // client doesn't exist so create it
                            API.graphql({
                                query: createCSSAClient,
                                variables: {
                                    input: {
                                        clientId: e.clientId,
                                        clientName: e.clientName,
                                        awsAccount: e.accountId,
                                        region: get_region(e.region)
                                    }
                                }
                            }).then(
                                (items) => {
                                    API.graphql({
                                        query: createCSSADatabase,
                                        variables: {
                                            input: {
                                                serverName: e.serverName,
                                                databaseEnv: e.databaseEnvironment,
                                                cSSAClientServersId: items.data.createCSSAClient.id
                                            }
                                        }
                                    }).then(
                                        () => {
                                            window.location.reload()

                                        }
                                    )
                                }
                            )

                        }
                    })
        } catch (error) {
            console.error("Error adding client", error);
            return (
                <Alert serverty="error"> Error adding current client </Alert>
            )
        }
        closeOnboardModal();
    }

    async function clear() {
        const clients = await DataStore.query(CSSAClient);
        setClients(clients);
    }

    useEffect(() => {
        function listAllCSSAClients(_clients = [], _nextToken = null) {
            API.graphql(
                {
                    query: listCSSAClients,
                    variables: {
                        filter: {
                            _deleted: {
                                ne: true
                            }
                        },
                        limit: 1000,
                        nextToken: _nextToken
                    }
                }
            ).then(
                (items) => {
                    _clients.push(...items.data.listCSSAClients.items);

                    if (!items.data.listCSSAClients.nextToken) {
                        setClients(_clients);
                        setTotalPages(Utils.getPageCount(pageSize, _clients.length));
                        setIsLoading(false);
                    } else {
                        return listAllCSSAClients(_clients, items.data.listCSSAClients.nextToken);
                    }
                }
            );
        }

        listAllCSSAClients();

        API.graphql(
            {
                query: listSupportClientRequests,
                variables: {
                    filter: {
                        _deleted: {
                            ne: true
                        }
                    }
                }
            }
        ).then(
            (items) => {
                setRequests(items.data.listSupportClientRequests.items);
            }
        )
    }, []);

    useEffect(() => {
        setTotalPages(Utils.getPageCount(pageSize, clients.length));
        setVisibleClients(Utils.getPageItems(clients, pageSize, currentPage));
    }, [clients, currentPage]);

    return (
        <View>
            <Modal shouldCloseOnOverlayClick={false} style={customStyles} appElement={document.getElementById('app')} isOpen={onboardModalOpen} onRequestClose={closeOnboardModal}>
                <ThemeProvider theme={nextGenTheme} >
                    {Utils.modalCloseButton(closeOnboardModal)}
                    <ComponentDivider level={4} title="Onboard a New Client" />
                    <ClientServicesCreateClient
                        overrides={formOverrides}
                        onSubmit={(e) => addClientToDatabase(e)}
                    /><br /><br />
                </ThemeProvider>
            </Modal>

            <LoadingWrapper isLoading={isLoading}>
                <Flex width="60rem" direction="column">
                    <View style={{ textAlign: "right", width: "60rem" }}>
                        <Button size="small" onClick={(e) => { openOnboardModal(); }}>Onboard a New Client</Button>
                    </View>

                    <ComponentDivider title="All Clients" />

                    <Table border="0">
                        <TableBody>
                            <TableRow>
                                <TableCell width="80%" border="0">
                                    <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={search} onClear={clear} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Table highlightOnHover={true} variation="striped" className="sortable">
                        <TableHead>
                            <TableCell as="th">
                                Client ID
                            </TableCell>
                            <TableCell as="th">
                                Client Name
                            </TableCell>
                            <TableCell as="th">
                                Last Credentials Created
                            </TableCell>
                            <TableCell as="th">
                                Number of Open Credentials
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                visibleClients.length === 0 ? <TableRow><TableCell colSpan="4" style={{textAlign: "center"}}>(No Results)</TableCell></TableRow> : visibleClients.map(cl => (
                                    <TableRow key={cl.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`${cl.clientId}`)}>
                                        <TableCell>{cl.clientId}</TableCell>
                                        <TableCell>{cl.clientName}</TableCell>
                                        <TableCell>{getLastCredentialCreated(cl)}</TableCell>
                                        <TableCell>{getNumberOfOpenCredentials(cl)}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Flex>
            </LoadingWrapper>
        </View>
    );
}
