import {Auth, DataStore} from 'aws-amplify';
import { Migration, Stack, Client } from '../../models';
import { MigrationCreateUpdate, ComponentDivider, ClientStackAssign, ClientCreateUpdate, LoadingWrapper, DeleteModel } from '..'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { nextGenTheme } from '../../themes';
import Utils from '../../lib/utils'
import {
    Button, Flex, Table, TableBody, TableCell,
    TableHead, TableRow, ThemeProvider, View
} from '@aws-amplify/ui-react'


export default function ClientDetails() {

    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState({})
    const [stacks, setStacks] = useState([])
    const [migrations, setMigrations] = useState([])
    const [createMigrationModalOpen, setCreateMigrationModalOpen] = useState(false)
    const [assignStackModalOpen, setAssignStackModalOpen] = useState(false)
    const [updateClientModalOpen, setUpdateClientModalOpen] = useState(false)
    const [deleteModelModalOpen, setDeleteModelModalOpen] = useState(false)

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups     = user.signInUserSession.accessToken.payload['cognito:groups'];

        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins') && !cognitoGroups.includes('MigrationEngineers')) {
            const error = new Error('401 Not Authorized');

            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    function openUpdateClientModal() {
        setUpdateClientModalOpen(true);
    }
    function closeUpdateClientModal() {
        setUpdateClientModalOpen(false);
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const { clientId } = useParams();
    const navigate = useNavigate();

    function openCreateMigrationModal() {
        setCreateMigrationModalOpen(true);
    }
    function closeCreateMigrationModal() {
        setCreateMigrationModalOpen(false);
    }

    function openAssignStackModal() {
        setAssignStackModalOpen(true);
    }
    function closeAssignStackModal() {
        setAssignStackModalOpen(false);
    }

    function openDeleteModelModal() {
        setDeleteModelModalOpen(true);
    }

    function closeDeleteModelModal() {
        setDeleteModelModalOpen(false);
    }

    function navigateToClientList() {
        navigate('/clients');
    }


    function clientDetailsRow(name, value) {
        return (
            <TableRow>
                <TableCell as="th" textAlign="right">{name}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    useEffect(() => {
        const clientSub = DataStore.observeQuery(Client, c => c.clientId.eq(clientId))
            .subscribe(({ items }) => {
                setClient(items[0] || {})
                setIsLoading(false)
            });


        const stackSub = DataStore.observeQuery(Stack, s => s.client.clientId.eq(clientId))
            .subscribe(({ items }) => {
                setStacks(items)
            });

        const migrationSub = DataStore.observeQuery(Migration, m => m.client.clientId.eq(clientId))
            .subscribe(({ items }) => {
                setMigrations(items)
            });

        return () => {
            clientSub.unsubscribe();
            stackSub.unsubscribe();
            migrationSub.unsubscribe();
        }
    }, [clientId])

    return (
        <View>
            <LoadingWrapper isLoading={isLoading}>
                <Flex width="60rem" direction="column">
                    <ComponentDivider title={client.clientName} />
                    <Flex direction="row" justifyContent="end" alignItems="flex-end">
                        <View />
                        <Button onClick={openDeleteModelModal} size="small" variation="secondary">
                            Delete
                        </Button>
                        <Button onClick={openUpdateClientModal} size="small" variation="primary">
                            Edit Details
                        </Button>
                    </Flex>
                    <Table size="small" variation='bordered'>
                        <TableBody>
                            {clientDetailsRow("Client ID", client.clientId)}
                            {clientDetailsRow("Client Name", client.clientName)}
                            {clientDetailsRow("Leveraged", client.leveraged ? "Yes" : "No")}
                            {clientDetailsRow("Multi-Timezone", client.multiTimezone ? "Yes" : "No")}
                            {clientDetailsRow("User Count", client.userCount)}
                            {clientDetailsRow("Time Zone", client.timeZone)}
                            {clientDetailsRow("Purchased Storage ", `${client.purchasedStoraged || 0} GB`)}
                            {clientDetailsRow("Internal Subnets ", client.internalSubnets && client.internalSubnets.join(", "))}
                            {clientDetailsRow("OS ", client.osVersion)}
                        </TableBody>
                    </Table>
                    <ComponentDivider title="Stacks" level={4} />
                    <Flex direction="row" justifyContent="space-between" alignItems="flex-end">
                        <View />
                        <Button onClick={openAssignStackModal} size="small" variation="primary">
                            Assign Stack</Button>
                    </Flex>
                    <Table highlightOnHover={true} variation="striped">
                        <TableHead>
                            <TableRow >
                                <TableCell as="th">Stack Name</TableCell>
                                <TableCell as="th">Primary AZ</TableCell>
                                <TableCell as="th">Primary CIDR</TableCell>
                                <TableCell as="th">Secondary AZ</TableCell>
                                <TableCell as="th">Secondary CIDR</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stacks.map(stack =>

                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => navigate(`/stack/${stack.id}`)} key={stack.id}>
                                        <TableCell>{stack.stackName}</TableCell>
                                        <TableCell>{Utils.formatRegion(stack.az1)}</TableCell>
                                        <TableCell>{stack.az1Cidr}</TableCell>
                                        <TableCell>{Utils.formatRegion(stack.az2)}</TableCell>
                                        <TableCell>{stack.az2Cidr}</TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                    <ComponentDivider title="Migrations" level={4} />
                    <Flex direction="row" justifyContent="space-between" alignItems="flex-end">
                        <View />
                        <Button onClick={openCreateMigrationModal} size="small" variation="primary">
                            Create Migration</Button>
                    </Flex>
                    <Table highlightOnHover={true} variation="striped">
                        <TableHead>
                            <TableRow >
                                <TableCell as="th">Migration Name</TableCell>
                                <TableCell as="th">Target Stack ID</TableCell>
                                <TableCell as="th">Start Date</TableCell>
                                <TableCell as="th">Cutover Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                migrations.map(migration =>

                                    <TableRow onClick={() => navigate(`/migration/${migration.id}`)} style={{ cursor: 'pointer' }} key={migration.id}>
                                        <TableCell>{migration.migrationName}</TableCell>
                                        <TableCell>{Utils.shortGuid(migration.migrationTargetStackId)}</TableCell>
                                        <TableCell >{migration.scheduledStart}</TableCell>
                                        <TableCell >{migration.scheduledCutover}</TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </Flex >
                <View>
                    <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={createMigrationModalOpen} onRequestClose={closeCreateMigrationModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <MigrationCreateUpdate client={client} onCloseModal={closeCreateMigrationModal} />
                        </ThemeProvider>
                    </Modal>
                    <Modal style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={assignStackModalOpen} onRequestClose={closeAssignStackModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ClientStackAssign client={client} onCloseModal={closeAssignStackModal} />
                        </ThemeProvider>
                    </Modal>
                    <Modal shouldCloseOnOverlayClick={false} style={customStyles} width="33vw" appElement={document.getElementById('app')} isOpen={updateClientModalOpen} onRequestClose={closeUpdateClientModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ClientCreateUpdate client={client} onCloseModal={closeUpdateClientModal} />
                        </ThemeProvider>
                    </Modal>
                    <Modal style={customStyles} width="50vw" appElement={document.getElementById('app')} isOpen={deleteModelModalOpen} onRequestClose={closeDeleteModelModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <DeleteModel model={client} onCancel={closeDeleteModelModal} onDelete={navigateToClientList} name={client.clientName} />
                        </ThemeProvider>
                    </Modal>
                </View>
            </LoadingWrapper >
        </View>

    )
}

