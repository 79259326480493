/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { Pod } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function PodUpdateForm(props) {
  const {
    id: idProp,
    pod: podModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    podName: "",
    domain: "",
    domainUser: "",
    timeZone: "",
    podSubId: "",
    awsAccountId: "",
    awsRegion: "",
    multiTimezone: false,
    vpcId: "",
    vpcCidr: "",
    securityGroupFile: "",
    securityGroupSql: "",
    securityGroupMgmt: "",
    securityGroupAD: "",
    sqlAddress: "",
    sqlName: "",
    sqlAz: "",
    sqlReportAddress: "",
    sqlReportName: "",
    sqlReportAz: "",
    fileServerAddress: "",
    fileServerName: "",
    fileServerAz: "",
    dnsIpPrimary: "",
    dnsIpSecondary: "",
    tgwAttachmentId: "",
    medFusionCidr: "",
    instanceKeyPair: "",
  };
  const [podName, setPodName] = React.useState(initialValues.podName);
  const [domain, setDomain] = React.useState(initialValues.domain);
  const [domainUser, setDomainUser] = React.useState(initialValues.domainUser);
  const [timeZone, setTimeZone] = React.useState(initialValues.timeZone);
  const [podSubId, setPodSubId] = React.useState(initialValues.podSubId);
  const [awsAccountId, setAwsAccountId] = React.useState(
    initialValues.awsAccountId
  );
  const [awsRegion, setAwsRegion] = React.useState(initialValues.awsRegion);
  const [multiTimezone, setMultiTimezone] = React.useState(
    initialValues.multiTimezone
  );
  const [vpcId, setVpcId] = React.useState(initialValues.vpcId);
  const [vpcCidr, setVpcCidr] = React.useState(initialValues.vpcCidr);
  const [securityGroupFile, setSecurityGroupFile] = React.useState(
    initialValues.securityGroupFile
  );
  const [securityGroupSql, setSecurityGroupSql] = React.useState(
    initialValues.securityGroupSql
  );
  const [securityGroupMgmt, setSecurityGroupMgmt] = React.useState(
    initialValues.securityGroupMgmt
  );
  const [securityGroupAD, setSecurityGroupAD] = React.useState(
    initialValues.securityGroupAD
  );
  const [sqlAddress, setSqlAddress] = React.useState(initialValues.sqlAddress);
  const [sqlName, setSqlName] = React.useState(initialValues.sqlName);
  const [sqlAz, setSqlAz] = React.useState(initialValues.sqlAz);
  const [sqlReportAddress, setSqlReportAddress] = React.useState(
    initialValues.sqlReportAddress
  );
  const [sqlReportName, setSqlReportName] = React.useState(
    initialValues.sqlReportName
  );
  const [sqlReportAz, setSqlReportAz] = React.useState(
    initialValues.sqlReportAz
  );
  const [fileServerAddress, setFileServerAddress] = React.useState(
    initialValues.fileServerAddress
  );
  const [fileServerName, setFileServerName] = React.useState(
    initialValues.fileServerName
  );
  const [fileServerAz, setFileServerAz] = React.useState(
    initialValues.fileServerAz
  );
  const [dnsIpPrimary, setDnsIpPrimary] = React.useState(
    initialValues.dnsIpPrimary
  );
  const [dnsIpSecondary, setDnsIpSecondary] = React.useState(
    initialValues.dnsIpSecondary
  );
  const [tgwAttachmentId, setTgwAttachmentId] = React.useState(
    initialValues.tgwAttachmentId
  );
  const [medFusionCidr, setMedFusionCidr] = React.useState(
    initialValues.medFusionCidr
  );
  const [instanceKeyPair, setInstanceKeyPair] = React.useState(
    initialValues.instanceKeyPair
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = podRecord
      ? { ...initialValues, ...podRecord }
      : initialValues;
    setPodName(cleanValues.podName);
    setDomain(cleanValues.domain);
    setDomainUser(cleanValues.domainUser);
    setTimeZone(cleanValues.timeZone);
    setPodSubId(cleanValues.podSubId);
    setAwsAccountId(cleanValues.awsAccountId);
    setAwsRegion(cleanValues.awsRegion);
    setMultiTimezone(cleanValues.multiTimezone);
    setVpcId(cleanValues.vpcId);
    setVpcCidr(cleanValues.vpcCidr);
    setSecurityGroupFile(cleanValues.securityGroupFile);
    setSecurityGroupSql(cleanValues.securityGroupSql);
    setSecurityGroupMgmt(cleanValues.securityGroupMgmt);
    setSecurityGroupAD(cleanValues.securityGroupAD);
    setSqlAddress(cleanValues.sqlAddress);
    setSqlName(cleanValues.sqlName);
    setSqlAz(cleanValues.sqlAz);
    setSqlReportAddress(cleanValues.sqlReportAddress);
    setSqlReportName(cleanValues.sqlReportName);
    setSqlReportAz(cleanValues.sqlReportAz);
    setFileServerAddress(cleanValues.fileServerAddress);
    setFileServerName(cleanValues.fileServerName);
    setFileServerAz(cleanValues.fileServerAz);
    setDnsIpPrimary(cleanValues.dnsIpPrimary);
    setDnsIpSecondary(cleanValues.dnsIpSecondary);
    setTgwAttachmentId(cleanValues.tgwAttachmentId);
    setMedFusionCidr(cleanValues.medFusionCidr);
    setInstanceKeyPair(cleanValues.instanceKeyPair);
    setErrors({});
  };
  const [podRecord, setPodRecord] = React.useState(podModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp ? await DataStore.query(Pod, idProp) : podModelProp;
      setPodRecord(record);
    };
    queryData();
  }, [idProp, podModelProp]);
  React.useEffect(resetStateValues, [podRecord]);
  const validations = {
    podName: [{ type: "Required" }],
    domain: [{ type: "Required" }],
    domainUser: [],
    timeZone: [{ type: "Required" }],
    podSubId: [{ type: "Required" }],
    awsAccountId: [],
    awsRegion: [],
    multiTimezone: [],
    vpcId: [],
    vpcCidr: [],
    securityGroupFile: [],
    securityGroupSql: [],
    securityGroupMgmt: [],
    securityGroupAD: [],
    sqlAddress: [],
    sqlName: [],
    sqlAz: [],
    sqlReportAddress: [],
    sqlReportName: [],
    sqlReportAz: [],
    fileServerAddress: [],
    fileServerName: [],
    fileServerAz: [],
    dnsIpPrimary: [],
    dnsIpSecondary: [],
    tgwAttachmentId: [],
    medFusionCidr: [],
    instanceKeyPair: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          podName,
          domain,
          domainUser,
          timeZone,
          podSubId,
          awsAccountId,
          awsRegion,
          multiTimezone,
          vpcId,
          vpcCidr,
          securityGroupFile,
          securityGroupSql,
          securityGroupMgmt,
          securityGroupAD,
          sqlAddress,
          sqlName,
          sqlAz,
          sqlReportAddress,
          sqlReportName,
          sqlReportAz,
          fileServerAddress,
          fileServerName,
          fileServerAz,
          dnsIpPrimary,
          dnsIpSecondary,
          tgwAttachmentId,
          medFusionCidr,
          instanceKeyPair,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Pod.copyOf(podRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "PodUpdateForm")}
      {...rest}
    >
      <TextField
        label="Pod name"
        isRequired={true}
        isReadOnly={false}
        value={podName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName: value,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.podName ?? value;
          }
          if (errors.podName?.hasError) {
            runValidationTasks("podName", value);
          }
          setPodName(value);
        }}
        onBlur={() => runValidationTasks("podName", podName)}
        errorMessage={errors.podName?.errorMessage}
        hasError={errors.podName?.hasError}
        {...getOverrideProps(overrides, "podName")}
      ></TextField>
      <TextField
        label="Domain"
        isRequired={true}
        isReadOnly={false}
        value={domain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain: value,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.domain ?? value;
          }
          if (errors.domain?.hasError) {
            runValidationTasks("domain", value);
          }
          setDomain(value);
        }}
        onBlur={() => runValidationTasks("domain", domain)}
        errorMessage={errors.domain?.errorMessage}
        hasError={errors.domain?.hasError}
        {...getOverrideProps(overrides, "domain")}
      ></TextField>
      <TextField
        label="Domain user"
        isRequired={false}
        isReadOnly={false}
        value={domainUser}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser: value,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.domainUser ?? value;
          }
          if (errors.domainUser?.hasError) {
            runValidationTasks("domainUser", value);
          }
          setDomainUser(value);
        }}
        onBlur={() => runValidationTasks("domainUser", domainUser)}
        errorMessage={errors.domainUser?.errorMessage}
        hasError={errors.domainUser?.hasError}
        {...getOverrideProps(overrides, "domainUser")}
      ></TextField>
      <SelectField
        label="Time zone"
        placeholder="Please select an option"
        isDisabled={false}
        value={timeZone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone: value,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.timeZone ?? value;
          }
          if (errors.timeZone?.hasError) {
            runValidationTasks("timeZone", value);
          }
          setTimeZone(value);
        }}
        onBlur={() => runValidationTasks("timeZone", timeZone)}
        errorMessage={errors.timeZone?.errorMessage}
        hasError={errors.timeZone?.hasError}
        {...getOverrideProps(overrides, "timeZone")}
      >
        <option
          children="At"
          value="AT"
          {...getOverrideProps(overrides, "timeZoneoption0")}
        ></option>
        <option
          children="Az"
          value="AZ"
          {...getOverrideProps(overrides, "timeZoneoption1")}
        ></option>
        <option
          children="Ct"
          value="CT"
          {...getOverrideProps(overrides, "timeZoneoption2")}
        ></option>
        <option
          children="Et"
          value="ET"
          {...getOverrideProps(overrides, "timeZoneoption3")}
        ></option>
        <option
          children="Mt"
          value="MT"
          {...getOverrideProps(overrides, "timeZoneoption4")}
        ></option>
        <option
          children="Pt"
          value="PT"
          {...getOverrideProps(overrides, "timeZoneoption5")}
        ></option>
      </SelectField>
      <SelectField
        label="Pod sub id"
        placeholder="Please select an option"
        isDisabled={false}
        value={podSubId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId: value,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.podSubId ?? value;
          }
          if (errors.podSubId?.hasError) {
            runValidationTasks("podSubId", value);
          }
          setPodSubId(value);
        }}
        onBlur={() => runValidationTasks("podSubId", podSubId)}
        errorMessage={errors.podSubId?.errorMessage}
        hasError={errors.podSubId?.hasError}
        {...getOverrideProps(overrides, "podSubId")}
      >
        <option
          children="Pod0"
          value="Pod0"
          {...getOverrideProps(overrides, "podSubIdoption0")}
        ></option>
        <option
          children="Pod1"
          value="Pod1"
          {...getOverrideProps(overrides, "podSubIdoption1")}
        ></option>
        <option
          children="Pod2"
          value="Pod2"
          {...getOverrideProps(overrides, "podSubIdoption2")}
        ></option>
        <option
          children="Pod3"
          value="Pod3"
          {...getOverrideProps(overrides, "podSubIdoption3")}
        ></option>
        <option
          children="Pod4"
          value="Pod4"
          {...getOverrideProps(overrides, "podSubIdoption4")}
        ></option>
        <option
          children="Pod5"
          value="Pod5"
          {...getOverrideProps(overrides, "podSubIdoption5")}
        ></option>
        <option
          children="Pod6"
          value="Pod6"
          {...getOverrideProps(overrides, "podSubIdoption6")}
        ></option>
        <option
          children="Pod7"
          value="Pod7"
          {...getOverrideProps(overrides, "podSubIdoption7")}
        ></option>
        <option
          children="Pod8"
          value="Pod8"
          {...getOverrideProps(overrides, "podSubIdoption8")}
        ></option>
        <option
          children="Pod9"
          value="Pod9"
          {...getOverrideProps(overrides, "podSubIdoption9")}
        ></option>
      </SelectField>
      <TextField
        label="Aws account id"
        isRequired={false}
        isReadOnly={false}
        value={awsAccountId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId: value,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.awsAccountId ?? value;
          }
          if (errors.awsAccountId?.hasError) {
            runValidationTasks("awsAccountId", value);
          }
          setAwsAccountId(value);
        }}
        onBlur={() => runValidationTasks("awsAccountId", awsAccountId)}
        errorMessage={errors.awsAccountId?.errorMessage}
        hasError={errors.awsAccountId?.hasError}
        {...getOverrideProps(overrides, "awsAccountId")}
      ></TextField>
      <SelectField
        label="Aws region"
        placeholder="Please select an option"
        isDisabled={false}
        value={awsRegion}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion: value,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.awsRegion ?? value;
          }
          if (errors.awsRegion?.hasError) {
            runValidationTasks("awsRegion", value);
          }
          setAwsRegion(value);
        }}
        onBlur={() => runValidationTasks("awsRegion", awsRegion)}
        errorMessage={errors.awsRegion?.errorMessage}
        hasError={errors.awsRegion?.hasError}
        {...getOverrideProps(overrides, "awsRegion")}
      >
        <option
          children="Us east 1"
          value="us_east_1"
          {...getOverrideProps(overrides, "awsRegionoption0")}
        ></option>
        <option
          children="Us east 2"
          value="us_east_2"
          {...getOverrideProps(overrides, "awsRegionoption1")}
        ></option>
        <option
          children="Us west 2"
          value="us_west_2"
          {...getOverrideProps(overrides, "awsRegionoption2")}
        ></option>
      </SelectField>
      <SwitchField
        label="Multi timezone"
        defaultChecked={false}
        isDisabled={false}
        isChecked={multiTimezone}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone: value,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.multiTimezone ?? value;
          }
          if (errors.multiTimezone?.hasError) {
            runValidationTasks("multiTimezone", value);
          }
          setMultiTimezone(value);
        }}
        onBlur={() => runValidationTasks("multiTimezone", multiTimezone)}
        errorMessage={errors.multiTimezone?.errorMessage}
        hasError={errors.multiTimezone?.hasError}
        {...getOverrideProps(overrides, "multiTimezone")}
      ></SwitchField>
      <TextField
        label="Vpc id"
        isRequired={false}
        isReadOnly={false}
        value={vpcId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId: value,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.vpcId ?? value;
          }
          if (errors.vpcId?.hasError) {
            runValidationTasks("vpcId", value);
          }
          setVpcId(value);
        }}
        onBlur={() => runValidationTasks("vpcId", vpcId)}
        errorMessage={errors.vpcId?.errorMessage}
        hasError={errors.vpcId?.hasError}
        {...getOverrideProps(overrides, "vpcId")}
      ></TextField>
      <TextField
        label="Vpc cidr"
        isRequired={false}
        isReadOnly={false}
        value={vpcCidr}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr: value,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.vpcCidr ?? value;
          }
          if (errors.vpcCidr?.hasError) {
            runValidationTasks("vpcCidr", value);
          }
          setVpcCidr(value);
        }}
        onBlur={() => runValidationTasks("vpcCidr", vpcCidr)}
        errorMessage={errors.vpcCidr?.errorMessage}
        hasError={errors.vpcCidr?.hasError}
        {...getOverrideProps(overrides, "vpcCidr")}
      ></TextField>
      <TextField
        label="Security group file"
        isRequired={false}
        isReadOnly={false}
        value={securityGroupFile}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile: value,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.securityGroupFile ?? value;
          }
          if (errors.securityGroupFile?.hasError) {
            runValidationTasks("securityGroupFile", value);
          }
          setSecurityGroupFile(value);
        }}
        onBlur={() =>
          runValidationTasks("securityGroupFile", securityGroupFile)
        }
        errorMessage={errors.securityGroupFile?.errorMessage}
        hasError={errors.securityGroupFile?.hasError}
        {...getOverrideProps(overrides, "securityGroupFile")}
      ></TextField>
      <TextField
        label="Security group sql"
        isRequired={false}
        isReadOnly={false}
        value={securityGroupSql}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql: value,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.securityGroupSql ?? value;
          }
          if (errors.securityGroupSql?.hasError) {
            runValidationTasks("securityGroupSql", value);
          }
          setSecurityGroupSql(value);
        }}
        onBlur={() => runValidationTasks("securityGroupSql", securityGroupSql)}
        errorMessage={errors.securityGroupSql?.errorMessage}
        hasError={errors.securityGroupSql?.hasError}
        {...getOverrideProps(overrides, "securityGroupSql")}
      ></TextField>
      <TextField
        label="Security group mgmt"
        isRequired={false}
        isReadOnly={false}
        value={securityGroupMgmt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt: value,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.securityGroupMgmt ?? value;
          }
          if (errors.securityGroupMgmt?.hasError) {
            runValidationTasks("securityGroupMgmt", value);
          }
          setSecurityGroupMgmt(value);
        }}
        onBlur={() =>
          runValidationTasks("securityGroupMgmt", securityGroupMgmt)
        }
        errorMessage={errors.securityGroupMgmt?.errorMessage}
        hasError={errors.securityGroupMgmt?.hasError}
        {...getOverrideProps(overrides, "securityGroupMgmt")}
      ></TextField>
      <TextField
        label="Security group ad"
        isRequired={false}
        isReadOnly={false}
        value={securityGroupAD}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD: value,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.securityGroupAD ?? value;
          }
          if (errors.securityGroupAD?.hasError) {
            runValidationTasks("securityGroupAD", value);
          }
          setSecurityGroupAD(value);
        }}
        onBlur={() => runValidationTasks("securityGroupAD", securityGroupAD)}
        errorMessage={errors.securityGroupAD?.errorMessage}
        hasError={errors.securityGroupAD?.hasError}
        {...getOverrideProps(overrides, "securityGroupAD")}
      ></TextField>
      <TextField
        label="Sql address"
        isRequired={false}
        isReadOnly={false}
        value={sqlAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress: value,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlAddress ?? value;
          }
          if (errors.sqlAddress?.hasError) {
            runValidationTasks("sqlAddress", value);
          }
          setSqlAddress(value);
        }}
        onBlur={() => runValidationTasks("sqlAddress", sqlAddress)}
        errorMessage={errors.sqlAddress?.errorMessage}
        hasError={errors.sqlAddress?.hasError}
        {...getOverrideProps(overrides, "sqlAddress")}
      ></TextField>
      <TextField
        label="Sql name"
        isRequired={false}
        isReadOnly={false}
        value={sqlName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName: value,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlName ?? value;
          }
          if (errors.sqlName?.hasError) {
            runValidationTasks("sqlName", value);
          }
          setSqlName(value);
        }}
        onBlur={() => runValidationTasks("sqlName", sqlName)}
        errorMessage={errors.sqlName?.errorMessage}
        hasError={errors.sqlName?.hasError}
        {...getOverrideProps(overrides, "sqlName")}
      ></TextField>
      <SelectField
        label="Sql az"
        placeholder="Please select an option"
        isDisabled={false}
        value={sqlAz}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz: value,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlAz ?? value;
          }
          if (errors.sqlAz?.hasError) {
            runValidationTasks("sqlAz", value);
          }
          setSqlAz(value);
        }}
        onBlur={() => runValidationTasks("sqlAz", sqlAz)}
        errorMessage={errors.sqlAz?.errorMessage}
        hasError={errors.sqlAz?.hasError}
        {...getOverrideProps(overrides, "sqlAz")}
      >
        <option
          children="Us east 1a"
          value="us_east_1a"
          {...getOverrideProps(overrides, "sqlAzoption0")}
        ></option>
        <option
          children="Us east 1b"
          value="us_east_1b"
          {...getOverrideProps(overrides, "sqlAzoption1")}
        ></option>
        <option
          children="Us east 1c"
          value="us_east_1c"
          {...getOverrideProps(overrides, "sqlAzoption2")}
        ></option>
        <option
          children="Us east 1d"
          value="us_east_1d"
          {...getOverrideProps(overrides, "sqlAzoption3")}
        ></option>
        <option
          children="Us east 1e"
          value="us_east_1e"
          {...getOverrideProps(overrides, "sqlAzoption4")}
        ></option>
        <option
          children="Us east 1f"
          value="us_east_1f"
          {...getOverrideProps(overrides, "sqlAzoption5")}
        ></option>
        <option
          children="Us east 2a"
          value="us_east_2a"
          {...getOverrideProps(overrides, "sqlAzoption6")}
        ></option>
        <option
          children="Us east 2b"
          value="us_east_2b"
          {...getOverrideProps(overrides, "sqlAzoption7")}
        ></option>
        <option
          children="Us east 2c"
          value="us_east_2c"
          {...getOverrideProps(overrides, "sqlAzoption8")}
        ></option>
        <option
          children="Us west 2a"
          value="us_west_2a"
          {...getOverrideProps(overrides, "sqlAzoption9")}
        ></option>
        <option
          children="Us west 2b"
          value="us_west_2b"
          {...getOverrideProps(overrides, "sqlAzoption10")}
        ></option>
        <option
          children="Us west 2c"
          value="us_west_2c"
          {...getOverrideProps(overrides, "sqlAzoption11")}
        ></option>
        <option
          children="Us west 2d"
          value="us_west_2d"
          {...getOverrideProps(overrides, "sqlAzoption12")}
        ></option>
      </SelectField>
      <TextField
        label="Sql report address"
        isRequired={false}
        isReadOnly={false}
        value={sqlReportAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress: value,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlReportAddress ?? value;
          }
          if (errors.sqlReportAddress?.hasError) {
            runValidationTasks("sqlReportAddress", value);
          }
          setSqlReportAddress(value);
        }}
        onBlur={() => runValidationTasks("sqlReportAddress", sqlReportAddress)}
        errorMessage={errors.sqlReportAddress?.errorMessage}
        hasError={errors.sqlReportAddress?.hasError}
        {...getOverrideProps(overrides, "sqlReportAddress")}
      ></TextField>
      <TextField
        label="Sql report name"
        isRequired={false}
        isReadOnly={false}
        value={sqlReportName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName: value,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlReportName ?? value;
          }
          if (errors.sqlReportName?.hasError) {
            runValidationTasks("sqlReportName", value);
          }
          setSqlReportName(value);
        }}
        onBlur={() => runValidationTasks("sqlReportName", sqlReportName)}
        errorMessage={errors.sqlReportName?.errorMessage}
        hasError={errors.sqlReportName?.hasError}
        {...getOverrideProps(overrides, "sqlReportName")}
      ></TextField>
      <SelectField
        label="Sql report az"
        placeholder="Please select an option"
        isDisabled={false}
        value={sqlReportAz}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz: value,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.sqlReportAz ?? value;
          }
          if (errors.sqlReportAz?.hasError) {
            runValidationTasks("sqlReportAz", value);
          }
          setSqlReportAz(value);
        }}
        onBlur={() => runValidationTasks("sqlReportAz", sqlReportAz)}
        errorMessage={errors.sqlReportAz?.errorMessage}
        hasError={errors.sqlReportAz?.hasError}
        {...getOverrideProps(overrides, "sqlReportAz")}
      >
        <option
          children="Us east 1a"
          value="us_east_1a"
          {...getOverrideProps(overrides, "sqlReportAzoption0")}
        ></option>
        <option
          children="Us east 1b"
          value="us_east_1b"
          {...getOverrideProps(overrides, "sqlReportAzoption1")}
        ></option>
        <option
          children="Us east 1c"
          value="us_east_1c"
          {...getOverrideProps(overrides, "sqlReportAzoption2")}
        ></option>
        <option
          children="Us east 1d"
          value="us_east_1d"
          {...getOverrideProps(overrides, "sqlReportAzoption3")}
        ></option>
        <option
          children="Us east 1e"
          value="us_east_1e"
          {...getOverrideProps(overrides, "sqlReportAzoption4")}
        ></option>
        <option
          children="Us east 1f"
          value="us_east_1f"
          {...getOverrideProps(overrides, "sqlReportAzoption5")}
        ></option>
        <option
          children="Us east 2a"
          value="us_east_2a"
          {...getOverrideProps(overrides, "sqlReportAzoption6")}
        ></option>
        <option
          children="Us east 2b"
          value="us_east_2b"
          {...getOverrideProps(overrides, "sqlReportAzoption7")}
        ></option>
        <option
          children="Us east 2c"
          value="us_east_2c"
          {...getOverrideProps(overrides, "sqlReportAzoption8")}
        ></option>
        <option
          children="Us west 2a"
          value="us_west_2a"
          {...getOverrideProps(overrides, "sqlReportAzoption9")}
        ></option>
        <option
          children="Us west 2b"
          value="us_west_2b"
          {...getOverrideProps(overrides, "sqlReportAzoption10")}
        ></option>
        <option
          children="Us west 2c"
          value="us_west_2c"
          {...getOverrideProps(overrides, "sqlReportAzoption11")}
        ></option>
        <option
          children="Us west 2d"
          value="us_west_2d"
          {...getOverrideProps(overrides, "sqlReportAzoption12")}
        ></option>
      </SelectField>
      <TextField
        label="File server address"
        isRequired={false}
        isReadOnly={false}
        value={fileServerAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress: value,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.fileServerAddress ?? value;
          }
          if (errors.fileServerAddress?.hasError) {
            runValidationTasks("fileServerAddress", value);
          }
          setFileServerAddress(value);
        }}
        onBlur={() =>
          runValidationTasks("fileServerAddress", fileServerAddress)
        }
        errorMessage={errors.fileServerAddress?.errorMessage}
        hasError={errors.fileServerAddress?.hasError}
        {...getOverrideProps(overrides, "fileServerAddress")}
      ></TextField>
      <TextField
        label="File server name"
        isRequired={false}
        isReadOnly={false}
        value={fileServerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName: value,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.fileServerName ?? value;
          }
          if (errors.fileServerName?.hasError) {
            runValidationTasks("fileServerName", value);
          }
          setFileServerName(value);
        }}
        onBlur={() => runValidationTasks("fileServerName", fileServerName)}
        errorMessage={errors.fileServerName?.errorMessage}
        hasError={errors.fileServerName?.hasError}
        {...getOverrideProps(overrides, "fileServerName")}
      ></TextField>
      <SelectField
        label="File server az"
        placeholder="Please select an option"
        isDisabled={false}
        value={fileServerAz}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz: value,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.fileServerAz ?? value;
          }
          if (errors.fileServerAz?.hasError) {
            runValidationTasks("fileServerAz", value);
          }
          setFileServerAz(value);
        }}
        onBlur={() => runValidationTasks("fileServerAz", fileServerAz)}
        errorMessage={errors.fileServerAz?.errorMessage}
        hasError={errors.fileServerAz?.hasError}
        {...getOverrideProps(overrides, "fileServerAz")}
      >
        <option
          children="Us east 1a"
          value="us_east_1a"
          {...getOverrideProps(overrides, "fileServerAzoption0")}
        ></option>
        <option
          children="Us east 1b"
          value="us_east_1b"
          {...getOverrideProps(overrides, "fileServerAzoption1")}
        ></option>
        <option
          children="Us east 1c"
          value="us_east_1c"
          {...getOverrideProps(overrides, "fileServerAzoption2")}
        ></option>
        <option
          children="Us east 1d"
          value="us_east_1d"
          {...getOverrideProps(overrides, "fileServerAzoption3")}
        ></option>
        <option
          children="Us east 1e"
          value="us_east_1e"
          {...getOverrideProps(overrides, "fileServerAzoption4")}
        ></option>
        <option
          children="Us east 1f"
          value="us_east_1f"
          {...getOverrideProps(overrides, "fileServerAzoption5")}
        ></option>
        <option
          children="Us east 2a"
          value="us_east_2a"
          {...getOverrideProps(overrides, "fileServerAzoption6")}
        ></option>
        <option
          children="Us east 2b"
          value="us_east_2b"
          {...getOverrideProps(overrides, "fileServerAzoption7")}
        ></option>
        <option
          children="Us east 2c"
          value="us_east_2c"
          {...getOverrideProps(overrides, "fileServerAzoption8")}
        ></option>
        <option
          children="Us west 2a"
          value="us_west_2a"
          {...getOverrideProps(overrides, "fileServerAzoption9")}
        ></option>
        <option
          children="Us west 2b"
          value="us_west_2b"
          {...getOverrideProps(overrides, "fileServerAzoption10")}
        ></option>
        <option
          children="Us west 2c"
          value="us_west_2c"
          {...getOverrideProps(overrides, "fileServerAzoption11")}
        ></option>
        <option
          children="Us west 2d"
          value="us_west_2d"
          {...getOverrideProps(overrides, "fileServerAzoption12")}
        ></option>
      </SelectField>
      <TextField
        label="Dns ip primary"
        isRequired={false}
        isReadOnly={false}
        value={dnsIpPrimary}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary: value,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.dnsIpPrimary ?? value;
          }
          if (errors.dnsIpPrimary?.hasError) {
            runValidationTasks("dnsIpPrimary", value);
          }
          setDnsIpPrimary(value);
        }}
        onBlur={() => runValidationTasks("dnsIpPrimary", dnsIpPrimary)}
        errorMessage={errors.dnsIpPrimary?.errorMessage}
        hasError={errors.dnsIpPrimary?.hasError}
        {...getOverrideProps(overrides, "dnsIpPrimary")}
      ></TextField>
      <TextField
        label="Dns ip secondary"
        isRequired={false}
        isReadOnly={false}
        value={dnsIpSecondary}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary: value,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.dnsIpSecondary ?? value;
          }
          if (errors.dnsIpSecondary?.hasError) {
            runValidationTasks("dnsIpSecondary", value);
          }
          setDnsIpSecondary(value);
        }}
        onBlur={() => runValidationTasks("dnsIpSecondary", dnsIpSecondary)}
        errorMessage={errors.dnsIpSecondary?.errorMessage}
        hasError={errors.dnsIpSecondary?.hasError}
        {...getOverrideProps(overrides, "dnsIpSecondary")}
      ></TextField>
      <TextField
        label="Tgw attachment id"
        isRequired={false}
        isReadOnly={false}
        value={tgwAttachmentId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId: value,
              medFusionCidr,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.tgwAttachmentId ?? value;
          }
          if (errors.tgwAttachmentId?.hasError) {
            runValidationTasks("tgwAttachmentId", value);
          }
          setTgwAttachmentId(value);
        }}
        onBlur={() => runValidationTasks("tgwAttachmentId", tgwAttachmentId)}
        errorMessage={errors.tgwAttachmentId?.errorMessage}
        hasError={errors.tgwAttachmentId?.hasError}
        {...getOverrideProps(overrides, "tgwAttachmentId")}
      ></TextField>
      <TextField
        label="Med fusion cidr"
        isRequired={false}
        isReadOnly={false}
        value={medFusionCidr}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr: value,
              instanceKeyPair,
            };
            const result = onChange(modelFields);
            value = result?.medFusionCidr ?? value;
          }
          if (errors.medFusionCidr?.hasError) {
            runValidationTasks("medFusionCidr", value);
          }
          setMedFusionCidr(value);
        }}
        onBlur={() => runValidationTasks("medFusionCidr", medFusionCidr)}
        errorMessage={errors.medFusionCidr?.errorMessage}
        hasError={errors.medFusionCidr?.hasError}
        {...getOverrideProps(overrides, "medFusionCidr")}
      ></TextField>
      <TextField
        label="Instance key pair"
        isRequired={false}
        isReadOnly={false}
        value={instanceKeyPair}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              podName,
              domain,
              domainUser,
              timeZone,
              podSubId,
              awsAccountId,
              awsRegion,
              multiTimezone,
              vpcId,
              vpcCidr,
              securityGroupFile,
              securityGroupSql,
              securityGroupMgmt,
              securityGroupAD,
              sqlAddress,
              sqlName,
              sqlAz,
              sqlReportAddress,
              sqlReportName,
              sqlReportAz,
              fileServerAddress,
              fileServerName,
              fileServerAz,
              dnsIpPrimary,
              dnsIpSecondary,
              tgwAttachmentId,
              medFusionCidr,
              instanceKeyPair: value,
            };
            const result = onChange(modelFields);
            value = result?.instanceKeyPair ?? value;
          }
          if (errors.instanceKeyPair?.hasError) {
            runValidationTasks("instanceKeyPair", value);
          }
          setInstanceKeyPair(value);
        }}
        onBlur={() => runValidationTasks("instanceKeyPair", instanceKeyPair)}
        errorMessage={errors.instanceKeyPair?.errorMessage}
        hasError={errors.instanceKeyPair?.hasError}
        {...getOverrideProps(overrides, "instanceKeyPair")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || podModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || podModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
