import { AvailabilityZone, TimeZone, PodSubId } from '../models'
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { Button } from "@aws-amplify/ui-react";

class Utils {

    static shortGuid(guid) {
        if (!guid) return null;

        return guid.substring(0, 7);
    }

    static trimServerVersion(osVersion) {
        if (!osVersion) return null
        const arr = osVersion.split('Server')
        return arr.pop()
    }

    // CloudFormation templates don't accept *actual* arrays, so we need to
    // convert these to the appropriate string format.
    static converToListParam() {
        // Take all of the arguments and merge them into a single array
        const params = [].concat.apply([], ...arguments)

        // Filter out any non-truthy values, convert the array to a
        // comma-seperated string.
        return params.filter(p => !!p).join(',')

    }

    static parseStackSetNameFromArn(stackSetArn) {
        if (!stackSetArn) return null
        return stackSetArn.substring(
            stackSetArn.indexOf("/") + 1
        )
    }

    // Some CloudFormation templates will automatically
    // append the appropriate CIDR size for a given IP, so
    // we need to be able to grap just the IP portion.
    static parseIpFromCidr(cidr) {
        if (!cidr) return null
        const arr = cidr.split('/')
        if (arr.length > 0) {
            return arr[0]
        }
        return null
    }

    static covertToLongTimeZone(timeZone) {
        const map = {
            "AT": "Alaskan Standard Time",
            "AZ": "US Mountain Standard Time",
            "CT": "Central Standard Time",
            "ET": "Eastern Standard Time",
            "MT": "Mountain Standard Time",
            "PT": "Pacific Standard Time"
        }
        return map[timeZone] || map['ET']
    }

    static formatRegion(region) {
        if (!region) return
        return region.replaceAll('_', '-')
    }
    static formatInternalRegion(region) {
        if (!region) return
        // Remove 'us' prefix
        let modifiedRegion = region.replace(/^us/, '');

        // Replace underscores with hyphens
        modifiedRegion = modifiedRegion.replace(/_/g, '-');

        // Remove hyphen after region name
        modifiedRegion = modifiedRegion.replace(/-(?=\d)/g, '');


        return modifiedRegion;
    }
    static createAzOptions(field) {
        const labels = {}
        Object.keys(AvailabilityZone).forEach((key, idx) => {
            labels[`${field}option${idx}`] = { children: AvailabilityZone[key].replaceAll('_', '-').toLowerCase() }

        })
        return labels
    }

    static createTzOptions(field) {
        const labels = {}
        Object.keys(TimeZone).forEach((key, idx) => {
            labels[`${field}option${idx}`] = { children: TimeZone[key].toUpperCase() }

        })
        return labels
    }

    static createPodSubIdOptions(field) {
        const labels = {}
        Object.keys(PodSubId).forEach((key, idx) => {
            labels[`${field}option${idx}`] = { children: PodSubId[key].toLowerCase() }

        })
        return labels
    }


    static objSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    static getProductName(baseName) {
        const environment = process.env.REACT_APP_ENV
        const prefix = 'lynx-'
        const suffix = environment === 'prd' ? '' : '-test'
        return `${prefix}${baseName}${suffix}`
    }

    // Define an array of objects that map userCount to instance types
    static getProvisioningProps(userCount) {
        const provisioningProps = [
            { maxCount: 5, testServers: 1, termServers: 1, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 't3a.xlarge', ancInstanceType: 't3a.xlarge', loadBalancer: 'No', pxpInstanceType: "t3a.large" },
            { maxCount: 15, testServers: 1, termServers: 1, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 't3a.xlarge', ancInstanceType: 't3a.xlarge', loadBalancer: 'No', pxpInstanceType: "t3a.large" },
            { maxCount: 25, testServers: 1, termServers: 1, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 't3a.2xlarge', ancInstanceType: 't3a.xlarge', loadBalancer: 'No', pxpInstanceType: "t3a.large" },
            { maxCount: 50, testServers: 1, termServers: 1, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 75, testServers: 1, termServers: 2, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 150, testServers: 1, termServers: 4, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 300, testServers: 2, termServers: 7, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 500, testServers: 2, termServers: 12, ancServers: 1, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 750, testServers: 2, termServers: 19, ancServers: 2, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 999, testServers: 2, termServers: 25, ancServers: 2, testInstanceType: 't3a.large', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 1499, testServers: 2, termServers: 37, ancServers: 3, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 2000, testServers: 2, termServers: 50, ancServers: 3, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 2500, testServers: 2, termServers: 62, ancServers: 4, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 3000, testServers: 2, termServers: 75, ancServers: 4, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 4000, testServers: 2, termServers: 100, ancServers: 4, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
            { maxCount: 5000, testServers: 2, termServers: 125, ancServers: 4, testInstanceType: 't3a.xlarge', termInstanceType: 'm6a.2xlarge', ancInstanceType: 'm6a.2xlarge', loadBalancer: 'Yes', pxpInstanceType: "m6a.large" },
        ];

        // Find the correct instance and EBS type based on userCount
        return provisioningProps.find(
            (x) => userCount <= x.maxCount
        );
    }

    static getCustomerBucketName(clientId) {
        return `nge-${process.env.REACT_APP_ENV}-envmgr-sftp-ngcstmr${clientId}`
    }

    static getSftpEndpointName(region) {
        const env = process.env.REACT_APP_ENV
        const prefix = env === 'prd' ? 'sftp' : `sftp-${env}`
        return `${prefix}-${this.formatRegion(region)}.nextgenmcs.com`
    }
    static getInternalSftpEndpointName(region) {
        const prefix = 'sftp'
        return `${prefix}${this.formatInternalRegion(region)}-internal.nextgenmcs.com`
    }

    static generateRandomId() {
        return uuidv4()
    }

    static getPodNumber(podSubId) {
        // Use a placeholder value of 0 if no podSubId is provided.
        if (!podSubId) return "0"
        return podSubId.split('Pod')[1]
    }

    static generateSqlBackupPath(pod, clientId, isTest = false) {
        const serverBaseName = isTest ? 'tstsql' : 'sql'
        const timeZone = pod.timeZone || "placeholder"

        return `\\\\${pod.domain}-${timeZone.toLowerCase()}${serverBaseName}${this.getPodNumber(pod.podSubId)}\\Backup\\${clientId}\\migration`
    }


    static generateFilesharePath(pod, clientId, folder) {
        const timeZone = pod.timeZone || "placeholder"

        return `\\\\${pod.domain}-${timeZone.toLowerCase()}file${this.getPodNumber(pod.podSubId)}\\${clientId}-${folder}\\`
    }

    static isEmpty(obj) {
        return Object.keys(obj).length === 0
    }

    static timestampToString(timestamp) {
        return new Date(timestamp).toLocaleString() || ""
    }

    static timestampToStringFormatted(timestamp) {
        if (!timestamp) {
            return null;
        }

        let datetime = new Date(timestamp);

        let year = datetime.toLocaleString('default', { year: 'numeric' });
        let month = datetime.toLocaleString('default', { month: '2-digit' });
        let day = datetime.toLocaleString('default', { day: '2-digit' });
        let time = datetime.toLocaleTimeString('en-us', { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' });

        return (year + '-' + month + '-' + day + ' ' + time);
    }

    static modalCloseButton(onCloseModal) {
        return (
            <Button onClick={onCloseModal} className={'modal-close-button'}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0 0 30 30">
                    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
                </svg>
            </Button>
        );
    }

    static getPageCount(pageSize, itemCount) {
        // This function calculates the total number of pages a list of items
        // will span, given a pageSize and the size of the list.
        if (!itemCount) {
            // Default to a single page if the itemCount is not a positive integer.
            return 1
        }

        return Math.ceil(itemCount / pageSize)
    }

    static getPageItems(items, pageSize, pageNumber) {
        // Returns a subset of items from an array based on a provided pageSize
        // and pageNumber
        const startingIndex = (pageNumber - 1) * pageSize
        const endingIndex = startingIndex + pageSize
        return items.slice(startingIndex, endingIndex)
    }
    static async downloadFile(filePath, fileName) {
        const element = document.createElement('a');
        const url = await Storage.get(filePath, { download: false });
        // Create a temporary element so that we can generate the download URL
        // "just in time". This ensures the signed URL doesn't expire before the
        // user clicks the download button.
        element.setAttribute('href', url);
        element.setAttribute('download', fileName);
        element.style.display = 'none';

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    static downloadPostmigration = async () => {
        const element = document.createElement('a');
        const fileName = 'sp_PostMigration_NGE.sql'
        const url = await Storage.get(`templates/storedprocs/${fileName}`, { download: false })

        // Create a temporary element so that we can generate the download URL
        // "just in time". This ensures the signed URL doesn't expire before the
        // user clicks the download button.
        element.setAttribute('href', url);
        element.setAttribute('download', fileName);
        element.style.display = 'none';

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    static downloadRestoredb = async () => {
        const element = document.createElement('a');
        const fileName = 'sp_restoredb_full.sql'
        const url = await Storage.get(`templates/storedprocs/${fileName}`, { download: false })

        // Create a temporary element so that we can generate the download URL
        // "just in time". This ensures the signed URL doesn't expire before the
        // user clicks the download button.
        element.setAttribute('href', url);
        element.setAttribute('download', fileName);
        element.style.display = 'none';

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static copyToClipboard(elementId) {
        let copyMe = document.getElementById(elementId);

        copyMe.select();
        copyMe.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyMe.value);

        return copyMe.value;
    }
}

export default Utils
