import { ComponentDivider } from '..'
import { DataStore } from "@aws-amplify/datastore";
import { Stack, Migration } from '../../models';
import { API } from 'aws-amplify';
import { updateStack, updateMigration } from '../../graphql/mutations';

import {
    Flex,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@aws-amplify/ui-react'
import Utils from '../../lib/utils'



import { useEffect, useState } from 'react';


export default function MigrationTargetStackAssign({ client, migration, onCloseModal }) {
    const [stacks, setStacks] = useState([])

    function closeModal() {
        if (typeof onCloseModal === 'function') {
            return onCloseModal()
        }
        return null

    }

    async function onAssign(stack) {
        const stackUpdatePayload = {
            id: stack.id,
            stackmigrationId: migration.id,
            _version: stack._version
        };

        const migrationUpdatePayload = {
            id: migration.id,
            _version: migration._version,
            migrationTargetStackId: stack.id
        };

        const updateStackResponse = await API.graphql({
            query: updateStack,
            variables:{
                input: stackUpdatePayload
            }
        });

        const updateMigrationResponse = await API.graphql({
            query: updateMigration,
            variables:{
                input: migrationUpdatePayload
            }
        });

        return closeModal()
    }

    useEffect(() => {
        const stackSub = DataStore.observeQuery(Stack, (s) => s.clientStacksClientId.eq(client.clientId)
        ).subscribe(({ items }) => {
            setStacks(items);
        })

        return () => {
            stackSub.unsubscribe();
        }
    }, [client.clientId])

    return (
        <Flex width="60rem" direction="column">
            {Utils.modalCloseButton(onCloseModal)}
            <ComponentDivider level={4} title="Target Stack" />
            <Flex direction="row" justifyContent="space-between">
            </Flex>
            <Table highlightOnHover={true} variation="striped">
                <TableHead>
                    <TableRow >
                        <TableCell as="th">Stack Name</TableCell>
                        <TableCell as="th">Primary AZ</TableCell>
                        <TableCell as="th">Primary CIDR</TableCell>
                        <TableCell as="th">Secondary AZ</TableCell>
                        <TableCell as="th">Secondary CIDR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        stacks.filter(m => m.stackName).map(stack =>

                            <TableRow onClick={() => onAssign(stack)} style={{ cursor: 'pointer' }} key={stack.id}>
                                <TableCell>{stack.stackName}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az1)}</TableCell>
                                <TableCell>{stack.az1Cidr}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az2)}</TableCell>
                                <TableCell>{stack.az2Cidr}</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </Flex >
    );
}
