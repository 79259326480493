import { useParams } from 'react-router-dom';
import {
    Button, Flex, Pagination, SearchField, Table, TableBody, TableCell,
    TableHead, TableRow, ThemeProvider, View, TextField, StepperField, ExpanderItem, Expander, Badge, Alert
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { ComponentDivider, LoadingWrapper } from "..";
import Modal from "react-modal";
import { nextGenTheme } from "../../themes";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { Client, Migration, Recipe, MigrationRecipes, TerminalServer } from "../../models";
import TerminalServers from '../../lib/terminalservers';
import { runEventOrchestrator, listMigrations, getClient, getMigration, listClients } from '../../graphql/queries';
import { API, Auth } from 'aws-amplify';
import { createMigrationRecipes, updateMigrationRecipes } from '../../graphql/mutations';
import Utils from "../../lib/utils";

const ts = new TerminalServers();

export default function TerminalServersClientDetails() {

    Auth.currentAuthenticatedUser().then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups']

        // No access to Migration Engineers for term server functionality (NGEMF-833)
        if (!cognitoGroups.includes('EnvironmentAdmins') && !cognitoGroups.includes('MigrationAdmins')) {
            const error = new Error('401 Not Authorized');
            error.stack = null;
            error.httpError = 401;

            throw error;
        }
    });

    const { clientId } = useParams();
    const [client, setClient] = useState({});
    const [instanceId, setInstanceId] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stopModalOpen, setStopModalOpen] = useState(false)
    const [onboardClient, setOnboardClient] = useState(false);
    const [replaceModalOpen, setReplaceModalOpen] = useState(false);
    const [replicateModalOpen, setReplicateModalOpen] = useState(false);
    const [snapshotModalOpen, setSnapshotModalOpen] = useState(false);
    const [confirmPackagesModalOpen, setConfirmPackagesModalOpen] = useState(false);
    const [createDescriptionModalOpen, setCreateDescriptionModalOpen] = useState(false);
    const [state, setState] = React.useState(null);
    const [terminalServers, setTerminalServers] = useState([]);
    const [addTerminalServersModalOpen, setAddTerminalServersModalOpen] = useState(false);
    const [recipes, setRecipes] = React.useState({});
    const [migration, setMigration] = React.useState({});
    const [awsAccountId, setAWSAccountId] = React.useState();
    const [awsRegion, setAWSRegion] = React.useState();
    const [customerInventory, setCustomerInventory] = useState([]);
    const [description, setDescription] = useState('');
    const [replicatedNumber, setReplicatedNumber] = useState(0);
    const [amiList, setAMIList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const closeConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(false);
    }

    const openConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(true);
    }
    const closeReplicateModal = () => {
        setReplicateModalOpen(false);
    };
    const openReplicateModal = (instanceId) => {
        setInstanceId(instanceId)
        setReplicateModalOpen(true);
    }

    const openOnboardClient = () => {
        setOnboardClient(true)
    }

    const closeOnboardClient = () => {
        setOnboardClient(false)
    }

    const addExistingEC2Instances = async () => {
        try {
            // Construct the payload the right input parameters
            const payload = {
                event_name: 'migrateclient',
                client_account_id: awsAccountId,
                region: awsRegion,
                client_id: client.clientId,
                client_name: client.clientName,
                migration_id: migration.id
            };

            // Call the API with the constructed payload
            const value = await API.graphql({
                query: runEventOrchestrator,
                variables: { payload },
            })
            alert("Finding additional terminal servers.");
            closeOnboardClient()

        } catch (error) {
            console.error('Error adding client ID', error);
            // Handle error
        }
    };


    // Function to handle changes in the text box
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };



    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const refreshUpdateSoftwareInventory = () => {
        setState(updateSoftwareInventory(recipes));
    };


    // Define help bar styles
    const helpBarStyles = {
        position: 'fixed',
        right: 0,
        top: '50px',
        width: '300px',
        height: isExpanded ? 'auto' : '40px',
        backgroundColor: isExpanded ? '#f0f0f0' : 'initial',
        padding: '20px',
        boxShadow: isExpanded ? '0 0 10px rgba(0,0,0,0.1)' : 'initial',
        cursor: 'pointer',
        transition: 'height 0.3s ease-in-out',
        zIndex: "1000"
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filterBySearchCriteria = (searchInput) => (ts) => {
        const searchLowerCase = searchInput.toLowerCase();

        const instanceId = ts.instanceId?.toLowerCase() || '';
        const amiId = get_ami_id(ts.terminalServerAmiId)?.toLowerCase() || '';
        const amiType = get_ami_type(ts.terminalServerAmiId)?.toLowerCase() || '';
        const instanceState = ts.instanceState?.toLowerCase() || '';

        const matchesSearchCriteria =
            instanceId.includes(searchLowerCase) ||
            amiId.includes(searchLowerCase) ||
            amiType.includes(searchLowerCase) ||
            instanceState.includes(searchLowerCase);

        return matchesSearchCriteria;
    };

    // Function to toggle expansion state for Help Bar
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    async function buildInventory() {
        const tsDescription = document.getElementById('tsDescription').value;

        if (!tsDescription) {
            return alert('Description is required.');
        }

        try {
            const get_recipe = await DataStore.query(Recipe)

            for (const [key, value] of Object.entries(ts.cur_packages)) {
                for (let i = 0; i < get_recipe.length; i++) {

                    if (value.value.id === get_recipe[i].id) {
                        createMigrationRecipe(get_recipe[i].id, migration.id)
                        break;
                    }
                }
            }

            const payload = {
                event_name: "basets",
                client_id: client.clientId,
                migration_id: migration.id,
                description: tsDescription,
                client_account_id: awsAccountId,
                region: awsRegion
            };

            await API.graphql({
                query: runEventOrchestrator,
                variables: { payload },
            });

            refreshUpdateSoftwareInventory();
            closeReplicateModal();
            closeConfirmPackagesModalOpen();
            closeCreateDescriptionModal();
            alert("Processing the creation of a base terminal server.");
        } catch (error) {
            console.error('Error creating base terminal servers', error);

            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                alert(`Error creating base terminal servers: ${error.errors[0].message}`);
            } else {
                alert('Error creating base terminal servers. Please try again.');
            }
        }
    }

    async function createMigrationRecipe(recipeId, migrationId) {
        let built = false;
        const get_migration_recipes = await DataStore.query(MigrationRecipes, c => c.and(c => [c.migrationId.eq(migrationId), c.recipeId.eq(recipeId)]))

        if (get_migration_recipes !== undefined && get_migration_recipes.length !== 0) {
            const migrationRecipeDetails = {
                id: get_migration_recipes[0].id,
                _version: get_migration_recipes[0]._version,
                recipeId: recipeId,
                migrationId: migrationId
            };

            const updateMigrationRecipe = API.graphql({
                query: updateMigrationRecipes,
                variables: { input: migrationRecipeDetails }
            })
            built = true;
        }

        if (built === false) {
            const migrationRecipeDetails = {
                recipeId: recipeId,
                migrationId: migrationId
            };

            const newMigrationRecipe = API.graphql({
                query: createMigrationRecipes,
                variables: { input: migrationRecipeDetails }
            });

        }
    }

    const get_current_customer_inventory = () => {
        const temp_mapping = [];
        for (let [key, value] of Object.entries(ts.cur_packages)) {
            temp_mapping.push(
                <li>
                    {value.key}
                </li>
            );
        };

        return (
            <><p>
                The following packages will be added to the new terminal server:
                <ul style={{ color: "green", fontWeight: "bold" }}>
                    {temp_mapping}
                </ul>
            </p></>
        )
    }

    function updateSoftwareInventory(recipes) {
        return (
            <View>
                <ComponentDivider title="Update Software Inventory" level="5" />
                {managePackages(recipes)}
            </View>
        );
    }


    const does_build_basets_get_button = () => {
        const isButtonDisabled = terminalServers.some(ts => {
            const amiType = get_ami_type(ts.terminalServerAmiId);
            const instanceState = ts.instanceState;

            if (!amiType || !instanceState) {
                return false;
            }
            const isInstanceRunning = instanceState.toLowerCase() === "running";

            return amiType === 'CustomerBaseline' && isInstanceRunning

        });

        return isButtonDisabled;
    }


    function managePackages(recipes) {

        const isButtonDisabled = does_build_basets_get_button()

        const button = (
            <Button
                id="buildInventory"
                onClick={openConfirmPackagesModalOpen}
                size="small"
                variation="primary"
                disabled={isButtonDisabled}
            >
                Build Base Terminal Server
            </Button>
        );

        return (
            <View>
                {ts.packagesTableComponent(recipes, button, clientId)}
            </View>
        );
    }


    const stopTerminalServer = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction('stopinstances')
            alert(instanceId + " stopped");
        } catch (error) {
            console.error('Error stopping terminal servers', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error stopping servers: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error stopping servers. Please try again.');
            }

        }
        closeStopModal()
    }

    const singleAction = async (event_name, description = "") => {
        const payload = {
            event_name: event_name,
            migration_id: migration.id,
            client_id: client.clientId,
            client_account_id: awsAccountId,
            instance_id: instanceId,
            region: awsRegion
        };

        if (description !== "") {
            payload['description'] = description
        }

        if (replicatedNumber !== 0) {
            for (let i = 0; i < replicatedNumber; i++) {
                const response = await API.graphql({
                    query: runEventOrchestrator,
                    variables: { payload },
                });
            }
        }
        else if (replicatedNumber === 0) {
            const response = await API.graphql({
                query: runEventOrchestrator,
                variables: { payload },
            });
        }
    }

    const replaceTSClick = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction("replacets");
            alert('Replacing terminal servers');
        } catch (error) {
            console.error('Error replacing terminal server', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error replacing server: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error replacing server. Please try again.');
            }
        }
        closeReplaceModal();
    }

    const replicateLaunchButtonClick = async () => {
        try {
            let response = await singleAction('replicatets');
            alert('Terminal servers replicated successfully!');
            closeAddTerminalServersModal();
        } catch (error) {
            console.error('Error replicating terminal servers', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error replicating servers: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error replicating servers. Please try again.');
            }

        }
        closeReplicateModal();
    };

    const snapshotTerminalServerClick = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction("snapshot", description)
            alert(`Instance ID: ${instanceId} snapshot taken successfully!`);
        } catch (error) {
            console.error('Error taking terminal servers snapshot', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error taking terminal servers snapshot: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error taking terminal servers snapshot. Please try again.');
            }

        }
        closeSnapshotModal();
    };

    function handleStepChange(e) {
        setReplicatedNumber(e);
    }
    function openReplaceModal(instanceId) {
        setInstanceId(instanceId)
        setReplaceModalOpen(true);
    }

    function closeReplaceModal() {
        setReplaceModalOpen(false);
    }

    function openStopModal(instanceId) {
        setInstanceId(instanceId);
        setStopModalOpen(true);
    }

    function get_region(region) {
        if (region) {
            return region.replaceAll("_", "-");
        }
    }

    function get_ami_type(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].amiType;
            }
        }
    }

    function get_ami_id(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].name;
            }
        }
    }

    function closeStopModal() {
        setStopModalOpen(false);
    }


    function closeSnapshotModal() {
        setSnapshotModalOpen(false);
    }


    function openSnapshotModal(instanceId) {
        setInstanceId(instanceId);
        setSnapshotModalOpen(true);
    }

    function closeAddTerminalServersModal() {
        setAddTerminalServersModalOpen(false);
    }

    function closeConfirmPackagesModal() {
        setConfirmPackagesModalOpen(false);
    }

    function openCreateDescriptionModal() {
        setCreateDescriptionModalOpen(true);
    }

    function closeCreateDescriptionModal() {
        setCreateDescriptionModalOpen(false);
    }

    function toggleAllColumns() {
        let hiddenColumnByDefault = document.getElementsByClassName('hiddenColumnByDefault');
        let toggleColumnsButton = document.getElementById('toggleColumnsButton');
        let terminalServerInfoTooltipIcon = document.getElementsByClassName('terminalServerInfoTooltipIcon');
        let terminalServerInfoTooltip = document.getElementsByClassName('terminalServerInfoTooltip');

        for (let i = 0; i < hiddenColumnByDefault.length; i++) {
            if (!hiddenColumnByDefault[i].style.display || hiddenColumnByDefault[i].style.display === 'none') {
                hiddenColumnByDefault[i].style.display = 'table-cell';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show Less)';
            } else {
                hiddenColumnByDefault[i].style.display = 'none';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show More)';
            }
        }

        for (let j = 0; j < terminalServerInfoTooltip.length; j++) {
            terminalServerInfoTooltip[j].style.display = 'none';

            if (hiddenColumnByDefault[0].style.display !== 'none') {
                terminalServerInfoTooltipIcon[j].style.display = 'none';
            } else {
                terminalServerInfoTooltipIcon[j].style.display = 'initial';
            }
        }
    }

    function toggleTerminalServerInfoTooltip(elementId) {
        let terminalServerInfoTooltipId = 'terminalServerInfoTooltip----' + elementId.split('----')[1];
        let terminalServerInfoTooltip = document.getElementById(terminalServerInfoTooltipId);
        if (!terminalServerInfoTooltip.style.display || terminalServerInfoTooltip.style.display === 'none') {
            terminalServerInfoTooltip.style.display = 'block';
        } else {
            terminalServerInfoTooltip.style.display = 'none';
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const does_instance_get_buttons = (curInstanceId, curAmiId, curInstanceState) => {

        const isInstanceStopped = curInstanceState === "stopped" || curInstanceState === "STOPPED" || curInstanceState === "Stopped";

        if (get_ami_type(curAmiId) === "DeprecatedAMI") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "CustomerBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openSnapshotModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Snapshot</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "ModifyBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplaceModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replace</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplicateModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replicate</Button>
                </>
            )
        }
    }

    useEffect(() => {
        const recipeSub = DataStore.observeQuery(Recipe,
            Predicates.ALL, {
            sort: (r) => r.name(SortDirection.ASCENDING)
        }).subscribe(async ({ items }) => {
            setRecipes(items);
        });

        const clientSub = DataStore.observeQuery(Client, c => c.clientId.eq(clientId))
            .subscribe(async ({ items }) => {

                if (typeof items[0] !== "undefined") {
                    setClient(items[0] || {});
                    let stack = await items[0].stacks.toArray()
                    let pods = await stack[0].pod.then(
                        (pod) => {
                            setAWSAccountId(pod.awsAccountId);
                            setAWSRegion((pod.awsRegion).replaceAll('_', '-'));
                        }
                    );
                }
            })

        const migrationSub = DataStore.observeQuery(Migration, m => m.client.clientId.eq(clientId))
            .subscribe(async ({ items }) => {
                const migration = items[0];
                if (migration) {
                    const migrationId = migration.id; // Extract the migration ID
                    setMigration(migration);
                    setIsLoading(false);
                    if (items[0]['customerInventory'] !== "undefined") {
                        const customerInventory = await items[0].customerInventory.values;
                        setCustomerInventory(customerInventory);
                    }
                }
            }, error => {
                console.error("Error fetching migration ID:", error); // Log any errors
            });

        const terminalServerSub = DataStore.observeQuery(Migration, m => m.client.clientId.eq(clientId))
            .subscribe(async ({ items }) => {
                if (typeof items[0] !== "undefined") {
                    const migration = items[0];

                    if (migration) {
                        const curTerminalServers = await migration.terminalServers.values;

                        if (curTerminalServers.length > 0) {
                            let ami_list = [];
                            for (let i = 0; i < curTerminalServers.length; i++) {
                                ami_list.push(await curTerminalServers[i].ami);
                            }

                            setAMIList(ami_list)

                            setTerminalServers(curTerminalServers);
                        } else {
                            setInstanceId("No terminal servers");
                        }
                    }
                }
            }, error => {
                console.error("Error fetching migration ID:", error); // Log any errors
            });

        return () => {
            recipeSub.unsubscribe();
            clientSub.unsubscribe();
            migrationSub.unsubscribe();
            terminalServerSub.unsubscribe();
        };

    }, [Client, Migration, TerminalServer]);

    return (
        <LoadingWrapper isLoading={isLoading}>

            <View>
                <Flex width="60rem" direction="column">
                    <div style={{ ...helpBarStyles, textDecoration: isExpanded ? 'none' : 'underline', }} onClick={toggleExpansion} className="help-bar">
                        <h2 style={{ textAlign: "right" }}><img style={{ height: "1em" }} src="/img/help-icon.png" /> Help</h2>
                        {isExpanded && (
                            <div style={{ height: 'auto', padding: '20px', maxHeight: '80vh', overflow: 'auto', cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                <p>This is for understanding how the process works.</p>
                                <p><strong><u>Build Base Terminal Server</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYAKJQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on build base ts workflow.</p>
                                <p><strong><u>Add Existing Terminal Servers</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/_oReIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on adding existing ts workflow.</p>
                                <p><strong><u>Actions Button</u></strong>:</p>
                                <p>Replace instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AQAAJ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Replicate instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYBpJQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Snapshot instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYD9J" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Stop instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/WJBoIw" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p><strong><u>Migrating Existing Clients</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/_oReIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on migrating existing clients workflow.</p>
                                <p><strong><u>Other useful information</u></strong>:</p>
                                <p>Lynx 2.0 User documentation - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/5VU5IQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Prerequisites and Onboarding Lynx 2.0 - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/IFw6IQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Lynx 2.0 Definitions - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/P4JdIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                            </div>
                        )}
                    </div>
                    <ComponentDivider title={`Terminal Servers - ${client.clientName}`} level="2" />
                    <View>
                        {updateSoftwareInventory(recipes)}
                    </View>
                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={replaceModalOpen} onRequestClose={closeReplaceModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title={`Replace Instance ${instanceId}`} />
                            <p>
                                Are you sure you want to replace this terminal server?
                            </p>
                            <div style={{ backgroundColor: '#e3f2fd' }}>
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>NOTE</span>: Replace instance process takes <span style={{ fontWeight: 'bold', color: "Red" }}>5-6 minutes</span> to complete. You can refresh the page after that.
                                </p>
                            </div>
                            <Button size="small" variation="primary" onClick={replaceTSClick}>Yes</Button>&#160;
                            <Button size="small" onClick={closeReplaceModal}>Cancel</Button>
                        </ThemeProvider>
                    </Modal>

                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={replicateModalOpen} onRequestClose={closeReplicateModal}>
                        <ThemeProvider theme={nextGenTheme}>
                            <>
                                <ComponentDivider level={4} title={`Add Terminal Servers from ${instanceId}`} />
                                <p>
                                    How many additional terminal servers would you like to launch?
                                </p>
                                <div style={{ backgroundColor: '#e3f2fd' }}>
                                    <p>
                                        <span style={{ fontWeight: 'bold' }}>NOTE</span>: Replicate instance process takes <span style={{ fontWeight: 'bold', color: "Red" }}>5-6 minutes</span> to complete. You can refresh the page after that.
                                    </p>
                                </div>
                                <Flex width="10em" direction="column">
                                    <StepperField min={1} max={21} step={1} onStepChange={e => handleStepChange(e)} />
                                </Flex>
                                <br />
                                <Button size="small" variation="primary" onClick={replicateLaunchButtonClick}>Launch</Button>&#160;
                                <Button size="small" onClick={closeReplicateModal}>Cancel</Button>
                            </>
                        </ThemeProvider>
                    </Modal>
                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={onboardClient} onRequestClose={closeOnboardClient}>
                        {Utils.modalCloseButton(closeOnboardClient)}
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title={`Are you sure you wish to onboard ${clientId}?`} />
                            <p style={{ color: "Red", fontWeight: "bold" }}>Warning:</p>
                            If you currently have other processes running do NOT onboard the client.<br /><br />
                            <Button size="small" variation="primary" onClick={() => addExistingEC2Instances()}>Yes</Button>&#160;
                            <Button size="small" onClick={() => closeOnboardClient()}>Cancel</Button>
                        </ThemeProvider>
                    </Modal>
                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={stopModalOpen} onRequestClose={closeStopModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title={`Stop Instance ${instanceId}`} />
                            Are you sure you want to stop {instanceId}?<br /><br />
                            <div style={{ backgroundColor: '#e3f2fd' }}>
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>NOTE</span>: Stop instance process takes <span style={{ fontWeight: 'bold', color: "Red" }}>4-10 minutes</span> to complete. You can refresh the page after that.
                                </p>
                            </div>
                            <Button size="small" variation="primary" onClick={() => stopTerminalServer()}>Yes</Button>&#160;
                            <Button size="small" onClick={() => closeStopModal()}>Cancel</Button>
                        </ThemeProvider>
                    </Modal>

                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={snapshotModalOpen} onRequestClose={closeSnapshotModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title={`Snapshot Instance ${instanceId}`} />
                            <p>Description:</p>
                            <TextField
                                size="small"
                                minWidth="300px"
                                value={description}
                                onChange={handleDescriptionChange}
                                placeholder="Add description here"
                            /><br /><br />
                            Are you sure you want to create an AMI of this Terminal Server?
                            <div style={{ backgroundColor: '#e3f2fd' }}>
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>NOTE</span>: Snapshot process takes <span style={{ fontWeight: 'bold', color: "Red" }}>25-35 minutes</span> to complete. You can refresh page after that.
                                </p>
                            </div>
                            <Button size="small" variation="primary" onClick={() => snapshotTerminalServerClick()}>Yes</Button>&#160;
                            <Button size="small" onClick={() => closeSnapshotModal()}>Cancel</Button>
                        </ThemeProvider>
                    </Modal>

                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={confirmPackagesModalOpen} onRequestClose={closeConfirmPackagesModal}>
                        {Utils.modalCloseButton(closeConfirmPackagesModalOpen)}
                        <ThemeProvider theme={nextGenTheme} >
                            <ComponentDivider level={4} title="Rebuild Terminal Servers" />
                            <div>
                                {get_current_customer_inventory()}
                            </div><br />
                            <p>Are you sure you want to continue?</p>
                            <Button size="small" variation="primary" onClick={openCreateDescriptionModal}>Yes</Button>&#160;
                            <Button size="small" onClick={closeConfirmPackagesModalOpen}>Cancel</Button>
                        </ThemeProvider>
                    </Modal>

                    <Modal style={customStyles} appElement={document.getElementById('app')} isOpen={createDescriptionModalOpen} onRequestClose={closeCreateDescriptionModal}>
                        <ThemeProvider theme={nextGenTheme} >
                            {Utils.modalCloseButton(closeCreateDescriptionModal)}
                            <ComponentDivider level={4} title="Create a Description for this Build" />
                            <View as="form">
                                <p>Enter a brief description for this terminal server for easy reference.</p><br />
                                <TextField id="tsDescription" label="Description" isRequired={true} placeholder="e.g. TS Scan & Print" onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                                <br /><br />
                                <div style={{ backgroundColor: '#e3f2fd' }}>
                                    <p>
                                        <span style={{ fontWeight: 'bold' }}>NOTE</span>: basets process takes about <span style={{ fontWeight: 'bold', color: "Red" }}>90 minutes</span> to complete. You can refresh the page after that.
                                    </p>
                                </div>
                                <Button size="small" variation="primary" onClick={buildInventory}>Continue</Button>&#160;
                                <Button size="small" onClick={closeCreateDescriptionModal}>Cancel</Button>
                            </View>
                        </ThemeProvider>
                    </Modal>
                    <ComponentDivider title="Deployed Terminal Servers" level="5" />
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={openOnboardClient} style={{ float: "right", width: "250px" }} size="small" variation="primary">Add Existing Terminal Servers</Button>
                    </div>
                    <Table border="0">
                        <TableBody>
                            <TableRow>
                                <TableCell width="80%" border="0">
                                    <SearchField hasSearchButton={false} hasSearchIcon={true} placeholder='Search' onChange={handleSearchInputChange} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Pagination
                        currentPage={1}
                        totalPages={1}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                        onChange={handleOnChange}
                    />
                    <Button style={{ width: "250px" }} id="toggleColumnsButton" size="small" onClick={toggleAllColumns}>
                        Toggle Columns (Show More)
                    </Button>
                    <Table id="deployedTerminalServers" className="sortable" highlightOnHover={true} variation="striped">
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Instance</TableCell>
                                <TableCell as="th">Build Date</TableCell>
                                <TableCell as="th">Description</TableCell>
                                <TableCell className="hiddenColumnByDefault" as="th">Region</TableCell>
                                <TableCell className="hiddenColumnByDefault" as="th">AMI Type</TableCell>
                                <TableCell className="hiddenColumnByDefault" as="th">AMI ID</TableCell>
                                <TableCell className="hiddenColumnByDefault" as="th">Health</TableCell>
                                <TableCell as="th">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                terminalServers.length === 0 ? <TableRow><TableCell colSpan="8" style={{textAlign: "center"}}>(No Results)</TableCell></TableRow> : terminalServers
                                .filter(filterBySearchCriteria(searchInput))
                                .map(ts => (
                                    <TableRow key={ts.instanceId} style={{ cursor: 'pointer' }}>
                                        <TableCell>{ts.instanceId}&#160;&#160;
                                            <img id={"terminalServerInfoTooltipIcon----" + ts.instanceId} onClick={(e) => { toggleTerminalServerInfoTooltip(e.target.id) }} className="terminalServerInfoTooltipIcon" style={{ height: "1em" }} src="/img/info-icon.png" />
                                            <View id={"terminalServerInfoTooltip----" + ts.instanceId} className="terminalServerInfoTooltip">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Instance ID:
                                                            </td>
                                                            <td>
                                                                {ts.instanceId}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Region:
                                                            </td>
                                                            <td>
                                                                {get_region(ts.region)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                AMI Type:
                                                            </td>
                                                            <td>
                                                                {get_ami_type(ts.terminalServerAmiId)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                AMI ID:
                                                            </td>
                                                            <td>
                                                                {get_ami_id(ts.terminalServerAmiId)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </View>
                                        </TableCell>
                                        <TableCell>{Utils.timestampToStringFormatted(ts.buildDate)}</TableCell>
                                        <TableCell>{ts.description}</TableCell>
                                        <TableCell className="hiddenColumnByDefault">{get_region(ts.region)}</TableCell>
                                        <TableCell className="hiddenColumnByDefault">{get_ami_type(ts.terminalServerAmiId)}</TableCell>
                                        <TableCell className="hiddenColumnByDefault">{get_ami_id(ts.terminalServerAmiId)} </TableCell>
                                        <TableCell className="hiddenColumnByDefault">
                                            <Badge variation={ts.instanceState.toUpperCase() === 'RUNNING' ? 'success' : 'error'} size="medium" >
                                                {(ts.instanceState).toUpperCase()}
                                            </Badge>
                                        </TableCell>
                                        <TableCell >
                                            {does_instance_get_buttons(ts.instanceId, ts.terminalServerAmiId, ts.instanceState)}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Flex>
            </View>
        </LoadingWrapper >
    );
}